import React from 'react';

import * as Styled from './Spinner.styled';

const Spinner = () => (
	<Styled.UnOrderedList>
		<Styled.List></Styled.List>
		<Styled.List></Styled.List>
		<Styled.List></Styled.List>
		<Styled.List></Styled.List>
		<Styled.List></Styled.List>
	</Styled.UnOrderedList>
);

export default Spinner;
