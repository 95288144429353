import { compareAsc, sub } from 'date-fns';
import {
	get,
	filter,
	find,
	intersection,
	includes,
	map,
	isEmpty,
} from 'lodash';

import { DropdownItemValue } from '../Dropdown/Dropdown.types';
import { TestResultArrayItem } from '../TestResultsBlock/TestResultsBlock.types';

import { TestFiltersTag } from './TestFilters.types';
import {
	tagsList,
	testTypeOptions,
	typeToCategoryMap,
} from './TestFiltersConstants';

export const filterData = (
	data: TestResultArrayItem[],
	testType: DropdownItemValue,
	testPeriod: DropdownItemValue,
	testTags: string[]
) => {
	let output = data;
	if (testType !== null) {
		const title = get(find(testTypeOptions, { value: testType }), 'title', '');
		output = filter(output, { category: title });
	}
	if (testPeriod !== null) {
		const dateNow = new Date();
		dateNow.setHours(0, 0, 0, 0);
		const dateFrom = sub(dateNow, {
			years: testPeriod as number,
		});

		output = filter(
			output,
			(o) => compareAsc(Date.parse(o.date), dateFrom) > -1
		);
	}

	if (isEmpty(testTags)) {
		return output;
	} else {
		return filter(output, (o) =>
			o.healthStoreCategories !== null
				? intersection(testTags, o.healthStoreCategories).length > 0
				: false
		);
	}
};

export const getTestTypeTagsList = (testTypeId: number | null) => {
	if (testTypeId === null) {
		return tagsList;
	}
	const tagIds: number[] = typeToCategoryMap[testTypeId];
	const related: TestFiltersTag[] = filter(tagsList, (t) =>
		includes(tagIds, t.id)
	);
	const disabled: TestFiltersTag[] = map(
		filter(tagsList, (t) => !includes(tagIds, t.id)),
		(t) => {
			return { ...t, related: false };
		}
	);
	return [...related, ...disabled];
};

export const getDefaultTagsList = (testTypeId: number | null) => {
	if (testTypeId === null) {
		return [];
	}
	const tagIds = typeToCategoryMap[testTypeId];
	return map(
		filter(tagsList, (t) => includes(tagIds, t.id)),
		(t) => t.value
	);
};
