// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Layout = styled.div`
	height: 100vh;
	width: 100%;
	// TODO: Check this line of code
	position: fixed;
	display: flex;

	@media ${device.mobileL} {
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
`;

export const Content = styled.div`
	display: flex;
	height: 100%;
	width: calc(100% - 330px);
	flex-direction: column;

	@media ${device.laptop} {
		width: 100%;
	}
`;

export const Container = styled.div<{
	hasBottomNavigation: boolean;
}>`
	overflow: auto;
	height: 100%;
	${({ hasBottomNavigation }) =>
		!hasBottomNavigation &&
		css`
			@media ${device.tablet} {
				margin-top: 86px;
			}
		`}
`;
