import ReactModal from 'react-modal';
import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const ModalContent = styled(ReactModal)`
	position: absolute;
	width: ${({ isEmpty }) => (isEmpty ? 'auto' : '1000px')};
	max-width: 90%;
	max-height: 90%;
	justify-content: ${({ isPortrait }) =>
		isPortrait ? 'center !important' : 'space-between'};
	border: 1px solid ${colors.grey};
	background: ${colors.white};
	overflow: auto;
	border-radius: 10px;
	outline: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: rgb(0 0 0 / 5%) 0px 2px 8px;

	.pdf-container {
		overflow: auto;
		height: calc(60vh + 20px);
		margin-bottom: -20px;
		padding-bottom: 20px;
		text-align: center;

		@media ${device.mobileXL} {
			height: 55vh;
		}
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	padding: 20px;
	overflow: auto;
`;

export const MainColumn = styled(Column)`
	overflow-y: auto;
	padding: 0 10px 0 0;

	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const Loader = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 20vh;
	width: 20vw;
	margin: auto;
	padding: 20px;
	@media ${device.mobileM} {
		padding: 20px;
		margin: 70px;
	}
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-top: 30px;

	@media ${device.mobileXL} and (${device.landscape}) {
		flex-direction: row;
		margin-top: 14px;
	}
`;
