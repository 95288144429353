import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	width: 100%;
	padding: 17px;
	margin-bottom: 17px;
	border-radius: 10px;
	border: 1px solid ${colors.lightGrey};
`;

export const Title = styled.h1`
	font-weight: 800;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.eighteen]};
`;

export const Table = styled.div`
	width: 100%;
	margin-top: 14px;
	border-radius: 10px;
	border: 1px solid ${colors.lightGrey};
`;

export const TableRow = styled.div`
	display: flex;
	width: 100%;
	border-bottom: 1px solid ${colors.lightGrey};

	&:last-child {
		border-bottom: none;
	}
`;

export const TableColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: ${({ isTwoColumn }) => (isTwoColumn ? '50%' : '25%')};

	height: 100%;
	border-right: 1px solid ${colors.lightGrey};
	border-left: 1px solid ${colors.lightGrey};

	&:last-child {
		width: 50%;
		border: none;
	}

	&:first-child {
		border: none;
	}

	div {
		width: 100%;
		display: inline-block;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
		text-align: center;
		border-right: none;

		font-weight: ${({ selectedRow }) => (selectedRow ? '800' : '')};

		&:first-child {
			height: 33px !important;
		}
	}
`;

export const HeaderCell = styled.div`
	width: ${({ thirdColumnExist }) => (!thirdColumnExist ? '25%' : '50%')};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px;
	color: ${colors.darkGrey};
	border-right: 1px solid ${colors.lightGrey};
	border-bottom: 1px solid ${colors.lightGrey};
	font-weight: 800;

	:last-child {
		border-bottom: none;
	}
`;

export const TableData = styled(HeaderCell)`
	font-weight: normal;
	border-bottom: 1px solid ${colors.lightGrey};

	background: ${({ isSelected }) =>
		isSelected ? 'rgba(196, 196, 196, 0.2);' : 'transparent'};

	color: ${({ isSelected }) => (isSelected ? colors.navy : colors.darkGrey)};

	font-weight: ${({ isSelected }) => (isSelected ? '800' : 'normal')};
`;
