import { useEffect, useState } from 'react';

import { sendBirdSelectors, useSendbirdStateContext } from 'sendbird-uikit';

// useUnreadMessagesCount will return value that will update every 5 seconds with the latest count of
// unread messages for current send bird user.
const useUnreadMessagesCount = () => {
	const context = useSendbirdStateContext();
	const sdk = sendBirdSelectors.getSdk(context);

	const [unreadCount, setCount] = useState(0);

	useEffect(() => {
		if (sdk && sdk.getTotalUnreadMessageCount) {
			const h = setInterval(() => {
				sdk.getTotalUnreadMessageCount((count, err) => {
					setCount(count);
					if (err) setCount(0);
				});
			}, 5000);
			return () => clearInterval(h);
		}
	}, [sdk]);

	return unreadCount;
};

export default useUnreadMessagesCount;
