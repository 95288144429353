import React, { VFC } from 'react';

import * as Styled from './Titles.styled';

interface TitlesProp {
	mainTitle: string;
	subTitle?: string;
	subTitleMore?: string;
	isMyHealthSection?: boolean;
}

const Titles: VFC<TitlesProp> = ({
	mainTitle,
	subTitle,
	subTitleMore,
	isMyHealthSection,
}) => {
	return (
		<Styled.Container>
			{isMyHealthSection ? null : (
				<Styled.MainTitle>{mainTitle}</Styled.MainTitle>
			)}
			{subTitle && <Styled.SubTitle>{subTitle}</Styled.SubTitle>}
			{subTitleMore && (
				<Styled.SubTitleMore>{subTitleMore}</Styled.SubTitleMore>
			)}
		</Styled.Container>
	);
};

export default Titles;
