import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	align-items: center;
	margin-left: 25px;

	@media ${device.tablet} {
		margin-left: 0;
	}
`;

export const GreetingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 8px;
`;

export const Greeting = styled.div`
	color: ${colors.navy};
	font-weight: 500;
	margin: 0 10px;
	${fontStyles[fontSizes.fourteen]};
`;

export const Username = styled.div`
	color: ${colors.navy};
	font-weight: 900;
	margin: 0 10px;
	${fontStyles[fontSizes.eighteen]};
`;
