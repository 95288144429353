import React from 'react';

import { useSelector } from 'react-redux';

import { colorsListMapping } from './ChartLegend.helper';

import * as Styled from './ChartLegend.styled';

const ChartLegend = ({ labelsData, tableData }) => {
	const { chartId } = useSelector((state) => state.chartData);

	const colorsList = colorsListMapping[chartId];
	const chartLabel = labelsData && labelsData.find((value) => value !== '');

	return (
		<>
			<Styled.Row>
				{chartLabel && (
					<Styled.DateContainer>
						First Result:
						<Styled.ChartPointer />
						<Styled.ChartDate>{chartLabel}</Styled.ChartDate>
					</Styled.DateContainer>
				)}

				{tableData && colorsList && (
					<Styled.ColorsList>
						{colorsList.map(({ colorName, color }) => (
							<Styled.Container key={color} title={colorName}>
								<Styled.Circle background={color} />
								<Styled.RefName>{colorName}</Styled.RefName>
							</Styled.Container>
						))}
					</Styled.ColorsList>
				)}
			</Styled.Row>
		</>
	);
};

export default ChartLegend;
