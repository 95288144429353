import {
	PATIENT_EXPERIENCE_COORDINATOR,
	PROGRAM_SUPPORT,
	MY_HEALTH,
	NUTRITION_FITNESS_LIFESTYLE,
	LAB_RESULTS_PRESCRIPTION_CLINICAL_CARE,
	HEALTH_COACH,
	PHYSICIAN,
	SENDBIRD_ERROR_MESSAGE,
} from 'utils/internationalization/en';

export const cenegenicsID = 'cenegenics';

const sendMessageOptions = {
	program_support: PROGRAM_SUPPORT,
	my_health: MY_HEALTH,
	nutrition_fitness_lifestyle: NUTRITION_FITNESS_LIFESTYLE,
	labresults_prescriptions_clinicalcare: LAB_RESULTS_PRESCRIPTION_CLINICAL_CARE,
};

const myCareTeamRoles = {
	service_coordinator: PATIENT_EXPERIENCE_COORDINATOR,
	health_coach: HEALTH_COACH,
	physician: PHYSICIAN,
};

export const handleCreateChannel = async (
	sdk,
	createChannel,
	channelName,
	userIds
) => {
	if (!userIds || userIds.length === 0) return;
	let params = new sdk.GroupChannelParams();

	params.isPublic = false;
	params.isEphemeral = false;
	params.isDistinct = true;
	params.addUserIds(userIds);
	params.name = channelName;

	return await createChannel(params)
		.then((channel) => {
			return channel.url;
		})
		.catch((error) => {
			console.error(SENDBIRD_ERROR_MESSAGE, error);
			return null;
		});
};

export const interlocutors = (members, userId) =>
	members.filter((m) => m.userId !== userId);

export const getChannelName = (list, name) =>
	list
		.map((li) => li[name])
		.join(', ')
		.replace(/,(?!.*,)/gim, ' and');

export const getSelectedMember = (myCareTeam, selectedProgram) => {
	const cenegenics = {
		id: `${process.env.REACT_APP_USER_ID_SENDBIRD}`,
		user_in_sendbird: true,
	};

	let result = {};

	switch (selectedProgram) {
		case sendMessageOptions.labresults_prescriptions_clinicalcare:
			result = myCareTeam.find((member) =>
				member.jobRole.includes(myCareTeamRoles.service_coordinator)
			);
			break;
		case sendMessageOptions.nutrition_fitness_lifestyle:
			result = myCareTeam.find((member) =>
				member.jobRole.includes(myCareTeamRoles.health_coach)
			);
			break;
		default:
			result = cenegenics;
	}

	if (result && !result.user_in_sendbird) {
		result = cenegenics;
	}

	if (typeof result === 'undefined') {
		result = cenegenics;
	}

	return result;
};

export const findPhoto = (teamList, userPhotoId, defaultPhoto) => {
	if (userPhotoId === cenegenicsID) {
		return defaultPhoto;
	}
	return teamList.find((t) => t.id === userPhotoId)?.photo || '';
};

export const sendMessageButtonsLabels = {
	program_support: sendMessageOptions.program_support,
	my_health: sendMessageOptions.my_health,
	healthOptions: [
		sendMessageOptions.nutrition_fitness_lifestyle,
		sendMessageOptions.labresults_prescriptions_clinicalcare,
	],
};
