import {
	OrdersResponse,
	ChangeRequested,
} from 'components/Orders/Orders.helper';

export const ordersState: {
	order_id: '';
	order_type: '';
	tracking_number: '';
	order_status: '';
	shipment_status: '';
	products: [];
	order_details: {
		address: {};
		patient_name: '';
		order_date: '';
		physician_name: '';
	};
	patient_requested_change: false;
}[] = [];

const orders = {
	state: ordersState,
	reducers: {
		removeOrder: (state: any, payload: string) => {
			return {
				...state,
				ordersStore: state.ordersStore.filter(
					(order: any) => order.order_id !== payload
				),
			};
		},
		setPendingOrders: (state: any, payload: OrdersResponse[]) => {
			return {
				...state,
				pending: payload,
			};
		},
		updatePendingOrders: (state: any, payload: string) => {
			return {
				...state,
				pending: state.pending?.filter(
					(order: any) => order.order_id !== payload
				),
			};
		},
		addOrders: (state: any, payload: OrdersResponse) => ({
			...state,
			ordersStore: payload,
		}),
		updateRequestedChanges: (state: any, payload: ChangeRequested) => {
			let updatedOrder = state.ordersStore.map((order: any) => {
				if (order.order_id === payload.orderId) {
					return { ...order, hasRequestedChange: payload.hasRequestedChange };
				} else {
					return { ...order, hasRequestedChange: false };
				}
			});
			return {
				...state,
				ordersStore: updatedOrder,
			};
		},
	},
};

export default orders;
