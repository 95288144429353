import React from 'react';

import { MAX, MIN } from 'utils/internationalization/en';

import * as Styled from './TestResultScale.styled';

interface TestResultScaleWithRangeProps {
	rangeSize: number;
	resultPosition?: number;
	isOutOfRange?: boolean;
	isPercentage?: boolean;
	high?: number;
	low?: number;
}

export default function TestResultScaleWithRange({
	rangeSize,
	resultPosition,
	isOutOfRange,
	isPercentage,
	high,
	low,
}: TestResultScaleWithRangeProps) {
	if (isPercentage) {
		return (
			<Styled.Container>
				<Styled.PercentNormalRange size={rangeSize}></Styled.PercentNormalRange>
			</Styled.Container>
		);
	}
	return (
		<Styled.Container>
			<Styled.MINMAXContainer>
				<Styled.MIN>
					{MIN} ({low})
				</Styled.MIN>
				<Styled.SpaceBetween width={rangeSize * 100} />
				<Styled.MAX position={rangeSize * 100}>
					{MAX} ({high})
				</Styled.MAX>
			</Styled.MINMAXContainer>
			<Styled.NormalRange size={rangeSize * 100}></Styled.NormalRange>
			{resultPosition && (
				<Styled.BlackDot min={resultPosition * 100}>
					<Styled.BlackDotInner isOutOfRange={isOutOfRange} />
				</Styled.BlackDot>
			)}
		</Styled.Container>
	);
}
