export const SET_PERIOD_FILTER = 'SET_PERIOD_FILTER';
export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
export const SET_TAGS_FILTER = 'SET_TAGS_FILTER';

export type SetPeriodFilterAction = {
	type: typeof SET_PERIOD_FILTER;
	payload: string | number | null;
};

export type SetTypeFilterAction = {
	type: typeof SET_TYPE_FILTER;
	payload: string | number | null;
};

export type SetTagsFilterAction = {
	type: typeof SET_TAGS_FILTER;
	payload: string[];
};

export type TestResultsFiltersActions =
	| SetPeriodFilterAction
	| SetTypeFilterAction
	| SetTagsFilterAction;
