import { TabOptions } from 'components/Tabs/Tabs';

export enum MyProductsTabs {
	recurring = 'recurring',
	extras = 'extras',
}

export const options: TabOptions<MyProductsTabs>[] = [
	{ name: MyProductsTabs.recurring, label: 'Recurring' },
	{ name: MyProductsTabs.extras, label: 'Extras' },
];
