import styled from 'styled-components/macro';

const PageContainer = styled.section<{ hasBottomNavigation?: boolean }>`
	padding: ${({ hasBottomNavigation }) =>
		hasBottomNavigation ? '8px 28px 28px 28px' : '28px'};
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export default PageContainer;
