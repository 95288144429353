import React from 'react';

import { useMediaQuery } from 'react-responsive';

import arrowRight from 'assets/svg/icons/arrow-right.svg';
import Tooltip from 'components/Tooltip/Tooltip';
import { device } from 'styles/device';
import { chartsKey } from 'utils/constants';
import { getWholeNumber } from 'utils/helpers';

import { handleBloodPressure } from './Item.helper';

import * as Styled from './Item.styled';

const Item = ({
	chartId,
	name,
	value,
	mapKey,
	healthIndicator,
	icon,
	type,
	data,
	onClick,
}) => {
	const showDecimalValue =
		chartId === chartsKey.waistHipRatio ||
		chartId === chartsKey.arterialThickness;
	const bloodPressure = handleBloodPressure(mapKey, data);

	const paramValue = showDecimalValue
		? data[mapKey]
		: getWholeNumber(data[mapKey]);

	const isMobile = useMediaQuery({ query: device.tablet });
	const result = bloodPressure || paramValue || value;

	return (
		<>
			{result !== 'N/A' && (
				<Styled.Container key={name} onClick={onClick} tabIndex='0'>
					<Styled.ItemHeader>
						<Styled.ItemTitle title={name}>
							<Styled.ItemTitleText>{name}</Styled.ItemTitleText>
							<Tooltip mapKey={mapKey} color='white' />
						</Styled.ItemTitle>

						{isMobile && (
							<Styled.Button>
								<img src={arrowRight} alt='Open chart' />
							</Styled.Button>
						)}
					</Styled.ItemHeader>
					<Styled.Content>
						<Styled.Value>
							<Styled.Measurement name={chartId}>{result}</Styled.Measurement>
							<Styled.Units name={type}>{type}</Styled.Units>
						</Styled.Value>

						<img src={icon} alt={name} name={name} />
					</Styled.Content>
				</Styled.Container>
			)}
		</>
	);
};
export default Item;
