import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const TestFiltersContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	flex-direction: row;
	> div:not(:first-child) {
		margin-left: 30px;
	}
	@media ${device.tablet} {
		> div:not(:first-child) {
			margin-left: 0px;
		}
		flex-direction: column;
	}
`;

export const TestFiltersItemContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	gap: 12px;
`;

export const TestFiltersTitle = styled.div`
	flex: 0 1 auto;
	font-size: 18px;
	line-height: 25px;
	color: ${colors.black};
`;

export const TestFiltersFilterContainer = styled.div`
	flex: 1 1 auto;
`;
