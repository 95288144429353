import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const ChartTitle = styled.h1`
	font-weight: 800;
	color: ${colors.darkGrey};
	font-size: 18px;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 200px;

	@media ${device.tablet || device.mobileL} {
		align-items: flex-start;
		margin-bottom: 10px;
	}
`;

export const MainColumn = styled(Column)`
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	@media ${device.tablet || device.mobileL} {
		background-color: white;
		border-radius: 20px;
		padding: 15px;
	}
`;

export const ChartWrapper = styled.section`
	width: 100%;
	height: 100%;
	padding: 18px;
	border-radius: 10px;
	border: 1px solid ${colors.lightGrey};
	position: relative;
`;

export const EmptyState = styled(Column)`
	@media ${device.tablet || device.mobileL} {
		height: 10px;
	}
	padding: 20px;
`;

export const ChartContainer = styled.div`
	position: relative;
	min-height: 294px;
	width: 100%;
`;

export const ChartTitleMobile = styled.div`
	display: none;
	@media ${device.tablet || device.mobileL} {
		display: block;
		align-items: flex-start;
		padding: 20px;
		font-weight: 800;
		font-size: 20px;
		line-height: 22px;
	}
`;
