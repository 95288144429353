import { MAX, MIN } from 'utils/internationalization/en';

const removeGreaterThanLowerThan = (result: string) =>
	result?.replace(/^(\s+)?(>|<)(\s+)?/, '');

export const calculateDimensions = (
	rangeLow: number,
	rangeHigh: number,
	result: string
): {
	size: number | undefined;
	dotPos: number | undefined;
	isOutOfRange: boolean;
	isValid: undefined;
} | null => {
	const multi = 2;
	const parsedResult = parseFloat(removeGreaterThanLowerThan(result));
	const rangeDifference = rangeHigh - rangeLow;
	const isOutOfRange = parsedResult < rangeLow || parsedResult > rangeHigh;

	let leftV, rightV, midV, vRange, size, dotPos, isValid;

	if (isNaN(parsedResult)) {
		return null;
	}
	if (!isOutOfRange) {
		//black dot is withing range
		vRange = rangeDifference * (multi + 1); // what is the total range we need to disp
		size = 0.33; // bar size in relation to total range of values supported
		dotPos = (parsedResult - (rangeLow - rangeDifference)) / vRange;
	} else if (parsedResult < rangeLow) {
		// value is smaller than range
		leftV = Math.min(parsedResult, rangeLow - rangeDifference);
		midV = rangeLow + rangeDifference / 2;
		vRange = (midV - leftV) * 2; // what is the total range we need to disp
		size = Math.max(rangeDifference / vRange, 0.15); // bar size in relation to total range of values supported
		const resultDividedByTwo = parsedResult / 2;
		const resultSubtractedByLeftV =
			parsedResult - leftV === 0 ? resultDividedByTwo : parsedResult - leftV;
		dotPos = resultSubtractedByLeftV / vRange;
	} else if (parsedResult > rangeHigh) {
		//  value is greater than normal range
		rightV = Math.max(rangeHigh + rangeDifference, parsedResult);
		midV = rangeLow + rangeDifference / 2;
		vRange = (rightV - midV) * 2; // what is the total range we need to disp
		size = Math.max(rangeDifference / vRange, 0.15); // bar size in relation to total range of values supported
		leftV = rightV - vRange;
		dotPos = (parsedResult - leftV) / vRange;
	}
	return { size, dotPos, isOutOfRange, isValid };
};

export enum AvailableReferenceValues {
	LOW_ONLY = 'low',
	HIGH_ONLY = 'high',
	LOW_AND_HIGH = 'both',
	NONE = 'none',
}

export type IncompleteReferenceValue =
	| AvailableReferenceValues.HIGH_ONLY
	| AvailableReferenceValues.LOW_ONLY;
export interface BiomarkerTestResult {
	Category__c: string;
	Cenegenics_High__c: number;
	Cenegenics_Low__c: number;
	Cenegenics_Test_Standards__c: string;
	Cenegenics_Test_Standards__r: {
		Id: string;
		Display_Order__c: number;
	};
	Display_Name: string;
	Id: string;
	Local_Code_Name__c: string;
	Local_Code__c: string;
	Quest_High__c: null;
	Quest_Low__c: null;
	Range_Low: number;
	Range_high: number;
	Result_Range__c: string;
	Result__c: string;
	Specific_Test_Name__c: string;
	Specimen_Collection_Date_Time__c: string;
	Standards_TestName__c: string;
	Type: string;
	Unit_of_Measure__c: string;
	result_source: string;
	Collected_Date_Time__c: string;
}

export function getAvailableReferenceValues(
	o: BiomarkerTestResult
): AvailableReferenceValues {
	if (typeof o.Range_high === 'number' && typeof o.Range_Low === 'number') {
		return AvailableReferenceValues.LOW_AND_HIGH;
	}
	if (typeof o.Range_high === 'number') {
		return AvailableReferenceValues.HIGH_ONLY;
	}
	if (typeof o.Range_Low === 'number') {
		return AvailableReferenceValues.LOW_ONLY;
	}
	return AvailableReferenceValues.NONE;
}

const refValuePositions = {
	low: [25, 40, 50],
	high: [55, 70, 80],
};

export const getReferenceValuePosition = (
	result: string,
	referenceValue: number, // FIX ME: make sure this is not a string?
	rangeType: IncompleteReferenceValue
): number | null => {
	const parsedResult = parseFloat(removeGreaterThanLowerThan(result));
	if (isNaN(parsedResult)) {
		return null;
	}
	const compared = referenceValue - parsedResult;
	const comparedNotNull = compared > 0 ? 0 : 2;
	return refValuePositions[rangeType][compared === 0 ? 1 : comparedNotNull];
};

export const isFirst3CharactersLetters = (str: string): boolean =>
	/^[a-zA-Z]+$/.test(str?.substring(0, 3));

export const nonNumericaTestResult = (result: string): string =>
	result && result?.charAt(0)?.toUpperCase() + result?.slice(1)?.toLowerCase();

export const nonNumericaTestResultRefRange = (result: string): string =>
	result && result?.charAt(0)?.toUpperCase() + result?.slice(1)?.toLowerCase();

export const ReferenceRangeValues = (
	rangeLeft: string,
	rangeRight: string,
	high: number,
	low: number,
	result?: string
) => {
	let leftWidth = 64;
	let rightWidth = 30;
	let minMAX_Range = '';
	let rangeValue = 0;
	let position = 0;
	let resultRange = result && parseFloat(result);
	let isResultOutOfRange = false;
	if (rangeLeft === AvailableReferenceValues.HIGH_ONLY) {
		minMAX_Range = MAX;
		position = 20;
		rangeValue = high;
		isResultOutOfRange = resultRange! > rangeValue;
	}
	if (rangeRight === AvailableReferenceValues.LOW_ONLY) {
		leftWidth = 30;
		rightWidth = 64;
		minMAX_Range = MIN;
		position = 55;
		rangeValue = low;
		isResultOutOfRange = resultRange! < rangeValue;
	}
	return {
		leftWidth,
		rightWidth,
		minMAX_Range,
		position,
		rangeValue,
		isResultOutOfRange,
	};
};

export const IsNonNumericResultRangeDiff = (testResult: BiomarkerTestResult) =>
	testResult.Result_Range__c === null ||
	testResult.Result__c === testResult.Result_Range__c;
