import React from 'react';

import { find, get } from 'lodash';

import { tagsListIcons, tagsListIconsActive } from '../TestFiltersConstants';

type TestTagIconProps = {
	tagId: number;
	active: boolean;
};

const TestTagIcon = ({ tagId, active }: TestTagIconProps) => {
	const tagList = active ? tagsListIconsActive : tagsListIcons;
	const icon = get(find(tagList, { id: tagId }), 'icon', undefined);
	return !icon ? <></> : <img src={icon} alt={icon} />;
};

export default TestTagIcon;
