import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

export const Card = styled.div`
	width: 310px;
	height: 192px;
	background: #ffffff;
	/* Gray 6 */

	border: 1px solid #f2f2f2;
	/* Card shadow */

	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
`;

export const TakeActionContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;

	${Card} {
		flex: 0 0 auto;
		padding: 20px;
		margin: 10px 10px 0 0;
		font-family: 'Avenir';
		font-style: normal;
		font-size: 18px;
		line-height: 25px;
		height: auto;
	}
`;

export const CardTitle = styled.p`
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 25px;
	margin-bottom: 20px;
`;

export const TakeActionTitle = styled.p`
	margin: 15px 0 10px 0;
	font-weight: 900;
	font-size: 18px;
`;

export const Paragraph = styled.p`
	font-weight: normal;
	color: ${colors.darkGrey};
`;
