export const fontSizes = {
	ten: 'ten',
	twelve: 'twelve',
	thirteen: 'thirteen',
	fourteen: 'fourteen',
	fifteen: 'fifteen',
	sixteen: 'sixteen',
	eighteen: 'eighteen',
	twenty: 'twenty',
	twentyTwo: 'twentyTwo',
	twentyFour: 'twentyFour',
	twentySix: 'twentySix',
	thirty: 'thirty',
	fourty: 'fourty',
	fifty: 'fifty',
	sixty: 'sixty',
	thirtyFive: 'thirtyFive',
};

export const fontStyles = {
	[fontSizes.ten]: 'font-size: 10px; line-height: 14px;',
	[fontSizes.twelve]: 'font-size: 12px; line-height: 16px;',
	[fontSizes.thirteen]: 'font-size: 13px; line-height: 17px;',
	[fontSizes.fourteen]: 'font-size: 14px; line-height: 18px;',
	[fontSizes.fifteen]: 'font-size: 15px; line-height: 19px;',
	[fontSizes.sixteen]: 'font-size: 16px; line-height: 20px;',
	[fontSizes.eighteen]: 'font-size: 18px; line-height: 24px;',
	[fontSizes.twenty]: 'font-size: 20px; line-height: 26px;',
	[fontSizes.twentyTwo]: 'font-size: 22px; line-height: 26px;',
	[fontSizes.twentyFour]: 'font-size: 24px; line-height: 30px;',
	[fontSizes.twentySix]: 'font-size: 26px; line-height: 32px;',
	[fontSizes.thirty]: 'font-size: 30px; line-height: 36px;',
	[fontSizes.thirtyFive]: 'font-size: 35px; line-height: 39px;',
	[fontSizes.fourty]: 'font-size: 40px;',
	[fontSizes.fifty]: 'font-size: 50px;',
	[fontSizes.sixty]: 'font-size: 60px;',
	[fontSizes.ninety]: 'font-size: 90px;',
};
