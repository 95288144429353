import styled from 'styled-components/macro';

import Button from 'components/Button/Button';
import { device } from 'styles/device';

export const Icon = styled.button`
	margin: -45px 0 10px 55px;
	outline: none;
	border: none;
	background: transparent;
	cursor: pointer;
	z-index: 9;

	@media ${device.laptopL} {
		margin: -45px 0 10px 40px;
	}
`;

export const EnhancedButton = styled(Button)`
	@media ${device.mobileM} {
		margin: 0;
		width: 100%;
	}
`;

export const EnhancedInput = styled.input`
	@media ${device.mobileM} {
		margin: 0;
		width: 100%;
	}
`;
