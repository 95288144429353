import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import { device } from '../../styles/device';
import { fontSizes, fontStyles } from '../../styles/fonts';

export const CardsContainer = styled.div`
	margin-top: 20px;
	margin-left: 0;
	margin-right: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
`;

export const EmptyContainer = styled.div`
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: ${colors.darkGrey};
	text-align: center;

	@media ${device.mobileL} {
		height: 50vh;
	}
`;

export const IconWrapper = styled.div`
	display: flex;
	width: 230px;
	height: 140px;
	border-radius: 13px;
	background: ${colors.softBlue};
	align-items: center;
	justify-content: center;
`;

export const Icon = styled.img``;

export const EmptyTitle = styled.div`
	margin: 20px 0;
	${fontStyles[fontSizes.eighteen]}
`;

export const EmptySubtitle = styled.div`
	${fontStyles[fontSizes.fourteen]}
`;
