import React from 'react';

import { withRouter } from 'react-router-dom';

import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			if (this.state.hasError) {
				this.setState({ hasError: false });
			}
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return <ErrorFallback />;
		}
		return this.props.children;
	}
}

export default withRouter(ErrorBoundary);
