import React, { useState, useEffect } from 'react';

import { Bar } from 'react-chartjs-2';

import { useGetBloodPressure } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';

import {
	handleBarChartData,
	adaptBloodPressureTable,
	increaseOrDecreaseMaxYThreshold,
	getChartMaxValue,
	getChartMinValue,
} from './utils/chartHelpers';
import { defaultBarChartConfig } from './utils/customViewChart';

const BloodPressure = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [customBarChartConfig, setCustomBarChartConfig] = useState(
		defaultBarChartConfig
	);
	const [tableData, setTableData] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { data: bloodPressureData, loading } = useGetBloodPressure();
	const isDataAvailable =
		bloodPressureData?.data && bloodPressureData.data.length > 0;
	const lastElement = isDataAvailable && bloodPressureData.data.length - 1;

	useEffect(() => {
		if (isDataAvailable) {
			const dataAdapted = handleBarChartData(bloodPressureData?.data);
			const lastResultBP = {
				value: bloodPressureData.data[lastElement].systolic,
			};
			const tableDataAdapted = adaptBloodPressureTable(
				bloodPressureData?.referential_values,
				lastResultBP
			);

			let minY = customBarChartConfig.scales.y.min;
			let maxY = customBarChartConfig.scales.y.max;

			const minValueOfY = getChartMinValue(bloodPressureData.data, 'diastolic');
			const maxValueOfY = getChartMaxValue(bloodPressureData.data, 'systolic');

			if (maxValueOfY && maxValueOfY >= maxY) {
				setCustomBarChartConfig({
					...customBarChartConfig,
					scales: {
						...customBarChartConfig.scales,
						y: {
							...customBarChartConfig.scales.y,
							max: maxValueOfY + increaseOrDecreaseMaxYThreshold.xl,
						},
					},
				});
			}

			if (minValueOfY && minValueOfY <= minY) {
				setCustomBarChartConfig({
					...customBarChartConfig,
					scales: {
						...customBarChartConfig.scales,
						y: {
							...customBarChartConfig.scales.y,
							min: minValueOfY,
						},
					},
				});
			}

			setData(dataAdapted);
			setTableData(tableDataAdapted);
			setLabelsData(dataAdapted?.labels);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bloodPressureData]);

	return (
		<ChartModal
			data={data}
			loading={loading}
			tableData={tableData}
			labelsData={labelsData}
			closeChart={closeChart}
			isOpen={isOpen}
		>
			<Bar data={data} options={customBarChartConfig} />
		</ChartModal>
	);
};

export default BloodPressure;
