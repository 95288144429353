import React from 'react';

import { find, get } from 'lodash';

import {
	tagsList,
	tagsListIconsActive,
} from '../../TestFilters/TestFiltersConstants';

import { CardTagsContainer, CardTagItem } from './TestCartTags.styled';

type TestCardTagsProps = {
	tags?: string[];
};

export const TestCardTags = ({ tags }: TestCardTagsProps) => {
	return (
		<CardTagsContainer>
			{tags?.map((tagsItem) => {
				const tagId = get(find(tagsList, { value: tagsItem }), 'id', null);
				const icon =
					tagId === null
						? null
						: get(find(tagsListIconsActive, { id: tagId }), 'icon', null);
				return (
					icon && (
						<CardTagItem key={tagsItem}>
							<img src={icon} alt={tagsItem} />
						</CardTagItem>
					)
				);
			})}
		</CardTagsContainer>
	);
};
