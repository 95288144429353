import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const Container = styled.div`
	background: ${colors.white};
	border-radius: 10px;
	flex: 0 0 220px;
	display: flex;
	margin: 10px;
	justify-content: center;

	@media ${device.mobileL} {
		width: 100%;
		margin-bottom: 20px;
		max-width: unset;

		:last-child {
			margin-bottom: 0;
		}
	}
`;

export const HeaderContainer = styled.div`
	width: 100%;
	display: flex;
`;

export const LoaderContainer = styled.div`
	width: 100%;
	display: flex;
	margin-top: 30px;
	flex-wrap: wrap;

	@media ${device.laptop} {
		width: 100%;
	}

	@media ${device.mobileL} {
		flex-direction: column;
	}
`;
