import infoIconWhite from 'assets/svg/icons/info-white.svg';
import infoIcon from 'assets/svg/icons/info.svg';
import { chartsKey } from 'utils/constants';

export const icons = {
	infoIcon,
	white: infoIconWhite,
};

export const tooltips = {
	[chartsKey.totalBodyFat]: `Weight is an important measure of health, but there are many
	other factors to consider – like your body fat percentage. Lean body mass,
	bone, and body fat make up your body composition. Your total body fat
	percentage is a measure of how much body fat you have in relation to your
	overall weight.`,
	[chartsKey.muscleMass]: `The amount of lean muscle tissue your body has (not to be
	confused with fat free mass, which includes bones, organs, and water).
	High levels of muscle mass compared to fat mass is correlated with higher
	metabolism, lower risk of chronic disease, and decreased risk of
	osteoporosis (the weakening and loss of bone mass).`,
	[chartsKey.fatMass]: `The amount of adipose/fat tissue in the body. There are 2 types of
	fat mass. 1) Subcutaneous: Found directly below the skin and can be pinched.
	2) Visceral: Weaves its way internally through our organs. Lower fat mass
	(while still within a healthy range) is correlated with decreased risk of
	chronic disease, including fatty liver.`,
	[chartsKey.upperTorsoFat]: `Gynoid region fat is found in the legs and hips and poses
	little threat to long term health. Android region fat is where the visceral
	fat is found, which innervates our organs and decreases their efficiency.
	High levels of android fat are correlated highly with chronic disease risk
	and can be due to high stress, poor sleep, poor nutrition, a sedentary
	lifestyle, and hormonal imbalance.`,
	[chartsKey.vo2Max]: `This is a measurement of maximal cardiovascular fitness. It
	represents our body’s ability to take in oxygen and remove carbon dioxide
	as the intensity of exercise increases. This number helps us assess how
	efficient your body is, while also pinpointing the fuel sources your body
	relies on at certain exercise intensities. A high VO2 is correlated with a
	lower risk of cardiovascular and respiratory disease.`,
	[chartsKey.peakHeartRate]: `Peak Heart Rate is the highest heart rate achieved during
	your exercise test. During the VO2 max exercise test, your heart rate
	responds to an increase in intensity by increasing heart rate to deliver
	oxygenated blood to tissues.`,
	[chartsKey.anaerobicThreshold]: `This is the heart rate in which your body begins to
	produce large amounts of lactic acid and hydrogen. This leads to a decrease
	in pH, which leads to burning in the muscles and the onset of fatigue. A
	higher anaerobic threshold is correlated with greater cardiovascular
	efficiency, which leads to decreased risk of chronic disease development.`,
	[chartsKey.maxWattage]: `This is defined as the maximal amount of power that your body
	can produce (typically during max testing). A high wattage is correlated
	with high type 2 muscle fibers, which are strong and powerful. High power
	output and type 2 muscle mass is correlated with decreased fracture risk and
	reduced risk of degenerative disease, such as osteoporosis.`,
	[chartsKey.restingHeartRate]: `The rate at which your heart beats when you are in a
	relaxed, non-active state. Although this number can fluctuate easily, a
	healthy resting heart rate should be well below 100 beats per minute (bpm).
	A low resting heart rate correlates with efficient stress control and
	decreased chronic disease risk.`,
	[chartsKey.bloodPressure]: `Typically represented in two numbers: 1) Systolic: The top
	number, which is the amount of pressure that the heart faces when pumping
	blood throughout the body. 2) Diastolic: The bottom number, which represents
	the pressure in the heart when it refills. Normal is considered to be 120/80.
	Although variations can occur regularly and due to genetics, high blood
	pressure is correlated with an increased risk of cardiovascular disease and
	other chronic diseases.`,
	[chartsKey.waistHipRatio]: `A measurement of the circumference of your waist divided by
	the circumference of your hips. The higher this number is, the more likely
	there is also elevated android region fat, which correlates with increased
	chronic disease risk. Example: 32 inch waist / 38 inch hips = .84 ratio.`,
	[chartsKey.arterialThickness]: `On the inside of our arteries is a protective lining,
	also known as the endothelial lining. Over time, natural thickening occurs
	that has little to no effect on long term health. Poor nutrition, poor
	sleep, lack of exercise, and excess alcohol consumption can lead to
	thickening under the lining. Increased arterial thickness through poor
	lifestyle is correlated with increased risk for high blood pressure, high
	resting heart rate, and cardiovascular disease.`,
	biomarkers: `Physicians decide whether to use the Optimal Range, Standard Range, or a hybrid of the two depending on what makes the most sense given their approach to healthcare and the circumstances of the individual patient. The Standard Range is used by laboratories to identify lab values of concern. If you have questions about what the ranges, consult your doctor.`,
};
