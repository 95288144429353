import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	width: 60%;
	height: 100vh;
	display: flex;
	margin: auto;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media ${device.mobileL} {
		width: 90%;
	}
`;

export const Heading = styled.span`
	${fontStyles[fontSizes.twentyFour]}
	font-weight: 800;
	color: ${colors.navy};
	margin-top: 60px;
	text-align: center;
	margin-bottom: 30px;

	@media ${device.mobileL} {
		${fontStyles[fontSizes.sixteen]}
	}
`;

export const Image = styled.img`
	width: 60%;
`;

export const Contact = styled.a.attrs({
	href: 'mailto:app@cenegenics.com',
})`
	margin-top: 30px;
	color: ${colors.darkGrey};
	font-weight: 600;
`;
