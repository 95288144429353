import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import { colors } from 'styles/colors';

import { chartDotsArrayColors, defaultChartAxisValues } from './chartHelpers';

Chart.register(annotationPlugin);

// Chart config options starts here
export const fatBodyReflColors = [
	{
		colorName: 'Lean',
		color: '#2D9CDB',
	},
	{
		colorName: 'Optimal',
		color: '#6FCF97',
	},
	{
		colorName: 'Average',
		color: '#F2C94C',
	},
	{
		colorName: 'Moderately Overweight',
		color: '#F2994A',
	},
	{
		colorName: 'Overweight',
		color: '#EB5757',
	},
];

export const commonRefColors = [
	{
		colorName: 'Excellent',
		color: '#6FCF97',
	},
	{
		colorName: 'Good',
		color: '#2D9CDB',
	},
	{
		colorName: 'Average',
		color: '#F2C94C',
	},
	{
		colorName: 'Fair',
		color: '#F2994A',
	},
	{
		colorName: 'Poor',
		color: '#EB5757',
	},
];

export const hipRatioRefColors = [
	{
		colorName: 'Low',
		color: '#6FCF97',
	},
	{
		colorName: 'Moderate',
		color: '#2D9CDB',
	},
	{
		colorName: 'High',
		color: '#F2C94C',
	},
	{
		colorName: 'Very High',
		color: '#EB5757',
	},
];

const legendDefault = {
	display: false,
};

const tooltipDefault = {
	displayColors: false,
	backgroundColor: `${colors.white}`,
	bodyColor: `${colors.navy}`,
	titleColor: `${colors.darkGrey}`,
	borderColor: `#ccc`,
	bodyAlign: 'left',
	borderRadius: 10,
	borderWidth: 1,
	padding: { top: 10, left: 10, right: 10, bottom: 5 },
	titleFont: {
		weight: 'bold',
		family: 'Avenir, Open-Sans, Sans-Serif, Helvetica',
	},
	bodyFont: {
		weight: 'bold',
		family: 'Avenir, Open-Sans, Sans-Serif, Helvetica',
	},
};

const elementsDefault = {
	line: {
		borderWidth: 5,
		borderColor: `${colors.chartBlue}`,
		color: `${colors.chartBlue}`,
	},
	point: {
		pointBorderColor: `${colors.chartBlue}`,
		pointBackgroundColor: `${colors.chartBlue}`,
		hoverBorderColor: `${colors.chartBlue}`,
		hoverBackgroundColor: `${colors.white}`,
		borderWidth: 3,
		radius: 7,
		hoverRadius: 8,
		hoverBorderWidth: 4,
	},
};

const biomarkerElements = {
	line: {
		borderWidth: 5,
		borderColor: `${colors.chartBlue}`,
		color: `${colors.chartBlue}`,
	},
	point: {
		pointBackgroundColor: `${colors.white}`,
		borderWidth: 3,
		radius: 7,
		hoverRadius: 8,
		hoverBorderWidth: 4,
	},
};

const yAxisTicks = {
	font: {
		weight: 800,
		size: 14,
		family: 'Avenir, Open-Sans, Sans-Serif, Helvetica',
	},
	color: `${colors.navy}`,
	padding: 20,
	beginAtZero: true,
	autoSkip: true,
	maxTicksLimit: 5,
};

const yAxisTicksBiomakers = {
	font: {
		weight: 600,
		size: 12,
		family: 'Avenir, Open-Sans, Sans-Serif, Helvetica',
	},
	color: `${colors.grey65}`,
	padding: 4,
	beginAtZero: true,
	autoSkip: true,
	maxTicksLimit: 5,
	maxRotation: 0,
	minRotation: 0,
};

const xAxisDefault = {
	grid: {
		tickColor: 'transparent',
		borderDash: [10],
		borderDashOffset: 2,
		lineWidth: 2,
		color: function (context) {
			if (context.tick?.value === 0) {
				return `${colors.grey65}`;
			}
			return 'transparent';
		},
	},
	ticks: {
		font: {
			weight: 800,
			size: 14,
			family: 'Avenir, Open-Sans, Sans-Serif, Helvetica',
		},
		color: `${colors.grey65}`,
		padding: 10,
	},
};

const xAxisBiomarkers = {
	grid: {
		tickColor: 'transparent',
		borderDash: [0],
		borderDashOffset: 0,
		lineWidth: 1,
		color: function (context) {
			if (context.tick?.value === 0) {
				return `${colors.grey65}`;
			}
			return 'transparent';
		},
	},
	ticks: {
		font: {
			weight: 600,
			size: 12,
			family: 'Avenir, Open-Sans, Sans-Serif, Helvetica',
		},
		color: colors.grey65,
		padding: 2,
		maxRotation: 45,
		minRotation: 45,
		autoSkip: false,
	},
};

export const ticksCallback = (value) => `${value}`;
export const ticksCallbackPercentage = (value) => `${value} %`;
export const ticksCallbackLBS = (value) => `${value} lbs`;
export const ticksCallbackKG = (value) => `${value} ml/kg/min`;
export const ticksCallbackBPM = (value) => `${value} bpm`;
export const ticksCallbackMM = (value) => `${value} mm`;
export const ticksCallbackWatts = (value) => `${value} watts`;
export const ticksCallbackBP = (value) => `${value} mmHg`;

export const tooltipCallbackPercentage = (tooltipItems) =>
	`${tooltipItems.formattedValue} %`;

export const tooltipCallback = (tooltipItems) => tooltipItems.formattedValue;

export const tooltipCallbackLBS = (tooltipItems) =>
	`${tooltipItems.formattedValue} lbs`;

export const tooltipCallbackKG = (tooltipItems) =>
	`${tooltipItems.formattedValue} ml/kg/min`;

export const tooltipCallbackBPM = (tooltipItems) =>
	`${tooltipItems.formattedValue} bpm`;

export const tooltipCallbackWatts = (tooltipItems) =>
	`${tooltipItems.formattedValue} watts`;

export const tooltipCallbackBPressure = (tooltipItems) => {
	return `${tooltipItems.formattedValue[0] / tooltipItems.formattedValue[1]}`;
};

export const tooltipCallbackMM = (tooltipItems) =>
	`${tooltipItems.formattedValue} mm`;

// Chart  Configurations starts here
export const defaultConfig = {
	animation: {
		easing: 'easeInSine',
	},
	plugins: {
		backgrounds: {
			hbars: [],
		},
		legend: legendDefault,
		tooltip: { ...tooltipDefault, callbacks: { label: tooltipCallbackLBS } },
	},
	responsive: true,
	maintainAspectRatio: false,
	elements: elementsDefault,
	scales: {
		y: {
			ticks: {
				...yAxisTicks,
				callback: ticksCallbackLBS,
			},
		},
		x: xAxisDefault,
	},
};

export const biomarkerChartConfig = {
	animation: {
		easing: 'easeInSine',
	},
	plugins: {
		backgrounds: {
			hbars: [],
		},
		legend: legendDefault,
		tooltip: { ...tooltipDefault, callbacks: { label: tooltipCallbackLBS } },
	},
	responsive: true,
	maintainAspectRatio: false,
	elements: biomarkerElements,
	scales: {
		y: {
			grid: {
				color: function (context) {
					if (context.index === 0) {
						return colors.grey65;
					}
					return 'transparent';
				},
			},
			ticks: {
				...yAxisTicksBiomakers,
				callback: ticksCallbackLBS,
			},
		},
		x: xAxisBiomarkers,
	},
};

export const defaultBarChartConfig = {
	...defaultConfig,
	plugins: {
		legend: legendDefault,
		tooltip: { ...tooltipDefault, label: tooltipCallbackBPressure },
	},
	scales: {
		y: {
			min: defaultChartAxisValues.bloodPressure.minY,
			max: defaultChartAxisValues.bloodPressure.maxY,
			ticks: {
				...yAxisTicks,
				maxTicksLimit: 10,
			},
		},
		x: xAxisDefault,
	},
};

/**
 * @description In order to have first dot of different color
 * we need to make an array of color for every dot
 * @param {Number} chartsDotsLength
 * @returns {Object} elements object for charts
 */
export const styledChartElements = (chartsDotsLength) => {
	return {
		...defaultConfig.elements,
		point: {
			...defaultConfig.elements.point,
			pointBackgroundColor: [
				colors.white,
				...chartDotsArrayColors(chartsDotsLength, colors.chartBlue),
			],
		},
	};
};

/**
 * @description In order to have first dot of different color
 * we need to make an array of color for every dot
 * @param {Number} chartsDotsLength
 * @returns {Object} elements object for charts
 */
export const styledBiomarckerChartElements = (chartsDotsLength) => {
	return {
		...biomarkerChartConfig.elements,
		point: {
			...biomarkerChartConfig.elements.point,
			pointBackgroundColor: [
				colors.white,
				...chartDotsArrayColors(chartsDotsLength, colors.white),
			],
		},
	};
};
