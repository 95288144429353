import lightningProductIcon from 'assets/svg/icons/lightningProduct.svg';
import otherProductsIcon from 'assets/svg/icons/otherProducts.svg';
import rxIcon from 'assets/svg/icons/rx.svg';
import vitaminsIcon from 'assets/svg/icons/vitamins.svg';

export const productIcons = {
	rx: rxIcon,
	vitamins: vitaminsIcon,
	lightning: lightningProductIcon,
	other: otherProductsIcon,
};
