import React from 'react';

import { useSelector } from 'react-redux';

import boxIcon from 'assets/svg/icons/box.svg';
import {
	EMPTY_BIOMARKER_LINK,
	EMPTY_BIOMARKER_MESSAGE,
	IMAGE_ALT_TEXT,
} from 'utils/internationalization/en';

import * as Styled from './Biomarkers.styled';

const BiomarkerEmptyStateCard = ({ image }: { image: string }) => {
	const { allowMessaging } = useSelector((state: any) => state.user);

	return (
		<Styled.EmtyStateContainer className='empty-state-card'>
			<Styled.ImgWrapper>
				<img src={image || boxIcon} alt={IMAGE_ALT_TEXT} />
			</Styled.ImgWrapper>

			<Styled.TextWrapper>
				<Styled.Text>
					{EMPTY_BIOMARKER_MESSAGE}
					{allowMessaging ? (
						<Styled.Anchor to={'/messaging'}>
							{EMPTY_BIOMARKER_LINK}
						</Styled.Anchor>
					) : (
						EMPTY_BIOMARKER_LINK
					)}
				</Styled.Text>
			</Styled.TextWrapper>
		</Styled.EmtyStateContainer>
	);
};

export default BiomarkerEmptyStateCard;
