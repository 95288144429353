// eslint-disable-next-line import/named
import { init, RematchRootState, Models } from '@rematch/core';

import biomarkers from './models/biomarkers';
import biometrics from './models/biometrics';
import chartData from './models/chartData';
import messaging from './models/messaging';
import orders from './models/orders';
import orderTracking from './models/orderTracking';
import policyConsent from './models/policyConsent';
import user from './models/user';

const native = {
	state: {
		dontRequestPushPermissionUntilDate: null,
		canRequestPushPermission: true,
	},
	reducers: {
		sync(state: any, newStateAsString: string) {
			try {
				const newState = JSON.parse(newStateAsString);
				return newState;
			} catch (e) {
				window.alert('failed to parse:' + newStateAsString);
				return state;
			}
		},
		resetReminderState(state: any) {
			return {
				...state,
				dontRequestPushPermissionUntilDate: null,
			};
		},
	},
};

export interface RootModel extends Models<RootModel> {
	user: typeof user;
	chartData: typeof chartData;
	biometrics: typeof biometrics;
	messaging: typeof messaging;
	policyConsent: typeof policyConsent;
	orderTracking: typeof orderTracking;
	orders: typeof orders;
	biomarkers: typeof biomarkers;
	native: typeof native;
}

export const models = {
	user,
	chartData,
	biometrics,
	messaging,
	policyConsent,
	orderTracking,
	orders,
	biomarkers,
	native,
};
const store = init({ models });
export type Store = typeof store;
export type RootState = RematchRootState<RootModel>;

export default store;
