import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	min-height: 220px;
	background: ${(props) =>
		props.background ? props.background : colors.green};
	flex: 0 0 22%;
	max-width: 22%;
	border-radius: 8px;
	padding: 1px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	transition: transform 0.15s linear;

	&:hover {
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		transform: translateY(-5px);
	}

	@media ${device.desktop}, ${device.laptop} {
		flex: 0 0 24%;
		max-width: 24%;
	}

	@media ${device.laptopL}, ${device.tablet} {
		flex: 0 0 23%;
		max-width: 23%;
	}

	@media ${device.mobileL} {
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		margin-bottom: 28px;
		min-height: 400px;
		max-height: 400px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Row = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	padding: 11px;
`;

export const SeeMore = styled.button`
	cursor: pointer;
	border: none;
	background: transparent;
	color: ${colors.white};
	${fontStyles[fontSizes.fourteen]};
	margin: 4px;
`;

export const Icon = styled.span`
	margin-left: auto;
	border: none;
	min-height: 40px;
	background: transparent;

	&:hover div {
		visibility: visible;
	}

	@media ${device.mobileL} {
		img {
			width: 68px;
			height: 68px;
		}
	}
`;

export const Data = styled.p`
	font-weight: 900;
	line-height: 18px;
	margin-top: 14px;
	color: ${colors.white};
	${fontStyles[fontSizes.sixty]};

	@media ${device.laptopL} {
		${fontStyles[fontSizes.fourty]};
	}

	@media ${device.mobileL} {
		${fontStyles[fontSizes.ninety]};
	}
`;

export const DataType = styled.p`
	margin-top: 32px;
	color: ${colors.white};
	${fontStyles[fontSizes.eighteen]};

	@media ${device.laptop} {
		${fontStyles[fontSizes.sixteen]};
	}

	@media ${device.mobileL} {
		${fontStyles[fontSizes.thirtyFive]};
	}
`;

export const Wrapper = styled.div`
	width: 100%;
	min-height: 75px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 14px;
	padding: 5px 8px;
	border-radius: 8px;
	background: ${colors.white};

	@media ${device.mobileL} {
		min-height: 150px;
	}
`;

export const Buttons = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const Text = styled.p`
	margin-top: 7px;
	font-weight: 500;
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;

	@media ${device.laptop} {
		${fontStyles[fontSizes.sixteen]};
	}

	@media ${device.laptop} {
		margin-top: 10px;
	}

	@media ${device.mobileL} {
		margin-top: 24px;
		${fontStyles[fontSizes.thirtyFive]};
	}
`;
