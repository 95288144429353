import arteries from 'assets/svg/icons/arteries.svg';
import bike from 'assets/svg/icons/bike.svg';
import bloodpressure from 'assets/svg/icons/bloodpressure.svg';
import heart from 'assets/svg/icons/heart.svg';
import heartrate from 'assets/svg/icons/heartrate.svg';
import hips from 'assets/svg/icons/hips.svg';
import mass from 'assets/svg/icons/mass.svg';
import muscle from 'assets/svg/icons/muscle.svg';
import oxygen from 'assets/svg/icons/o2.svg';
import uppertorsofat from 'assets/svg/icons/upper-torso.svg';
import weight from 'assets/svg/icons/weight.svg';
import { chartsKey } from 'utils/constants';
import {
	ARTERIAL_THICKNESS,
	TOTAL_BODY_FAT,
	MUSCLE_MASS,
	FAT_MASS,
	ANDROID_FAT,
	WAIST_TO_HIP_RATIO,
	BLOOD_PRESURE,
	RESTING_HEART_RATE,
	MAX_WATTAGE,
	ANAEROBIC_THRESHOLD,
	PEAK_HEART_RATE,
	VO2_MAX,
	WEIGHT,
} from 'utils/internationalization/en';

export const healthTrackingCards = [
	{
		chartId: chartsKey.vo2Max,
		name: VO2_MAX,
		value: 'N/A',
		type: 'ml/kg/min',
		icon: oxygen,
		mapKey: chartsKey.vo2Max,
		healthIndicator: 'vo2MaxIndicator',
	},
	{
		chartId: chartsKey.peakHeartRate,
		name: PEAK_HEART_RATE,
		value: 'N/A',
		type: 'bpm',
		icon: heart,
		mapKey: chartsKey.peakHeartRate,
		healthIndicator: null,
	},
	{
		chartId: chartsKey.anaerobicThreshold,
		name: ANAEROBIC_THRESHOLD,
		value: 'N/A',
		type: 'bpm',
		icon: heartrate,
		mapKey: chartsKey.anaerobicThreshold,
		healthIndicator: null,
	},
	{
		chartId: chartsKey.maxWattage,
		name: MAX_WATTAGE,
		value: 'N/A',
		type: 'Watts',
		icon: bike,
		mapKey: chartsKey.maxWattage,
		healthIndicator: null,
	},
];

export const healthTrackingHealthCards = [
	{
		chartId: chartsKey.restingHeartRate,
		name: RESTING_HEART_RATE,
		value: 'N/A',
		type: 'bpm',
		icon: heart,
		mapKey: chartsKey.restingHeartRate,
		healthIndicator: 'restingHeartRateIndicator',
	},
	{
		chartId: chartsKey.bloodPressure,
		name: BLOOD_PRESURE,
		value: 'N/A',
		type: 'mmHg',
		icon: bloodpressure,
		mapKey: chartsKey.bloodPressure,
		healthIndicator: 'bloodPressureIndicator',
	},
	{
		chartId: chartsKey.waistHipRatio,
		name: WAIST_TO_HIP_RATIO,
		value: 'N/A',
		type: '',
		icon: hips,
		mapKey: chartsKey.waistHipRatio,
		healthIndicator: 'waistHipRatioIndicator',
	},
	{
		chartId: chartsKey.arterialThickness,
		name: ARTERIAL_THICKNESS,
		value: 'N/A',
		type: 'mm',
		icon: arteries,
		mapKey: chartsKey.arterialThickness,
		healthIndicator: null,
	},
];

export const bodyComposition = [
	{
		chartId: chartsKey.totalBodyFat,
		name: TOTAL_BODY_FAT,
		value: 'N/A',
		type: '%',
		icon: hips,
		mapKey: chartsKey.totalBodyFat,
		healthIndicator: null,
	},
	{
		chartId: chartsKey.muscleMass,
		name: MUSCLE_MASS,
		value: 'N/A',
		type: 'Ibs',
		icon: muscle,
		mapKey: chartsKey.muscleMass,
		healthIndicator: null,
	},
	{
		chartId: chartsKey.fatMass,
		name: FAT_MASS,
		value: 'N/A',
		type: 'lbs',
		icon: mass,
		mapKey: chartsKey.fatMass,
		healthIndicator: null,
	},
	{
		chartId: chartsKey.upperTorsoFat,
		name: ANDROID_FAT,
		value: 'N/A',
		type: '%',
		icon: uppertorsofat,
		mapKey: chartsKey.upperTorsoFat,
		healthIndicator: null,
	},
	{
		chartId: chartsKey.weight,
		name: WEIGHT,
		value: 'N/A',
		type: 'Ibs',
		icon: weight,
		mapKey: chartsKey.weight,
		healthIndicator: null,
	},
];
