// Toast notifications
export const SIGN_IN = 'Log In';
export const SIGN_OUT = 'Logout';
export const ACCEPT_POLICY_REQUIRED = 'To continue, please accept our policy';
export const PASSWORD_SHORT_ERR =
	'Password must contain at least 8 characters, one uppercase, one lowercase, one special character, and one number';
export const REQUIRED_USERNAME_PASSWORD = 'Username and password are required';
export const PASSWORD_NOT_MATCH_ERR = 'Password does not match';
export const PASSWORD_REQUIRED = 'Password is required';
export const USERNAME_REQUIRED = 'Username is required';

export const INVALID_PHONE =
	'Phone number is invalid. Follow the (xxx) xxx-xxxx format';
export const NOT_SURE_WHO_TO_CONTACT = 'Not Sure Who to Contact?';

export const WRONG_EMAIL_FORMAT = 'Email format is not correct';
export const REQUIRED_EMAIL = 'Email is required';

export const SUCCESS_REGISTRATION_AND_LOGIN =
	'You have registered successfully and you are now logged in';
export const REGISTER_LINK_USED = 'Registration Link is already used. ';
export const VALIDATION_ERROR_MSG =
	'There is a problem with your registration. Please contact Cenegenics support at info@cenegencis.com';

export const IMAGE_UPLOAD_SUCCESS = 'Picture uploaded successfully';
export const IMAGE_UPLOAD_FAILED = 'Picture upload failed';
export const IMAGE_REMOVE_FAILED = 'Picture removing failed';
export const IMAGE_REMOVE_SUCCESS = 'Successfully removed picture';

export const SUCCESS_CHANGE_PASSWORD = 'Successfully changed password';
export const SUCCESS_UPDATED_USER = 'Your changes have been saved.';
export const INVALID_CREDENTIALS_ERR = 'Username or password is incorrect';

export const ERROR_GET_USER = 'Failed fetching User data';
export const ERROR_UPDATING_USER = 'Error with Updating user';

// Body composition
export const BODY_COMPOSITION_EMPTY_TEXT =
	'Seems like there are no body composition parameters in your biometrics yet';
export const BODY_COMPOSITION_EMPTY_SUB_TEXT =
	'Please contact Cenegenics to add your biometrics';

// Chart empty state
export const CHART_EMPTY_TEXT =
	'We do not have any biometric data to visualize';
export const CHART_EMPTY_SUB_TEXT =
	'Contact your dedicated Performance Health Coach for any questions.';

// My care team
export const CARE_TEAM_EMPTY_TEXT =
	'Seems like you are missing your dedicated care team members';
export const CARE_TEAM_EMPTY_SUB_TEXT =
	'Please contact Cenegenics to add your care team members';

// Page Main and Sub Titles
// export const ACCOUNT_MAIN_TITLE = 'Account Settings';
// export const ACCOUNT_SUB_TITLE =
// 	'Here you can adjust your personal information and your account settings.';

export const BIOMETRIC_MAIN_TITLE = 'Biometrics';
export const BIOMETRIC_SUB_TITLE = 'Track your biometrics.';
export const BIOMETRIC_SUB_TITLE_MORE =
	'Risk with biometrics is individualized. Contact your physician to learn more.';

export const BIOMARKERS_MAIN_TITLE = 'Biomarkers';
export const BIOMARKERS_SUB_TITLE =
	'Track your health biomarkers through blood and urine biomarkers.';
export const BIOMARKERS_SUB_TITLE_MORE =
	'Risk with biomarkers is individualized. Contact your physician to learn more.';
export const EMPTY_BIOMARKER_MESSAGE = 'If you have gotten lab results, ';
export const EMPTY_BIOMARKER_LINK =
	'contact your dedicated Patient Experience Coordinator.';
export const EMPTY_BIOMARKER_SUB_MESSAGE = '';

export const MY_CARE_TEAM_TITLE = 'My Team';
export const MY_CARE_TEAM_SUB_TITLE =
	'Your Cenegenics Physician, Health Coach and Patient Experience Coordinator.';
export const MY_CARE_TEAM_SUB_TITLE_MOBILE =
	'Your team is committed to helping you achieve your health goals.';

export const MESSAGING_TITLE = 'Messaging';
export const MESSAGING_SUB_TITLE =
	'Message your dedicated performance health team. We do our best to respond within 24 hours or one business day.';

export const TEST_RESULTS_TITLE = 'Reports';
export const TEST_RESULTS_SUBTITLE =
	'To see your results, select the report below. Consult your physician on your results.';

export const PRODUCTS_TITLE = 'My Regimen';
export const PRODUCTS_SUBTITLE =
	'See the products your performance health team recommends for you.';
export const PRODUCTS_SEND_GENERIC_MESSAGE = 'Send a Message';

// Send generic message modal
export const SEND_GENERIC_MESSAGE_TITLE = 'Send a Message';
export const SEND_GENERIC_MESSAGE_SUBTITLE =
	'What do you need assistance with?';
export const SEND_GENERIC_MESSAGE_CENEGENICS_USERNAME = 'Cenegenics';

// SendBird Modal
export const DELETE_MODAL_TITLE = 'Do you want to delete this message?';
export const SENDBIRD_ERROR_MESSAGE = 'Cannot create channel';

// Generic message
export const GENERIC_ERROR_MESSAGE =
	'Something went wrong, please try again later...';

// Profile
export const PROFILE_TITLE = 'My Account';
export const PROFILE_PHOTO_TITLE = 'Profile Photo';

export const NEXT_ORDER_EMPTY_TITLE = `You don't have any upcoming orders. If you have questions, please `;
export const PAST_ORDER_EMPTY_TITLE = `You don't have any past orders. If you have questions, please `;

export const ORDER_EMPTY_SUBTITLE = 'Please contact Cenegenics to add orders';
export const PERSONAL_INFO_TEXT =
	'Changes will not impact how Cenegenics communicates with you';

//Order  Approval
export const ORDER_APPROVAL_SUCCESS_MESSAGE = 'Your order has been approved';

//Order Decline
export const ORDER_DECLINE_MESSAGE = 'Your order has been declined';
export const ORDER_DELIVERY_DATE = 'Delivery Date';
export const ESTIMATED_ORDER_DELIVERY_DATE = 'Est. Delivery Date';

//Order ProductCardMain Component Text
export const ORDER_SHIPPING_TITLE = 'Shipping from';
export const TRACK_YOUR_ORDER = 'Track Your Order';
export const ORDER_APPROVED_BUTTON_TITLE = 'Order Approved';
export const DECLINED_BUTTON_TITLE = 'Decline';
export const REQUEST_A_CHANGE_BUTTON_TITLE = 'Request a Change';
export const APPROVE_BUTTON_TITLE = 'Approve';
export const REQUEST_CHANGE_TITLE = 'What changes do you what to request?';
export const REQUEST_CHANGE_SUBMITTING_TITLE_BUTTON = 'Submitting';
export const REQUEST_CHANGE_SUBMIT_TITLE_BUTTON = 'Submit';
export const REQUEST_CHANGE_CANCEL_TTTLE_BUTTON = 'Cancel';
export const REQUEST_CHANGE_ERROR = 'An error occured';
export const REQUEST_CHANGE_MESSAGE =
	'Need additional support? Contact your dedicated Patient Experience Coordinator by';
export const DOWNLOAD_PDF = 'Download PDF';
export const SHARE_PDF = 'Share this PDF file';
export const DOWNLOAD_FILE = 'Download File';
export const SHARE_FILE = 'Share this File';
export const ORDER_INVOICE = 'Order Invoice';
export const EMPTY_INVOICE = 'There seems to be no PDF uploaded';
export const TEST_RESULTS_FILE_TITLE = 'Test_Results';

export const REQUEST_CHANGE_SUCCESS_TITLE = 'Change requested  submitted';
export const REQUEST_CHANGE_SUCCESS_MESSAGE_MODAL =
	'Your Patient Experience Coordinator will review the request and contact you with any follow up questions or clarifications';
export const NO_ORDER_MESSAGE =
	'You don’t have any pending orders. If you have questions, please ';
export const NO_ORDER_MESSAGE_LINK1 = 'message ';
export const NO_ORDER_MESSAGE_LINK2 = 'call ';
export const NO_ORDER_MESSAGE2 =
	'your dedicated Patient Experience Coordinator.';

//error messages
export const ERROR_FETCHING_TEST_RESULT =
	'Seems like there was a problem fetching the test results.';
export const ERROR_SUBTITLE_TEST_RESULT = '';
export const ERROR_TEST_RESULT_ISEMPTY = 'No reports are available';
export const SUBTITLE_CONTACT_CENEGENICS_TEAM_FOR_TESTRESULTS =
	'If you believe you should have test results, contact your dedicated Patient Experience Coordinator.';

export const ERROR_MYPRODUCTS_ISEMPTY =
	'Seems like there are no products in your list yet';
export const SUBTITLE_CONTACT_CENEGENICS_TEAM =
	'Please contact Cenegenics to add products';
export const ERROR_FETCHING_MYPRODUCTS =
	'Seems like there was a problem fetching the test results.';

export const NETWORK_ERROR_SUBTITLE = 'Please check your internet connection.';

export const PDF_TEST_RESULT_DESCRIPTION =
	'To see your test results please click on the cards below and it will open a PDF which you can download';

export const ORDER_APPROVAL_SUCCESS_TITLE = 'Success';
export const ORDER_APPROVAL_SUCCESS_MESSAGE_MODAL =
	'Your order has been approved.';
export const ORDER_APPROVAL_SUCCESS_MESSAGE_MODAL_SUBTITLE =
	'You can find it in the Next Orders tab.';
//Health Tracking Titles
export const VO2_MAX = 'VO2max';
export const PEAK_HEART_RATE = 'Peak Heart Rate';
export const ANAEROBIC_THRESHOLD = 'Anaerobic Threshold';
export const MAX_WATTAGE = 'Max Wattage';

export const RESTING_HEART_RATE = 'Resting Heart Rate';
export const BLOOD_PRESURE = 'Blood Pressure';
export const WAIST_TO_HIP_RATIO = 'Waist to Hip Ratio';
export const ARTERIAL_THICKNESS = 'Arterial Thickness';

export const TOTAL_BODY_FAT = 'Body Fat Percentage';
export const MUSCLE_MASS = 'Lean Body Mass';
export const FAT_MASS = 'Fat Mass';
export const ANDROID_FAT = 'Android & Visceral Fat';
export const WEIGHT = 'Weight';

export const FITNESS_TITLE = 'Fitness';
export const HEALTH_TITLE = 'Health';
export const BODY_COMPOSITY = 'Body Composition';

export const FITNESS_TAB = 'fitness';
export const HEALTH_TAB = 'Health';
export const BODY_COMPOSITION_TAB = 'body_composition';

export const CHANGE_REQUESTED = 'Change Requested';

export const PATIENT_EXPERIENCE_COORDINATOR = 'Patient Experience Coordinator';
export const PROGRAM_SUPPORT = 'Program support';
export const MY_HEALTH = 'My health';
export const NUTRITION_FITNESS_LIFESTYLE = 'Nutrition, Fitness or Lifestyle';
export const LAB_RESULTS_PRESCRIPTION_CLINICAL_CARE =
	'Lab results, Prescriptions or Clinical care';
export const HEALTH_COACH = 'Health Coach';
export const PHYSICIAN = 'Physician';

export const IMAGE_ALT_TEXT = 'Empty state placeholder';
export const ABOUT = 'About ';
export const HEALTH_IMPACT = 'Health Impact';
export const TAKING_ACTION = 'Taking Action';
export const LIFESTYLE = 'Lifestyle';
export const FUEL = 'Fuel';
export const PEAK_FITNESS = 'Peak Fitness';

export const NOTIFICATION_FOR_ORDERS_HEADER = 'Track your orders';
export const NOTIFICATION_FOR_ORDERS_BODY =
	'By turning on notifications, Cenegenics can notify you when your order has shipped or arrived.';
export const NOT_NOW = 'Not Now';
export const TURN_ON_NOTIFICATION = 'Turn on Notifications';
export const BIOMARKER_RANGE_MODAL_TITLE =
	'What are optimal and standard reference ranges?';

export const NOTIFICATION_FOR_REGIMEN_HEADER = 'Track your orders';
export const NOTIFICATION_FOR_REGIMEN_BODY =
	'Turn on notifications to know when your order ships and arrives.';

export const NOTIFICATION_FOR_MY_CARE_TEAM_HEADER =
	'Find out when you have messages';
export const NOTIFICATION_FOR_MY_CARE_TEAM_BODY =
	"Don't miss a message! By turning on notifications, Cenegenics can notify you when you have a new or unread message from your Performance Health Team.";
export const TIPSY_MODAL_TITLE =
	'What are Optimal range and Standard laboratory reference range?';

export const ORDER_CHANGE_REVIEW_MESSAGE =
	'To make additional changes to your order, please';
export const ORDER_CHANGE_REVIEW_LINK_LABEL = ' call ';
export const ORDER_CHANGE_REVIEW_LINK_LABEL2 = ' message ';
export const ORDER_CHANGE_REVIEW_MESSAGE2 =
	'your dedicated Patient Experience Coordinator.';

export const CHEM_SCREEN = 'Chem-Screen';
export const CHOLESTROL_PANEL = 'Cholesterol Panel';
export const CARDIO_IQ = 'Cardio IQ';
export const LIPOPROTEIN_FRACTIONATION = 'Lipoprotein Fractionation';
export const INFLAMMATION_AND_ENDOTHELIAL_HEALTH =
	'Inflammation and Endothelial Health';
export const OMEGACHECK = 'OmegaCheck';
export const CBC_DIFFERENTIAL_PLATELET = 'CBC w/ Differential & Platelet';
export const CBC_OTHER = 'CBC (Other)';
export const HORMONE_PANEL = 'Hormone Panel';
export const THYROID_PANEL = 'Thyroid Panel';
export const URINALYSIS = 'Urinalysis';
export const INFECTIOUS_DISEASE = 'Infectious Disease';
export const OTHER = 'Other';
export const PATIENT_DEMOGRAPHICS = 'Patient Demographics';
export const NEXT_ORDERS = 'Next Orders';

export const MIN = 'MIN';
export const MAX = 'MAX';

export const FILE_ERROR =
	'There was an error displaying the content of this file.';
export const ERROR_FETCHING_BIOMARKER_EDUCATIONAL_CONTENT =
	'There was an error fetching Biomarker Educational Content';
export const PENDING_CONFIRMATION = 'Pending Confirmation';
export const PENDING_FULLFILMENT = 'Pending Fulfillment';
export const IN_FULFILLMENT = 'In Fulfillment';
export const PARTIALLY_SHIPPED = 'Partially Shipped';
export const PARTIALLY_RETURNED = 'Partially Returned';
export const RETURNED = 'Returned';
export const SHIPPED = 'Shipped';
export const CANCEL_REQUEST = 'Cancel Requested';
export const PARTIALLY_CANCELLED = 'Partially Cancelled';
export const CANCELLED = 'Cancelled';
export const DELIVERED = 'Delivered';
export const MY_ORDERS_TITLE = 'My Orders';
export const MY_ORERS_SUB_TITLE =
	'Track your orders and then see products your Performance Health Team recommends.';
export const BIOMARKERS_LABEL = 'Biomarkers';
export const EMPTY_CHART = 'Empty Chart';
export const NO_DATA_PLOT = 'No Data to plot graph';
export const INFORMATION = 'Information';
export const ALL = 'All';
export const LAST_2_YEARS = 'Last 2 years';
export const LAST_3_YEARS = 'Last 3 years';
export const REGISTRATION_SUCCESS_MESSAGE_OTHER = `You have successfully setup your Cenegenics app account! 🎉\nChoose an option below for instructions on how to use the app on your platform of choice:`;
export const REGISTRATION_SUCCESS_MESSAGE_ANDROID =
	'Please proceed to the Google Play Store to download the app.';
export const REGISTRATION_SUCCESS_MESSAGE_IOS =
	'Please proceed to the Apple App Store to download the app.';

export const REGISTRATION_SUCCESS_CTA_ANDROID = 'Download on Google Play';
export const REGISTRATION_SUCCESS_CTA_IOS = 'Download on App Store';
export const REGISTRATION_SUCCESS_IOS_STEP_1 = `1. Open the App Store app on your iPhone`;
export const REGISTRATION_SUCCESS_IOS_STEP_2 = `2. Search for "Cenegenics"`;
export const REGISTRATION_SUCCESS_IOS_STEP_3 = `3. Click the install button`;
export const REGISTRATION_SUCCESS_ANDROID_STEP_1 = ` to access the Google Play Store`;
export const REGISTRATION_SUCCESS_ANDROID_STEP_2 = `2. Click "Install" and follow the on-screen instructions to install the Cenegenics app on your Android phone`;
export const REGISTRATION_SUCCESS_BROWSER_STEP_1a = `Just log in to the `;
export const REGISTRATION_SUCCESS_BROWSER_STEP_1b = `Patient Portal`;
export const REGISTRATION_SUCCESS_BROWSER_STEP_1c = ` to use the web-based version of the app.`;
export const REGISTRATION_SUCCESS_BROWSER_STEP_2 = `For the full digital experience with blood biomarker and biometric data, download the Cenegenics app on your mobile phone.`;
export const REGISTRATION_SUCCESS_TROUBLESHOOT = `Please contact app@cenegenics.com if you have any problems.`;
export const REGISTRATION_SUCCESS_TAB_IOS = 'How to install for iPhone';
export const REGISTRATION_SUCCESS_TAB_ANDROID = 'How to install for Android';
export const REGISTRATION_SUCCESS_TAB_BROWSER = 'Access on a web browser';
export const CLICK_HERE = 'Click here';

export enum deviceType {
	iOS = 'android',
	Android = 'ios',
}

export const PRODUCT_COMPONENT_NAME = 'ProductComponent';
export const TOAST_GENERIC_ERROR_TITLE = 'Something went wrong';
export const TOAST_UNAUTHORIZED_ERROR_TEXT =
	'We apologize you aren’t still signed in. Please log in again to continue using the app or contact app@cenegenics.com if the problem persists.';
export const TOAST_TOKEN_EXPIRED_ERROR_TEXT =
	'Your session has expired. We are working to fix this and keep you signed in. Please log in again to continue with the app.';

export const MY_REGIMEN_TITLE = 'Regimen';
export const MY_REGIMENT_SUBTITLE =
	'See the prescriptions and nutraceuticals your performance health team recommends, the dosage, directions, and refill dates.';
