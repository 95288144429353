import { useContext } from 'react';

import { TestResultsContext } from './TestResultsContext';

export const useTestResultsContext = () => {
	const context = useContext(TestResultsContext);

	if (!context) {
		throw new Error(
			'useTestResultsContext must be used inside TestResultsContext'
		);
	}

	return context;
};
