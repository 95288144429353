import styled from 'styled-components/macro';

export const Image = styled.img`
	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	animation-name: rotate;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
`;

export const UnOrderedList = styled.ul`
	display: flex;
`;

export const List = styled.li`
	list-style: none;
	width: 10px;
	height: 40px;
	background: linear-gradient(114.52deg, #4061a3 22.76%, #5a98d1 96.44%);
	margin: 0 4px;
	animation: animate 0.7s infinite alternate;

	@keyframes animate {
		0% {
			transform: scaleY(1);
		}
		25% {
			transform: scaleY(1);
		}
		50% {
			transform: scaleY(1);
		}
		75% {
			transform: scaleY(1);
		}
		100% {
			transform: scaleY(3);
		}
	}
	&:nth-child(1) {
		animation-delay: 0.1s;
	}

	&:nth-child(2) {
		animation-delay: 0.2s;
	}

	&:nth-child(3) {
		animation-delay: 0.3s;
	}

	&:nth-child(4) {
		animation-delay: 0.4s;
	}

	&:nth-child(5) {
		animation-delay: 0.5s;
	}
`;
