// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

import { setTabFontSize, setTabBgClip, setTabBgColor } from './Tabs.helper';

interface TabProps {
	isActive?: boolean;
	isOrderTracking?: boolean;
	isNotSpacedEvenly?: boolean;
	hasFlexStart?: boolean;
}

export const Tabs = styled.ul<TabProps>`
	display: flex;
	flex-shrink: 0;
	justify-content: ${({ isNotSpacedEvenly }) =>
		isNotSpacedEvenly ? 'center' : 'start'};
	@media ${device.mobileL} {
		width: 100%;
		overflow: ${({ isOrderTracking }) => (isOrderTracking ? '' : 'auto')};
		height: 40px;
		justify-content: ${({ hasFlexStart }) =>
			hasFlexStart ? 'flex-start' : 'center'};
		::-webkit-scrollbar {
			display: none;
		}
	}
`;

export const Span = styled.span`
	display: flex;
	font-weight: 800;
	-webkit-background-clip: text;
	background-clip: text;
	background-image: ${colors.gradientBlue};
	white-space: nowrap;
	color: ${colors.blue};
`;

export const Tab = styled.li<TabProps>`
	@media ${device.mobileL} {
		align-items: center;
		line-height: 40px;
	}
	${({ isOrderTracking }) => setTabFontSize(isOrderTracking)};
	background-color: ${({ isActive, isOrderTracking }) =>
		setTabBgColor(isActive, isOrderTracking)};
	background-clip: ${({ isActive, isOrderTracking }) =>
		setTabBgClip(isActive, isOrderTracking)};
	border-bottom: ${({ isActive, isOrderTracking }) =>
		isActive && !isOrderTracking ? `3px solid ${colors.textBlue}` : 'unset'};
	box-shadow: ${({ isOrderTracking }) =>
		!isOrderTracking && `2px 1000px 1px ${colors.offWhite} inset`};
	display: flex;
	cursor: pointer;
	outline: none;
	margin-right: ${({ isOrderTracking }) => !isOrderTracking && '25px'};
	align-items: baseline;

	${({ isOrderTracking }) =>
		isOrderTracking &&
		css`
			width: 100%;
			align-items: center;
			justify-content: center;
			&:first-child {
				border-top: 1px solid ${colors.softBlue};
				border-left: 1px solid ${colors.softBlue};
				border-bottom: 1px solid ${colors.softBlue};
				border-radius: 4px 0 0 4px;
			}

			&:nth-child(2) {
				border: 1px solid ${colors.softBlue};
			}

			&:last-child {
				border-top: 1px solid ${colors.softBlue};
				border-right: 1px solid ${colors.softBlue};
				border-bottom: 1px solid ${colors.softBlue};
				border-radius: 0 4px 4px 0;
				align-items: center;
			}

		}
		@media ${device.mobileXL} {
			text-align: center;
		}
		@media ${device.tablet} {
			text-align: center;
		}
		@media ${device.mobileL} {
			text-align: center;
		}

			@media ${device.mobileL} {
				text-align: center;
			}
		`};

	${Span} {
		${({ isActive }) =>
			!isActive &&
			css`
				font-weight: unset;
				-webkit-background-clip: unset;
				-webkit-text-fill-color: ${colors.black};
				border-image-source: unset;
				background-image: unset;
			`}
		${({ isOrderTracking }) =>
			isOrderTracking &&
			css`
				color: ${colors.navy};
				padding: 7px 35px;
				@media ${device.mobileL} {
					padding: 15px 20px;
				}
			`}
		${({ isOrderTracking, isActive }) =>
			isOrderTracking &&
			isActive &&
			css`
				color: ${colors.blue3};
				background-image: unset;
				-webkit-text-fill-color: unset;
			`}
	}
`;
