import React from 'react';

import brokenHeart from 'assets/svg/illustrations/broken_2.svg';

import * as Styled from './ErrorFallback.styled';

export function ErrorFallback() {
	return (
		<Styled.Container>
			<img
				src={brokenHeart}
				alt='Bandaged heart'
				width='200px'
				height='100px'
			/>
			<Styled.Text>
				It is not you, it is us and we are sorry...
				<br />{' '}
				{`Please try again later later if you can't access this page now.`}
				<br /> We are working really hard to address the issue.
			</Styled.Text>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<Styled.TryAgainButton onClick={() => window.location.reload()}>
					Try again
				</Styled.TryAgainButton>
			</div>

			<Styled.Email>You can contact us at app@cenegenics.com</Styled.Email>
		</Styled.Container>
	);
}
