import React from 'react';

import * as Styled from './Button.styled';

const Button = ({
	children,
	disabled,
	icon,
	id,
	margin,
	onClick,
	onSubmit,
	rounded,
	small,
	tabIndex = 0,
	type = 'button',
	variant,
	...rest
}: any) => {
	return (
		<Styled.Container
			disabled={disabled}
			icon={icon}
			id={id}
			margin={margin}
			onClick={!disabled ? onClick : undefined}
			onSubmit={onSubmit}
			rounded={rounded}
			small={small}
			tabIndex={tabIndex}
			type={type}
			variant={variant}
			{...rest}
		>
			{icon && <Styled.Icon src={icon} alt='Button icon' small={small} />}
			{children && <Styled.Label variant={variant}>{children}</Styled.Label>}
		</Styled.Container>
	);
};

export default Button;
