import * as Sentry from '@sentry/react';
import { format as fnsFormat, parse } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { get } from 'lodash';
import Resizer from 'react-image-file-resizer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GppIcon from 'assets/svg/icons/fileIcon/3gpp.svg';
import BmpIcon from 'assets/svg/icons/fileIcon/bmp.svg';
import DocIcon from 'assets/svg/icons/fileIcon/doc.svg';
import DocxIcon from 'assets/svg/icons/fileIcon/docx.svg';
import GifIcon from 'assets/svg/icons/fileIcon/gif.svg';
import HtmlIcon from 'assets/svg/icons/fileIcon/html.svg';
import JpegIcon from 'assets/svg/icons/fileIcon/jpeg.svg';
import M4aIcon from 'assets/svg/icons/fileIcon/m4a.svg';
import MovIcon from 'assets/svg/icons/fileIcon/mov.svg';
import Mp4Icon from 'assets/svg/icons/fileIcon/mp4.svg';
import MpegIcon from 'assets/svg/icons/fileIcon/mpeg.svg';
import OctetIcon from 'assets/svg/icons/fileIcon/octet.svg';
import PdfIcon from 'assets/svg/icons/fileIcon/pdf.svg';
import PngIcon from 'assets/svg/icons/fileIcon/png.svg';
import PptIcon from 'assets/svg/icons/fileIcon/ppt.svg';
import PptxIcon from 'assets/svg/icons/fileIcon/pptx.svg';
import TiffIcon from 'assets/svg/icons/fileIcon/tiff.svg';
import TxtIcon from 'assets/svg/icons/fileIcon/txt.svg';
import VcfIcon from 'assets/svg/icons/fileIcon/vcf.svg';
import VectorIcon from 'assets/svg/icons/fileIcon/Vector.svg';
import WavIcon from 'assets/svg/icons/fileIcon/wav.svg';
import WebpIcon from 'assets/svg/icons/fileIcon/webp.svg';
import XzipIcon from 'assets/svg/icons/fileIcon/x-zip.svg';
import XlsmIcon from 'assets/svg/icons/fileIcon/xlsm.svg';
import XlsxIcon from 'assets/svg/icons/fileIcon/xlsx.svg';
import ZipIcon from 'assets/svg/icons/fileIcon/zip.svg';
import ZplIcon from 'assets/svg/icons/fileIcon/zpl.svg';

import { EMAIL_RGX, PASSWORD_RGX, PHONE_RGX, BOTTOM_LEFT } from './constants';
import { GENERIC_ERROR_MESSAGE } from './internationalization/en';

type ToastPosition =
	| 'top-right'
	| 'top-center'
	| 'top-left'
	| 'bottom-right'
	| 'bottom-center'
	| 'bottom-left';

export const renderSimpleAvatarImages =
	`${process.env.REACT_APP_SIMPLE_AVATARS}` === 'true';

export const USE_SIMPLE_VALIDATION_ERRORS =
	`${process.env.REACT_APP_SIMPLE_VALIDATION_ERRORS}` === 'true';

export const showSuccessNotify = (msg: string, position: ToastPosition) =>
	toast.success(msg, { position, hideProgressBar: true });

export const showWarningNotify = (msg: string, position: ToastPosition) =>
	toast.warn(msg, { position, hideProgressBar: true });

export const showErrorNotify = (
	msg: string,
	position: ToastPosition,
	toastId: string | undefined
) => toast.error(msg, { position, hideProgressBar: true, toastId });

export const checkPasswordStrength = (pass: string) => PASSWORD_RGX.test(pass);

export const checkPasswordMatch = (firstPass: string, secPass: string) =>
	firstPass === secPass;

export const checkPhoneValidity = (phone: string) => PHONE_RGX.test(phone);

export const isEmailFormat = (email: string) => EMAIL_RGX.test(email);

export const removeToken = () => sessionStorage.removeItem('token');

export const handleApiErrors = (err: any, verr: any, serr: any, gerr: any) => {
	const payload = err?.data;
	switch (payload?.code) {
		case 'validation':
			if (!USE_SIMPLE_VALIDATION_ERRORS) {
				payload?.data?.forEach((v: any) => {
					v?.loc?.forEach((l: any) => verr(l, v?.msg));
				});
				return;
			}
			gerr(err);
			break;
		default:
			if (payload && payload?.message) serr(payload?.message);
			else gerr(err);
	}
};

export const getToken = () => sessionStorage.getItem('token');

export const resizeFile = (file: Blob, maxWidth = 400, maxHeight = 400) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			maxWidth,
			maxHeight,
			'JPEG',
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			'base64'
		);
	});

export const formatDate = (dateStr?: string) => {
	try {
		const day = dateStr?.split('-')[2].replace(/^0+/, '');
		const month = dateStr?.split('-')[1].replace(/^0+/, '');
		const year = dateStr?.split('-')[0];
		const result = `${month}/${day}/${year}`;
		return result || '';
	} catch (err) {
		Sentry.captureException(err);
	}
	return '';
};

export const formatDateDespiteTimeZone = (
	dateParams: string,
	dateFormat: string
) => {
	//method expect this date format 2021-12-03T00:45:00.000+0000
	const date = new Date(dateParams);
	const timeZone = 'UTC';
	const zonedDate = utcToZonedTime(date, timeZone);
	const pattern = dateFormat;
	return format(zonedDate, pattern, { timeZone });
};

// date-fns fix for Safari browser
// https://github.com/date-fns/date-fns/issues/2130
export const dateFormat = (date: string) => {
	return fnsFormat(parse('', '', new Date(date)), 'MMM d, yyyy');
};

export const getWholeNumber = (num: number) => Math.floor(num);

export const removeStorageItem = (storageName: string) => {
	if (localStorage.getItem(storageName)) {
		localStorage.removeItem(storageName);
	} else {
		showErrorNotify(GENERIC_ERROR_MESSAGE, BOTTOM_LEFT, '');
	}
};

export function toAgeGroup(age: number) {
	if (age < 25) {
		return '18-24';
	} else if (age < 35) {
		return '25-34';
	} else if (age < 45) {
		return '35-44';
	} else if (age < 55) {
		return '45-54';
	} else if (age < 65) {
		return '55-64';
	} else {
		return '65+';
	}
}

export const getFileSVG = {
	png: PngIcon,
	zip: ZipIcon,
	docx: DocxIcon,
	doc: DocIcon,
	jpeg: JpegIcon,
	xlsx: XlsxIcon,
	mp4: Mp4Icon,
	'3gpp': GppIcon,
	bmp: BmpIcon,
	gif: GifIcon,
	html: HtmlIcon,
	m4a: M4aIcon,
	mov: MovIcon,
	mpeg: MpegIcon,
	octet: OctetIcon,
	ppt: PptIcon,
	pptx: PptxIcon,
	tiff: TiffIcon,
	txt: TxtIcon,
	vcf: VcfIcon,
	vector: VectorIcon,
	wav: WavIcon,
	webp: WebpIcon,
	'x-zip': XzipIcon,
	xlsm: XlsmIcon,
	zpl: ZplIcon,
	pdf: PdfIcon,
	'vnd.ms-excel.sheet.macroenabled.12': XlsmIcon,
	'vnd.openxmlformats-officedocument.wordprocessingml.document': DocxIcon,
	'vnd.openxmlformats-officedocument.spreadsheetml.sheet': XlsxIcon,
	msword: DocIcon,
	plain: TxtIcon,
};

const firstIndex = 0;
const secondIndex = 1;

export const iconType = (fileType: string) => {
	const icon =
		fileType &&
		Object.entries(getFileSVG).find((x) => x[firstIndex] === fileType);
	return (icon && icon[secondIndex]) || getFileSVG.pdf;
};

export const fileExtensions = {
	png: '.png',
	zip: '.zip',
	docx: '.docx',
	doc: '.doc',
	jpeg: '.jpeg',
	xlsx: '.xlsx',
	mp4: '.mp4',
	'3gpp': '.3gpp',
	bmp: '.bmp',
	gif: '.gif',
	html: '.html',
	m4a: '.m4a',
	mov: '.mov',
	mpeg: '.mpeg',
	octet: '.octet',
	ppt: '.ppt',
	pptx: '.pptx',
	tiff: '.tiff',
	txt: '.txt',
	vcf: '.vcf',
	wav: '.vaw',
	webp: '.webp',
	'x-zip': '.zip',
	xlsm: '.xlsm',
	zpl: '.zpl',
	pdf: '.pdf',
	'vnd.ms-excel.sheet.macroenabled.12': '.xlsm',
	'vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
	'vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
	msword: '.doc',
	plain: '.txt',
};

export const getFileExtension = (fileType: string) => {
	return get(fileExtensions, fileType, '');
};

export default function versionEqualOrLater(
	str: string,
	major: number,
	minor: number,
	build: number
) {
	if (!str) return false;
	try {
		const v = str

			.split('.')

			.reduce((acc: number[], val: string, index: number) => {
				acc.push(parseInt(val));

				return acc;
			}, []);

		if (v[0] > major) return true;

		if (major === v[0] && v[1] > minor) return true;

		if (major === v[0] && minor === v[1] && v[2] >= build) return true;

		return false;
	} catch (err) {
		return false;
	}
}
export const cleanUpFileName = (text: string) => {
	return text?.replace(/\s/g, '_');
};

export function parseTokenPayload(token: string) {
	try {
		const result = JSON.parse(window.atob(token.split('.')[1]));
		const iat = new Date(parseInt(result.iat) * 1000).toISOString();
		const exp = new Date(parseInt(result.exp) * 1000).toISOString();
		const scope = result.scope;
		const curTime = new Date();
		return { iat, exp, curTime, scope };
	} catch (error) {
		return { iat: '', exp: '', curTime: '', scope: '' };
	}
}

const messagingExcludeCenters = ['denver'];

export function isMessagingExcludeCenter(userCenter: string) {
	let isExcludeCenter = false;

	messagingExcludeCenters.forEach((str) => {
		if ((userCenter || '').toLowerCase().includes(str)) {
			isExcludeCenter = true;
		}
	});
	return isExcludeCenter;
}
