import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	justify-content: center;
	border-radius: 10px;
	background: ${colors.white};
	border: 1px solid ${colors.grey30};
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);

	#avatarBorder {
		width: 90px;
		height: 90px;
		margin-bottom: 8px;

		@media ${device.laptopL} {
			height: 77px;
			width: 77px;
		}
	}

	#uploadIcon {
		@media ${device.laptopL} {
			top: 44px;
			right: 26px;
		}
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	// TODO: After CEN-579, refactor this
	@media screen and (max-width: 767px) {
		margin-top: 10px;
	}
`;

export const Text = styled.p`
	font-weight: 500;
	color: ${colors.navy};
	min-height: 24px;
	${fontStyles[fontSizes.eighteen]};
`;

export const Row = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: stretch;
	margin-top: 28px;

	// TODO: After CEN-579, refactor this
	@media screen and (max-width: 767px) {
		margin-bottom: 10px;
	}
`;

export const Param = styled(Column)`
	justify-content: flex-start;
	flex: 0 0 33%;
	max-width: 33%;
	min-height: 75px;
	border-right: 1px solid ${colors.lightGrey};
	padding-top: 15px;

	&:last-child {
		border: none;
	}

	button {
		margin-top: 10px;
		@media ${device.laptopL} {
			padding: 5px;
		}
		@media ${device.mobileL} {
			padding: 5px 10px;
		}
	}
`;

export const Value = styled.p`
	min-height: 24px;
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};
`;

export const Name = styled.p`
	margin-top: 8px;
	min-height: 24px;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.fourteen]};
`;
