import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	padding: 28px;
	display: flex;
	flex-direction: column;
	flex: 1;
`;
export const ModalContainer = styled(Container)`
	padding: 10px;
`;

export const SubHeading = styled.div`
	margin-bottom: 12px;
`;

export const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const EmptyDiv = styled.div`
	height: 50%;
`;

export const EmptyContainer = styled.div`
	margin-top: 30px;
`;

export const BiomarkerChartContainer = styled.div`
	background-color: ${colors.white};
	border-radius: 5px;
	box-shadow: 0 0 2px #ccc;
	width: 100%;
	padding: 20px;
`;

export const BiomarkerChartLoaderContainer = styled.div`
	border-radius: 5px;
`;

export const SelectDate = styled.select`
	border: 1px solid #cccccc;
	border-radius: 4px;
`;

export const DateContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 10%;
`;

export const EmtyStateContainer = styled.div`
	margin-top: 21px;
`;

export const ImgWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 164px;
	height: 108px;
	border-radius: 13px;
	background: ${colors.softBlue};
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	width: 360px;
	margin-top: 14px;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const Text = styled.p`
	font-weight: 500;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.eighteen]};
`;

export const Anchor = styled(Link)`
	color: ${colors.blue3};
	text-decoration: none;
`;

export const ResultType = styled.div`
	color: ${colors.chartBlue};
	background-color: ${colors.softBlue};
	padding: 5px;
	border-radius: 5px;
	font-weight: 900;
`;

export const ResultTypeContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`;

export const Paragraph = styled.div`
	font-size: 20px;
	font-weight: 700;
	padding: 3px 0 10px 0;
	text-align: center;
`;

export const Message = styled.div`
	color: ${colors.grey65};
	font-size: 20px;
`;

export const InfoIcon = styled.img`
	width: 30px;
	height: 30px;
	padding-left: 5px;
`;
