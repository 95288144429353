import React, { useEffect, useMemo } from 'react';

import { orderBy, map, isEmpty, has, reduce } from 'lodash';

import {
	GetTestResultsResponseResponse,
	useGetTestResults,
} from 'api/services';
import { ReactComponent as NetworkError } from 'assets/svg/illustrations/networkerror.svg';
import Error from 'components/Error/Error';
import PageContainer from 'components/PageContainer/PageContainer';
import TestResultsLoader from 'components/Skeleton/TestResultsLoader/TestResultsLoader';
import {
	TestResultArrayItem,
	TestResultItem,
} from 'components/TestResultsBlock/TestResultsBlock.types';
import Titles from 'components/Titles/Titles';
import useAnalytics from 'hooks/useAnalytics';
import useUniversalContext from 'hooks/useUniversalContext';
import versionEqualOrLater from 'utils/helpers';
import {
	TEST_RESULTS_TITLE,
	TEST_RESULTS_SUBTITLE,
	ERROR_FETCHING_TEST_RESULT,
	NETWORK_ERROR_SUBTITLE,
} from 'utils/internationalization/en';

import { TestResultsFilterProvider } from '../../components/TestFilters/context/TestResultsProvider';
import TestFilters from '../../components/TestFilters/TestFilters';
import { TestResultsBlock } from '../../components/TestResultsBlock/TestResultsBlock';

const TestResults = ({
	hasBottomNavigation,
}: {
	hasBottomNavigation?: boolean;
}) => {
	let { data, loading, error } = useGetTestResults({});

	if (typeof data === 'object' && !has(data, '0.category')) {
		// @ts-ignore
		const flattenData = !isEmpty(data)
			? reduce(
					data,
					(total: any, categoryCollection: any) => {
						return [...total, ...categoryCollection];
					},
					[]
			  )
			: [];
		const output = map(flattenData as TestResultItem[], (dataItem) => {
			return {
				category: dataItem.Category,
				date: dataItem.testDate,
				description: dataItem.description,
				document_id: dataItem.testId,
				filetype: dataItem.fileType,
				title: dataItem.name,
				document_name: dataItem.name || 'test-result' + dataItem.testId,
				healthStoreCategories: dataItem.healthStoreCategories,
			};
		});
		data = output as GetTestResultsResponseResponse;
	}

	//@ts-ignore
	// eslint-disable-next-line
	let resultData: TestResultArrayItem[] = data || [];

	const { logScreenView } = useAnalytics();
	const { isBrowser } = useUniversalContext();
	useEffect(() => {
		logScreenView?.();
	}, [logScreenView]);

	if (
		Array.isArray(data) &&
		!isBrowser &&
		// @ts-ignore
		!versionEqualOrLater(window?.nativeContext?.appVersion, 1, 1, 14)
	) {
		resultData = data.filter(
			(result) => result.filetype.toLowerCase() === 'pdf'
		);
	}

	const testCollection = useMemo(
		() =>
			orderBy(
				resultData,
				(item) => Date.parse((item as unknown as TestResultArrayItem).date),
				'desc'
			),
		[resultData]
	);

	if (error) {
		return (
			<Error
				Image={NetworkError}
				message={ERROR_FETCHING_TEST_RESULT}
				subTitleMessage={NETWORK_ERROR_SUBTITLE}
			/>
		);
	}

	return (
		<PageContainer hasBottomNavigation={hasBottomNavigation}>
			{loading ? (
				<TestResultsLoader />
			) : (
				<TestResultsFilterProvider>
					<Titles
						isMyHealthSection={true}
						mainTitle={TEST_RESULTS_TITLE}
						subTitle={TEST_RESULTS_SUBTITLE}
					/>
					<TestFilters />
					<TestResultsBlock testCollection={testCollection} />
				</TestResultsFilterProvider>
			)}
		</PageContainer>
	);
};

export default TestResults;
