import React from 'react';

import { useHistory } from 'react-router-dom';

import FourOhFour from 'assets/svg/illustrations/four-oh-four.svg';
import Button from 'components/Button/Button';

import * as Styled from './NotFound.styled';

const NotFound = () => {
	const history = useHistory();

	return (
		<Styled.Container>
			<Styled.Image src={FourOhFour} />
			<Styled.Heading>
				We’ve listened and... we can’t find the page you’re looking for
			</Styled.Heading>
			<Button variant='primary' onClick={() => history.goBack()}>
				Go Back
			</Button>
			<Styled.Contact>Or contact Cenegenics team</Styled.Contact>
		</Styled.Container>
	);
};

export default NotFound;
