import React from 'react';

import backIcon from 'assets/svg/icons/arrow-left.svg';

import * as Styled from './NavigateBackButton.styled';

export interface NavigateBackButtonProps {
	label: string;
	callback?: () => void;
}

export default function NavigateBackButton({
	label,
	callback,
}: NavigateBackButtonProps) {
	return (
		<Styled.BackButtonContainer>
			<Styled.BackButtonAnchorTag
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					e.preventDefault();
					callback && callback();
				}}
			>
				<Styled.BackIcon src={backIcon} alt='back icon' />
				<Styled.BackButtonText>{label}</Styled.BackButtonText>
			</Styled.BackButtonAnchorTag>
		</Styled.BackButtonContainer>
	);
}
