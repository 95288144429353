import React from 'react';

import imgLogo from 'assets/img/cen_logo1_new.png';
import logo from 'assets/svg/illustrations/logo.svg';
import Image from 'components/Image/Image';

const Logo = ({ svg = false, ...rest }) => (
	<Image img={svg ? logo : imgLogo} alt='Cenegenics Logo' {...rest} />
);

export default Logo;
