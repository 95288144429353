import React, { useEffect, useState, useRef } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { withSendBird } from 'sendbird-uikit';

import burger from 'assets/svg/icons/burger-menu.svg';
import Footer from 'components/Footer/Footer';
import Logo from 'components/Logo/Logo';
import Routes from 'components/Routes/Routes';
import useChannelManager from 'hooks/useChannelManager';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import useUniversalContext from 'hooks/useUniversalContext';
import { SIGN_OUT } from 'utils/internationalization/en';

import { Routes as routesList } from '../../router/routes';
import { colors } from '../../styles/colors';
import Avatar from '../UserImage/UserImage';

import Caret from './Caret/Caret';
import LogoutDropdown from './LogoutDropdown/LogoutDropdown';
import MessagingBtn from './MessagingBtn';

import * as Styled from './Header.styled';

interface HeaderProps {
	isResponsive: boolean;
	showMessaging: boolean;
}

const Header = ({ isResponsive, showMessaging }: HeaderProps) => {
	useChannelManager();
	const menuRef = useRef() as React.RefObject<HTMLDivElement>;
	const isOutside = () => menuRef && setBurgerMenuOpen(false);
	useOnClickOutside(menuRef, isOutside);

	const { logOut } = useUniversalContext();
	const history = useHistory();
	const location = useLocation();
	const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
	const [userDropdownOpen, setUserDropdownOpen] = useState(false);
	const [currentPath, setCurrentPath] = useState(location?.pathname);

	useEffect(() => {
		if (location?.pathname !== currentPath) {
			setBurgerMenuOpen(false);
			setUserDropdownOpen(false);
			setCurrentPath(location?.pathname);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.pathname, currentPath]);

	const toggleMenu = () => {
		setBurgerMenuOpen(!burgerMenuOpen);
		userDropdownOpen && setUserDropdownOpen(false);
	};

	const toggleUserDropdown = () => {
		setUserDropdownOpen(!userDropdownOpen);
	};

	const handleRedirect = (url: string) => {
		if (!url) return;
		history.push(url);
	};

	return (
		<>
			<Styled.Container ref={menuRef}>
				<Styled.HeaderTop>
					{isResponsive && (
						<Styled.HeaderLogo>
							<Logo width='220px' height='40px' />
						</Styled.HeaderLogo>
					)}
					<Styled.Spacer />
					<Styled.Menu>
						{/* TODO: Think it over if we can put this component into <Button /> as well */}
						{showMessaging && <MessagingBtn />}

						{!isResponsive && (
							<>
								<Styled.HeaderDivider />
								<Avatar />

								<Caret
									onClick={toggleUserDropdown}
									dropdownOpen={userDropdownOpen}
									onClickOutside={setUserDropdownOpen}
								>
									{userDropdownOpen && <LogoutDropdown />}
								</Caret>
							</>
						)}
						{isResponsive && (
							<Styled.Burger
								role='button'
								aria-label='toggle-menu'
								onClick={toggleMenu}
							>
								{!burgerMenuOpen ? (
									<>
										<Styled.Icon src={burger} />
										<Styled.SmallText>Menu</Styled.SmallText>
									</>
								) : (
									<Styled.CloseMenu>
										{/* TODO: Think it over if we can put this component into <Button /> as well */}
										<i
											className='lni lni-close'
											style={{ color: `${colors.black}` }}
										></i>
										<Styled.SmallText>Close</Styled.SmallText>
									</Styled.CloseMenu>
								)}
							</Styled.Burger>
						)}
					</Styled.Menu>
				</Styled.HeaderTop>

				{burgerMenuOpen && isResponsive && (
					<Styled.DropdownMenu>
						<Styled.UserSection>
							<Avatar />

							<Caret
								ariaLabel='toggle-user-dropdown'
								onClick={toggleUserDropdown}
								dropdownOpen={userDropdownOpen}
							/>
						</Styled.UserSection>

						{userDropdownOpen && (
							<Styled.UserDropdown>
								<Styled.UserAction
									name='settings'
									onClick={() => handleRedirect(routesList.profile)}
								>
									My Account
								</Styled.UserAction>

								<Styled.UserAction name='logout' onClick={() => logOut('')}>
									{SIGN_OUT}
								</Styled.UserAction>
							</Styled.UserDropdown>
						)}

						<Styled.Divider />

						<Styled.DropdownRoutes>
							<Routes />
						</Styled.DropdownRoutes>

						<Footer />
					</Styled.DropdownMenu>
				)}
			</Styled.Container>
			{burgerMenuOpen && isResponsive && <Styled.PageOverlay />}
		</>
	);
};

export default withSendBird(Header);
