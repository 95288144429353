import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

interface ContainerProps {
	isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
	border: 1px solid ${colors.gradient};
	max-height: ${({ isOpen }) => (isOpen ? '100%' : '37px')};
	border-radius: 4px;
	width: 100%;
	color: white;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 10px;
`;

export const Title = styled.p`
	${fontStyles[fontSizes.fourteen]};
	font-weight: 800;
	color: ${colors.white};
	flex-grow: 1;
`;

export const Body = styled.div`
	padding: 0 10px 5px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	& > p {
		margin-bottom: 5px;

		& > span {
			display: block;
		}
	}
`;
