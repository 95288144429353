import React from 'react';

import { ReferenceRangeValues } from '../BiomarkerCard.helper';

import * as Styled from './TestResultScale.styled';

interface TestResultWithSingleRangeProps {
	resultPosition: number | null;
	isOutOfRange: boolean;
	isPercent: boolean;
	rangeLeft: string;
	rangeRight: string;
	high: number;
	low: number;
	result?: string;
}

export default function TestResultWithSingleRange({
	resultPosition,
	isOutOfRange,
	rangeLeft,
	rangeRight,
	high,
	low,
	result,
}: TestResultWithSingleRangeProps) {
	const {
		leftWidth,
		rightWidth,
		minMAX_Range,
		position,
		rangeValue,
		isResultOutOfRange,
	} = ReferenceRangeValues(rangeLeft, rangeRight, high, low, result);

	return (
		<Styled.Container>
			{typeof resultPosition === 'number' && (
				<Styled.GreenDot min={resultPosition}>
					<Styled.GreenDotInner isOutOfRange={isResultOutOfRange} />
				</Styled.GreenDot>
			)}
			<Styled.SingleRangeLeftBar barColor={rangeLeft} width={leftWidth} />
			<Styled.SingleRangeInnerContainer>
				<Styled.MINMAX position={position}>
					{minMAX_Range} ({rangeValue})
				</Styled.MINMAX>{' '}
				<Styled.MidPoint />
			</Styled.SingleRangeInnerContainer>
			<Styled.SingleRangeRightBar barColor={rangeRight} width={rightWidth} />
		</Styled.Container>
	);
}
