import React, { useEffect, useRef } from 'react';

import { throttle } from 'lodash';

import * as Styled from './Tabs.styled';

const saveScroll = throttle((left: number) => {
	localStorage.setItem('biomarker-tab-scroll', left.toString());
}, 150);
const onScroll = (e: { currentTarget: { scrollLeft: any } }) => {
	try {
		const left = Math.round(e.currentTarget.scrollLeft);
		saveScroll(left);
	} catch (err) {}
};

interface TabsOptions<T> {
	options: TabOptions<T>[];
	setActiveTab: (tab: T) => void;
	activeTab: T;
	isOrderTracking?: boolean;
	hasPendingOrders?: boolean;
	isNotSpacedEvenly?: boolean;
	hasFlexStart?: boolean;
}

export interface TabOptions<T> {
	name: T;
	label: string;
}

const Tabs = <T,>({
	options,
	setActiveTab,
	activeTab,
	isOrderTracking,
	isNotSpacedEvenly = true,
	hasFlexStart = false,
}: TabsOptions<T>) => {
	const containerRef = useRef(null);
	const isFirstRender = useRef(true);

	useEffect(() => {
		const div = containerRef?.current as unknown as HTMLDivElement;
		if (isFirstRender && activeTab && div) {
			try {
				isFirstRender.current = false;
				const prevScroll = parseInt(
					localStorage.getItem('biomarker-tab-scroll') as string
				);
				if (prevScroll > 0) {
					div.scrollLeft = prevScroll;
				}
			} catch (err) {}
		}
	}, [activeTab]);
	return (
		<Styled.Tabs
			ref={containerRef}
			isNotSpacedEvenly={isNotSpacedEvenly}
			onScroll={onScroll}
			isOrderTracking={isOrderTracking}
			hasFlexStart={hasFlexStart}
		>
			{options.map((option: TabOptions<any>) => (
				<Styled.Tab
					key={option.label}
					onClick={() => setActiveTab(option.name)}
					isActive={activeTab === option.name}
					isOrderTracking={isOrderTracking}
				>
					<Styled.Span>{option.label}</Styled.Span>
				</Styled.Tab>
			))}
		</Styled.Tabs>
	);
};

export default Tabs;
