import { useEffect, useState } from 'react';

import { sendBirdSelectors, useSendbirdStateContext } from 'sendbird-uikit';
let handlerID = 0;

const useChannels = () => {
	const [refreshTime, setRefreshTime] = useState(null);
	const context = useSendbirdStateContext();
	const sdk = sendBirdSelectors.getSdk(context);
	const [channels, setChannels] = useState([]);

	const sortAndSet = (arr) => {
		arr.sort((a, b) => {
			let adte = a.lastMessage?.createdAt;
			let bdte = b.lastMessage?.createdAt;

			if (!adte) adte = 0;
			if (!bdte) bdte = 0;
			if (adte < bdte) return +1;
			if (adte > bdte) return -1;
			return 0;
		});
		setChannels(arr);
	};

	const addCh = (arr, item) => {
		const idx = arr.findIndex((el) => el.url === item.url);
		if (idx < 0) {
			arr.push(item);
		} else {
			arr[idx] = item;
		}
	};

	const removeChannel = (url) => {
		let arr = [...channels];
		const elIdx = arr.findIndex((el) => el.url === url);
		if (elIdx > -1) {
			arr.splice(elIdx, 1);
			sortAndSet(arr);
		}
	};

	// query initial list of channels
	useEffect(() => {
		async function sendBirdChannel() {
			if (sdk && sdk.GroupChannel) {
				let listQuery = sdk.GroupChannel.createMyGroupChannelListQuery();
				listQuery.includeEmpty = true;
				listQuery.order = 'latest_last_message';
				listQuery.includeFrozen = true;

				let grpChannels = await listQuery.next();
				while (grpChannels && grpChannels.length > 0) {
					let arr = channels.slice();
					grpChannels.forEach((gc) => addCh(arr, gc));
					sortAndSet(arr);
					grpChannels = await listQuery.next();
				}
			}
		}

		sendBirdChannel();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sdk, refreshTime]);

	useEffect(() => {
		if (sdk && sdk.ChannelHandler) {
			const channelHandler = new sdk.ChannelHandler();

			channelHandler.onChannelChanged = (channel) => {
				let arr = channels.slice();
				addCh(arr, channel);
				sortAndSet(arr);
			};

			channelHandler.onChannelDeleted = (url, type) => {
				removeChannel(url, type);
			};

			channelHandler.onMessageReceived = (channel) => {
				let arr = channels.slice();
				addCh(arr, channel);
				sortAndSet(arr);
			};

			const id = handlerID++;
			sdk.addChannelHandler(id, channelHandler);
			return () => sdk.removeChannelHandler(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sdk, channels]);

	const refresh = () => setRefreshTime(new Date());
	return [channels, refresh];
};

export default useChannels;
