import React from 'react';

import ContentLoader from 'react-content-loader';

import * as Styled from './BiomarkerLoader.styled';

const Loader = () => (
	<Styled.Container>
		<ContentLoader
			height={'100%'}
			width={`100%`}
			speed={1}
			viewBox='0 0 340 135'
			backgroundColor='#D9E5E9'
			foregroundColor='#ecebeb'
		>
			<rect x='11' y='15' rx='5' ry='10' width='130' height='17' />
			<rect x='265' y='15' rx='5' ry='10' width='60' height='17' />
			<rect x='11' y='50' rx='5' ry='10' width='90' height='25' />
			<rect x='210' y='50' rx='5' ry='10' width='115' height='25' />
			<rect x='11' y='100' rx='5' ry='10' width='315' height='10' />
		</ContentLoader>
	</Styled.Container>
);

function AvatarLoader() {
	return (
		<Styled.LoaderContainer>
			{[...Array(4)].map((index) => (
				<Loader key={index} />
			))}
		</Styled.LoaderContainer>
	);
}

export default AvatarLoader;
