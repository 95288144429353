import React from 'react';

import * as Styled from './Error.styled';

function Error({
	Image,
	message,
	subTitleMessage,
}: {
	Image: any;
	message: string;
	subTitleMessage: string;
}) {
	return (
		<Styled.EmptyContainer>
			<Styled.IconWrapper>
				<Image />
			</Styled.IconWrapper>
			<Styled.EmptyTitle>{message}</Styled.EmptyTitle>
			<Styled.EmptySubtitle>{subTitleMessage}</Styled.EmptySubtitle>
		</Styled.EmptyContainer>
	);
}

export default Error;
