const chartState = {
	chartId: '',
	name: '',
};

const chartData = {
	state: chartState,
	reducers: {
		update: (state, payload) => ({
			...state,
			...payload,
		}),
	},
};

export default chartData;
