import React from 'react';

import dayjs from 'dayjs';
import greetPlugin from 'dayjs-greet';
import { useSelector } from 'react-redux';
import 'dayjs/locale/en';

import Avatar from 'components/Avatar/Avatar';

import * as Styled from './UserImage.styled';

dayjs.extend(greetPlugin);

const UserImage = () => {
	const { firstName, picture } = useSelector((state) => state.user);

	return (
		<Styled.Container>
			<Avatar picture={picture} allowSwitch={false} />
			<Styled.GreetingContainer>
				<Styled.Greeting>{`${dayjs().greet()},`}</Styled.Greeting>
				<Styled.Username>{firstName || ''}</Styled.Username>
			</Styled.GreetingContainer>
		</Styled.Container>
	);
};

export default UserImage;
