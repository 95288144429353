import React, { useState, useRef, useEffect, LegacyRef } from 'react';

import { find, get, lowerCase } from 'lodash';

import arrowDown from 'assets/svg/icons/arrow_down.svg';
import arrowUp from 'assets/svg/icons/arrow_up.svg';

import useComponentVisible from '../../hooks/useComponentVisible';

import { DropdownItemValue, DropdownProps } from './Dropdown.types';

import {
	DropDownContainer,
	DropDownHeader,
	DropDownListContainer,
	DropDownList,
	ListItem,
	ListItemSearchable,
	DropDownItemTitle,
	DropDownSearchFieldContainer,
} from './Dropdown.styled';

const Dropdown = ({
	options,
	selected,
	onSelectCallback,
	isSearchable = false,
	searchPlaceholder = 'Search',
}: DropdownProps) => {
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible();
	const [selectedOption, setSelectedOption] = useState(selected);
	const [searchValue, setSearchValue] = useState('');
	const searchRef = useRef();

	const toggling = (e: React.MouseEvent<HTMLDivElement>) => {
		setIsComponentVisible(!isComponentVisible);
	};

	useEffect(() => {
		setSearchValue('');
		if (isComponentVisible && searchRef.current) {
			// @ts-ignore
			searchRef.current.focus();
		}
	}, [isComponentVisible]);

	const onSearch = (e: { target: { value: React.SetStateAction<string> } }) => {
		setSearchValue(e.target.value);
	};

	const getOptions = () => {
		if (!searchValue) {
			return options;
		}

		return options.filter(
			(option) =>
				option.value !== null &&
				lowerCase(option.title as string).indexOf(lowerCase(searchValue)) >= 0
		);
	};

	const onOptionClicked = (value: DropdownItemValue) => () => {
		setSelectedOption(value);
		setIsComponentVisible(false);
		onSelectCallback(value);
	};

	const ListItemElement = isSearchable ? ListItemSearchable : ListItem;

	return (
		<DropDownContainer ref={ref}>
			<DropDownHeader active={isComponentVisible} onClick={toggling}>
				<DropDownItemTitle>
					{get(find(options, { value: selectedOption }), 'title', '')}
				</DropDownItemTitle>
				<img src={isComponentVisible ? arrowUp : arrowDown} alt='black caret' />
			</DropDownHeader>
			{isComponentVisible && (
				<DropDownListContainer>
					<DropDownList>
						{isSearchable && (
							<DropDownSearchFieldContainer>
								<input
									onChange={onSearch}
									value={searchValue}
									ref={searchRef as LegacyRef<any>}
									placeholder={searchPlaceholder}
								/>
							</DropDownSearchFieldContainer>
						)}
						{getOptions().map((option) => (
							<ListItemElement
								onClick={onOptionClicked(option.value)}
								key={option.value}
							>
								<DropDownItemTitle>{option.title}</DropDownItemTitle>
							</ListItemElement>
						))}
					</DropDownList>
				</DropDownListContainer>
			)}
		</DropDownContainer>
	);
};

export default Dropdown;
