import React, { useState } from 'react';

import arrowDown from 'assets/svg/icons/arrow_down.svg';
import arrowUp from 'assets/svg/icons/arrow_up.svg';

import * as Styled from './Accordion.styled';

interface AccordionProps {
	title: string;
	body: string | null;
}

const Accordion = ({ title, body }: AccordionProps) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<Styled.Container isOpen={isOpen}>
			<Styled.Header onClick={() => setIsOpen(!isOpen)}>
				<Styled.Title>{title}</Styled.Title>
				<img src={isOpen ? arrowUp : arrowDown} alt='black caret' />
			</Styled.Header>
			{isOpen && <Styled.Body>{body}</Styled.Body>}
		</Styled.Container>
	);
};

export default Accordion;
