import React from 'react';

import manIcon from 'assets/svg/icons/user.svg';

import * as Styled from './EmptyStateCard.styled';

const EmptyStateCard = ({ image, text = '', subText = '' }) => (
	<Styled.Container className='empty-state-card'>
		<Styled.ImgWrapper>
			<img src={image || manIcon} alt='Empty state placeholder' />
		</Styled.ImgWrapper>

		<Styled.TextWrapper>
			<Styled.Text>{text}</Styled.Text>

			<Styled.SubText>{subText}</Styled.SubText>
		</Styled.TextWrapper>
	</Styled.Container>
);

export default EmptyStateCard;
