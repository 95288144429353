import { removeToken } from 'utils/helpers';

const token = sessionStorage.getItem('token') || null;

const userState = {
	userId: '',
	firstName: '',
	lastName: '',
	username: '',
	email: '',
	picture: null,
	phone: '',
	width: '',
	height: '',
	age: '',
	gender: '',
	password: '',
	confirmPassword: '',
	token,
	accessToken: '',
	myCareTeamIsLoading: true,
	myCareTeamError: null,
	myCareTeam: [],
	allowMessaging: false,
	center: 'n/a',
};

const user = {
	state: userState,
	reducers: {
		updateUser: (state, payload) => ({
			...state,
			...payload,
			myCareTeamLoading: state.myCareTeamLoading,
			myCareTeamError: state.myCareTeamError,
			myCareTeam: state.myCareTeam,
		}),
		setTeam: (state, payload) => ({
			...state,
			myCareTeamLoading: payload.loading,
			myCareTeamError: payload.error,
			myCareTeam: payload.data,
		}),
		uploadPicture: (state, payload) => {
			return {
				...state,
				picture: payload,
			};
		},
		logout: () => {
			return userState;
		},
	},
	effects: {
		logoutUser() {
			removeToken();
			this.logout();
		},
	},
};

export default user;
