// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

import {
	buttonBorderRadius,
	setButtonFontSize,
	buttonFontWeight,
	setButtonLineHeight,
	setButtonPadding,
} from './Button.helper';

interface ButtonProps {
	variant: string;
	small?: boolean;
	icon?: boolean;
	rounded?: boolean;
	circle?: boolean;
	margin?: string;
	src?: string;
	alt?: string;
	disable: boolean;
}

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	small?: boolean;
}

interface SpanProps extends React.HTMLAttributes<HTMLSpanElement> {
	variant: string;
}

const setButtonBorder = (variant: string) => {
	if (variant === 'secondary') {
		return `1px solid ${colors.blue}`;
	} else if (variant === 'tertiary') {
		return `1px solid ${colors.navy}`;
	} else {
		return 'none';
	}
};

const setBackgroundColor = (variant: string) => {
	switch (variant) {
		case 'cancel':
			return 'transparent';
		case 'main':
			return colors.blue3;
		case 'gray':
			return colors.softBlue;
		default:
			return colors.white;
	}
};

const primaryButtonContainer = css<ButtonProps>`
	&:before {
		content: '';
		background-color: ${colors.white};
		border-radius: ${({ rounded }) =>
			rounded ? buttonBorderRadius.rounded.min : 'inherit'};
		bottom: 1px;
		box-sizing: border-box;
		left: 1px;
		position: absolute;
		right: 1px;
		top: 1px;
		z-index: -1;
	}
`;

const hoverBoxShadowVariant = css`0px 4px 8px ${colors.black20}`;

const hoverPrimaryVariant = css`
	background-image: ${colors.white};

	&:before {
		content: '';
		background-image: ${colors.gradientBlue};
	}

	span {
		background: ${colors.gradientText};
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	img {
		filter: brightness(0) invert(1);
	}
`;

const hoverSecondaryVariant = css`
	background-color: ${colors.blue};

	span {
		color: ${colors.white};
	}
`;

const hoverTertiaryVariant = css`
	background-color: ${colors.navy};

	span {
		color: ${colors.white};
	}
`;

const variantColorCancel = css`
	color: ${colors.navy};
`;

const variantMainButton_before_span = css`
	&:before {
		content: '';
		background-image: ${colors.gradientBlue};
	}

	span {
		background: ${colors.gradientText};
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
`;

export const Container = styled.button<ButtonProps>`
	align-items: center;
	background-color: ${({ variant }) => setBackgroundColor(variant)};
	border-radius: ${({ rounded }) =>
		rounded ? buttonBorderRadius.circle : buttonBorderRadius.rounded.max};
	border: ${({ variant }) => setButtonBorder(variant)};
	color: ${({ variant }) => variant === 'cancel' && colors.darkGrey};
	cursor: pointer;
	display: flex;
	font-size: ${({ variant, small }) => setButtonFontSize(variant, small)};
	font-weight: ${({ variant }) =>
		variant === 'tertiary' ? buttonFontWeight.default : buttonFontWeight.xxl};
	justify-content: center;
	line-height: ${({ variant, small }) => setButtonLineHeight(variant, small)};
	margin: ${({ margin }) => margin};
	overflow: hidden;
	padding: ${({ icon, small }) => setButtonPadding(icon, small)};
	position: relative;
	z-index: 0;
	// Primary
	background-image: ${({ variant }) =>
		variant === 'primary' && colors.gradient};
	${({ variant }) => variant === 'primary' && primaryButtonContainer}

	&:hover {
		box-shadow: ${({ variant }) =>
			variant !== 'cancel' && hoverBoxShadowVariant};

		// Primary
		${({ variant }) => variant === 'primary' && hoverPrimaryVariant}

		// Secondary
		${({ variant }) => variant === 'secondary' && hoverSecondaryVariant}

		// Tertiary
		${({ variant }) => variant === 'tertiary' && hoverTertiaryVariant}

		// Cancel
		${({ variant }) => variant === 'cancel' && variantColorCancel}

			// main
			${({ variant }) => variant === 'main' && variantMainButton_before_span}
	}

	&:disabled {
		background: ${colors.grey20};
		box-shadow: none;
		cursor: not-allowed;
		border: none;

		&:before {
			content: '';
			background: ${colors.grey20};
		}

		&:hover {
			background: ${colors.grey20};
			box-shadow: none;
			cursor: not-allowed;
			border: none;

			&:before {
				content: '';
				background: ${colors.grey20};
			}

			span {
				background: ${colors.darkGrey};
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			img {
				filter: none;
			}
		}
		span {
			background: ${colors.darkGrey};
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
`;

const labelVariantPrimary = css`
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: ${colors.blue};
	text-fill-color: ${colors.blue};
`;

const labelVariantSencondary = css`
	color: ${colors.blue};
`;

const labelVariantTertiary = css`
	color: ${colors.navy};
`;

const labelVariantMainButton = css`
	color: ${colors.white};
`;

const labelVariantGrayButton = css`
	color: ${colors.navy};
`;

export const Label = styled.span<SpanProps>`
	// Primary
	${({ variant }) => variant === 'primary' && labelVariantPrimary}
	// Secondary
	${({ variant }) => variant === 'secondary' && labelVariantSencondary}
	// Tertiary
	${({ variant }) => variant === 'tertiary' && labelVariantTertiary}

		// main
		${({ variant }) => variant === 'main' && labelVariantMainButton}

		//gray
		${({ variant }) => variant === 'gray' && labelVariantGrayButton}
`;

export const Icon = styled.img<ImageProps>`
	height: ${({ small }) => small && '15px'};
	margin-right: 11px;
	width: ${({ small }) => small && '15px'};
`;
