import React from 'react';

import Accordion from 'components/Accordion/Accordion';
import { EducationalContentProps } from 'pages/HealthTracking/HealthTracking.helper';
import {
	ABOUT,
	FUEL,
	HEALTH_IMPACT,
	LIFESTYLE,
	PEAK_FITNESS,
	TAKING_ACTION,
} from 'utils/internationalization/en';

import * as Styled from './EducationalContent.styled';

function EducationalContent({
	about,
	healthImpact,
	lifeStyle,
	fuel,
	exercise,
	title,
}: EducationalContentProps) {
	return (
		<>
			{about && <Accordion title={ABOUT + title} body={about} />}
			{healthImpact && <Accordion title={HEALTH_IMPACT} body={healthImpact} />}
			{(lifeStyle || fuel || exercise) && (
				<>
					<Styled.TakeActionTitle>{TAKING_ACTION}</Styled.TakeActionTitle>
					<Styled.TakeActionContainer>
						{lifeStyle && (
							<Styled.Card>
								<Styled.CardTitle>{LIFESTYLE}</Styled.CardTitle>
								<Styled.Paragraph>{lifeStyle}</Styled.Paragraph>
							</Styled.Card>
						)}
						{fuel && (
							<Styled.Card>
								<Styled.CardTitle> {FUEL}</Styled.CardTitle>
								<Styled.Paragraph>{fuel}</Styled.Paragraph>
							</Styled.Card>
						)}
						{exercise && (
							<Styled.Card>
								<Styled.CardTitle> {PEAK_FITNESS}</Styled.CardTitle>
								<Styled.Paragraph>{exercise}</Styled.Paragraph>
							</Styled.Card>
						)}
					</Styled.TakeActionContainer>
				</>
			)}
		</>
	);
}

export default EducationalContent;
