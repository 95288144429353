import { chartsKey } from 'utils/constants';

export const toFeetAndInch = (inches) => {
	return parseInt(inches / 12) + `'` + Math.round(inches % 12);
};

export const healthTrackingWeightCard = {
	chartId: chartsKey.weight,
	name: 'Weight',
	value: 'N/A',
	type: 'lbs',
	mapKey: chartsKey.weight,
};
