import React, { Suspense, lazy } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import PageLoader from 'components/PageLoader/PageLoader';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import RouteGuard from 'components/RouteGuard';
import MyHealth from 'pages/MyHealth/MyHealthPage';

const MyCareTeam = lazy(() => import('pages/MyCareTeam/MyCareTeam'));
const BiomarkersDetail = lazy(() =>
	import('pages/Biomarkers/BiomarkersDetail')
);
const BiometricDetail = lazy(() =>
	import('pages/HealthTracking/BiometricDetail')
);
const MyProfile = lazy(() => import('pages/MyProfile/MyProfile'));
const Messaging = lazy(() => import('pages/Messaging/Messaging'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy/PrivacyPolicy'));
const RegistrationSuccess = lazy(() =>
	import('pages/Registration/RegistrationSuccess')
);
const TestResultsViewFile = lazy(() =>
	import('pages/TestResults/TestResultsViewFile')
);
const MyProductsViewFile = lazy(() =>
	import('pages/MyProducts/MyProductsViewFile')
);
const OrderInvoiceViewFile = lazy(() =>
	import('pages/MyOrders/OrderInvoiceViewFile')
);
const Login = lazy(() => import('pages/Login/Login'));

const MyOrders = lazy(() => import('pages/MyOrders/MyOrder'));

const Router = ({ location }) => (
	<Suspense fallback={<PageLoader />}>
		<Switch>
			<PrivateRoute path='/' exact>
				<Redirect to='/my-team' />
			</PrivateRoute>
			<PrivateRoute path='/my-team'>
				<MyCareTeam />
			</PrivateRoute>
			<PrivateRoute path='/biometrics/detail'>
				<Redirect to='/my-health/biometrics' />
			</PrivateRoute>
			<PrivateRoute path='/biometrics'>
				<Redirect to='/my-health/biometrics' />
			</PrivateRoute>
			<PrivateRoute path='/biomarkers/detail'>
				<Redirect to='/my-health/biomarkers' />
			</PrivateRoute>
			<PrivateRoute path='/biomarkers'>
				<Redirect to='/my-health/biomarkers' />
			</PrivateRoute>
			<Route
				path='/diagnostics/:id'
				render={({ match }) => {
					const fileId = match.url.split('/diagnostics/')[1];
					return <Redirect to={`/my-health/reports/${fileId}`} />;
				}}
			/>
			<Route
				path='/reports/:id'
				render={({ match }) => {
					const fileId = match.url.split('/reports/')[1];
					return <Redirect to={`/my-health/reports/${fileId}`} />;
				}}
			/>
			<PrivateRoute exact path='/diagnostics'>
				<Redirect to='/my-health/reports' />
			</PrivateRoute>
			<PrivateRoute path='/products'>
				<Redirect to='/my-health/regimen' />
			</PrivateRoute>
			<PrivateRoute path='/products-view-file'>
				<Redirect to='/my-health/regimen/detail' />
			</PrivateRoute>
			<PrivateRoute path='/profile'>
				<MyProfile />
			</PrivateRoute>
			<PrivateRoute path='/orders'>
				<MyOrders />
			</PrivateRoute>
			<PrivateRoute path='/order-invoice-view-file'>
				<OrderInvoiceViewFile />
			</PrivateRoute>
			<PrivateRoute path='/messaging'>
				<Messaging />
			</PrivateRoute>
			{/** MyHealth */}
			<PrivateRoute path='/my-health/biomarkers/detail'>
				<BiomarkersDetail />
			</PrivateRoute>
			<PrivateRoute path='/my-health/biometrics/detail'>
				<BiometricDetail />
			</PrivateRoute>
			<PrivateRoute path='/my-health/reports/:id'>
				<TestResultsViewFile />
			</PrivateRoute>
			<PrivateRoute path='/regimen/detail'>
				<MyProductsViewFile />
			</PrivateRoute>
			<PrivateRoute path='/my-health/biomarkers'>
				<MyHealth />
			</PrivateRoute>
			<PrivateRoute path='/my-health/biometrics'>
				<MyHealth />
			</PrivateRoute>
			<PrivateRoute path='/my-health/reports'>
				<MyHealth />
			</PrivateRoute>
			<PrivateRoute path='/my-health/regimen'>
				<MyHealth />
			</PrivateRoute>
			<PrivateRoute path='/my-health'>
				<Redirect to='/my-health/biomarkers' />
			</PrivateRoute>
			{/** Auth routes */}
			<PrivateRoute path='/privacy-policy'>
				<PrivacyPolicy />
			</PrivateRoute>
			<Route path='/login'>
				<Login location={location} />
			</Route>
			<Route path='/registration-success'>
				<RegistrationSuccess />
			</Route>
			<Route path='*'>
				<RouteGuard />
			</Route>
		</Switch>
	</Suspense>
);

export default Router;
