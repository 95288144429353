import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

export const Container = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border: 1px solid ${colors.navy};
	border-radius: 2px;
	cursor: pointer;
	background: transparent;
`;

export const CaretUp = styled.div`
	width: 0px;
	height: 0px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom: 6px solid ${colors.black};
`;

export const CaretDown = styled.div`
	width: 0px;
	height: 0px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 6px solid ${colors.black};
`;
