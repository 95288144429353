import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useGetTotalBodyFat } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';
import { chartsKey } from 'utils/constants';

import { getChartBgColors } from './utils/chartColorizing';
import {
	adaptFatBodyTable,
	defaultChartAxisValues,
	gradientBgPlugin,
	handleChartData,
	getChartMaxValue,
	increaseOrDecreaseMaxYThreshold,
} from './utils/chartHelpers';
import {
	defaultConfig,
	tooltipCallbackPercentage,
	ticksCallbackPercentage,
	styledChartElements,
} from './utils/customViewChart';

const TotalBodyFat = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [tableData, setTableData] = useState(null);
	const [chartConfig, setChartConfig] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { gender } = useSelector((state) => state.user);

	const { data: bodyFatData, loading } = useGetTotalBodyFat();
	const isDataAvailable = bodyFatData?.data && bodyFatData.data.length > 0;
	const lastElement = isDataAvailable && bodyFatData.data.length - 1;

	useEffect(() => {
		if (isDataAvailable) {
			const refValues = bodyFatData?.referential_values?.general;
			const dataAdapted = handleChartData(bodyFatData.data);
			const lastResultTotalBodyFat = bodyFatData.data[lastElement];
			const tableDataAdapted = adaptFatBodyTable(
				bodyFatData?.referential_values,
				lastResultTotalBodyFat,
				gender
			);

			setData(dataAdapted);
			setTableData(tableDataAdapted);
			setLabelsData(dataAdapted?.labels);
			handleChartConfig(refValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodyFatData]);

	const handleChartConfig = (refValues) => {
		const minY = defaultChartAxisValues[chartsKey.totalBodyFat].minY;
		let maxY = defaultChartAxisValues[chartsKey.totalBodyFat].maxY;

		const maxValueOfY = getChartMaxValue(bodyFatData.data, 'value');

		if (maxValueOfY && maxValueOfY >= maxY) {
			maxY = maxValueOfY + increaseOrDecreaseMaxYThreshold.large;
		}

		const chartColors = (refValues && getChartBgColors(refValues, maxY)) || [];

		const config = {
			...defaultConfig,
			plugins: {
				...defaultConfig.plugins,
				backgrounds: {
					hbars: chartColors,
				},
				tooltip: {
					...defaultConfig.plugins.tooltip,
					callbacks: { label: tooltipCallbackPercentage },
				},
			},
			elements: styledChartElements(lastElement),
			scales: {
				...defaultConfig.scales,
				y: {
					...defaultConfig.scales.y,
					min: minY,
					max: maxY,
					ticks: {
						...defaultConfig.scales.y.ticks,
						callback: ticksCallbackPercentage,
					},
				},
			},
		};

		setChartConfig(config);
	};

	return (
		<ChartModal
			data={data}
			loading={loading}
			tableData={tableData}
			labelsData={labelsData}
			closeChart={closeChart}
			chartConfig={chartConfig}
			chartPlugins={gradientBgPlugin}
			isOpen={isOpen}
		/>
	);
};

export default TotalBodyFat;
