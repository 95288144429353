import React, { useRef } from 'react';

import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import chartIcon from 'assets/svg/icons/chart.svg';
import Modal from 'components/Modal/Modal';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { device } from 'styles/device';
import { modalStyle } from 'utils/constants';
import {
	CHART_EMPTY_TEXT,
	CHART_EMPTY_SUB_TEXT,
} from 'utils/internationalization/en';

import EmptyStateCard from '../EmptyStateCard/EmptyStateCard';

import { isEmptyArray } from './ChartModal.helper';

import ChartLegend from './ChartLegend/ChartLegend';
import ReferentialTable from './ReferentialTable/ReferentialTable';

import * as Styled from './ChartModal.styled';

const ChartModal = ({
	children,
	data,
	tableData,
	chartConfig,
	chartPlugins,
	closeChart,
	loading,
	labelsData,
	isOpen,
}) => {
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

	const isMobile = useMediaQuery({ query: device.mobileL });
	const isTablet = useMediaQuery({ query: device.tablet });

	const isMobileOrTablet = isMobile || isTablet;

	const { name } = useSelector((state) => state.chartData);
	const modalRef = useRef();

	const isDataAvailable = !loading && labelsData && !isEmptyArray(labelsData);
	const isEmptyState =
		(!loading && !labelsData) || (!loading && isEmptyArray(labelsData));

	useOnClickOutside(modalRef, () => {
		if (closeChart) closeChart();
	});

	const chart = () => {
		return (
			<>
				{isDataAvailable && (
					<Styled.MainColumn>
						{name && <Styled.ChartTitleMobile>{name}</Styled.ChartTitleMobile>}
						{tableData && <ReferentialTable data={tableData} />}

						<Styled.ChartWrapper>
							<Styled.ChartTitle>Your Trend</Styled.ChartTitle>
							<Styled.ChartContainer>
								{children || (
									<Line
										data={data}
										options={chartConfig}
										plugins={chartPlugins}
									/>
								)}
							</Styled.ChartContainer>
						</Styled.ChartWrapper>

						<ChartLegend tableData={tableData} labelsData={labelsData} />
					</Styled.MainColumn>
				)}

				{isEmptyState && (
					<Styled.EmptyState>
						<EmptyStateCard
							text={CHART_EMPTY_TEXT}
							subText={CHART_EMPTY_SUB_TEXT}
							image={chartIcon}
						/>
					</Styled.EmptyState>
				)}
			</>
		);
	};

	if (isMobileOrTablet) {
		return chart();
	}

	return (
		<Modal
			isPortrait={isPortrait}
			loading={loading}
			onRequestClose={closeChart}
			isOpen={isOpen}
			style={modalStyle}
			title={name}
			isEmpty={isEmptyState}
		>
			{chart()}
		</Modal>
	);
};

export default ChartModal;
