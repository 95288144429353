import * as Sentry from '@sentry/react';

import { colors } from 'styles/colors';

export const teamMembersHelper = {
	physician: {
		primaryColor: colors.blue3,
		secondaryColor: colors.secondaryBlue3,
		role: 'Physician',
	},
	health_coach: {
		primaryColor: colors.burgundy,
		secondaryColor: colors.secondaryBurgundy,
		role: 'Performance Health Coach',
	},
	secondary_health_coach: {
		primaryColor: colors.burgundy,
		secondaryColor: colors.secondaryBurgundy,
		role: 'Performance Health Coach',
	},
	service_coordinator: {
		primaryColor: colors.gold,
		secondaryColor: colors.gold10,
		role: 'Patient Experience Coordinator',
	},
	secondary_service_coordinator: {
		primaryColor: colors.gold,
		secondaryColor: colors.gold10,
		role: 'Patient Experience Coordinator',
	},
	tertiary_service_coordinator: {
		primaryColor: colors.gold,
		secondaryColor: colors.gold10,
		role: 'Patient Experience Coordinator',
	},
};

export const mapRoleByKey = (key) => teamMembersHelper[key].role;

export const getMembersIds = (data) => data.map((obj) => obj.id);

export const removeDuplicatedMembers = (members, membersIds) =>
	members.filter(({ id }, index) => !membersIds.includes(id, index + 1));

export const getJobRole = (currentRole, teamKeys, teamData) => {
	try {
		let role = mapRoleByKey(currentRole);
		teamKeys.forEach((nextRole) => {
			if (
				currentRole !== nextRole &&
				teamData[currentRole].id === teamData[nextRole].id
			) {
				const secondRole = mapRoleByKey(nextRole);
				role = `${role} & ${secondRole}`;
			}
		});

		return role;
	} catch (error) {
		console.error(error.message);
		Sentry.captureException(error);
	}
};

export const adaptTeamData = (teamKeys, teamData) =>
	teamKeys.map((key) => {
		//let jobRole = getJobRole(key, teamKeys, teamData);
		const data = teamData[key];

		return {
			...data,
			key: key,
			jobRole: data['member_role'] || 'N/A',
		};
	});

export const mockResponse = {
	physician: {
		id: '1',
		full_name: 'name 1',
		title: 'Physician',
		city: 'city 1',
		state: 'string',
		jobRole: 'role_physician',
		member_role: 'role_physician',
		photo: '',
		Center__c: 'city 1, state',
	},
	health_coach: {
		id: '2',
		full_name: 'name 2',
		title: 'Performance Health Coach',
		city: 'city 2',
		state: 'string',
		jobRole: 'role_coach',
		member_role: 'role_coach',
		photo: '',
		Center__c: 'city 1, state',
	},
	service_coordinator: {
		id: '3',
		full_name: 'name 3',
		title: 'Patient Experience Coordinator',
		city: 'city 3',
		state: 'string',
		jobRole: 'role_coordinator',
		member_role: 'role_coordinator',
		photo: '',
		Center__c: 'city 1, state',
	},
};

export const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0;

export const isDataAvailable = (error, data) => {
	return error === null && !isEmptyObject(data);
};
