import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

import {
	CommonCard,
	CommonCardDate,
	CommonCardDescription,
	CommonCardGeneralInfo,
	CommonCardWrapper,
} from '../CommonCard/CommonCard.styled';

export const CardWrapper = styled(CommonCardWrapper)`
	flex: 0 0 calc(33% - 5px);

	@media ${device.laptopL} {
		flex: 0 0 calc(33% - 5px);
	}

	@media ${device.tablet} {
		flex: 0 0 calc(33.33% - 5px);
	}

	@media ${device.mobileXL} {
		flex: 0 0 calc(50% - 5px);
	}

	@media ${device.mobileL} {
		flex: 0 1 100%;
		max-width: 100%;
	}

	margin: 0;
`;

export const TestCard = styled(CommonCard)`
	align-items: flex-start;
	padding: 20px 10px 20px 16px;
`;

export const TestGeneralInfo = styled(CommonCardGeneralInfo)`
	margin: 0;
	align-items: flex-start;
`;

export const TestTitle = styled.div`
	font-weight: 900;
	font-size: 14px;
	text-align: left;
	word-break: break-word;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
`;

export const TestDescription = styled(CommonCardDescription)`
	text-align: left;
	margin: 0;
`;

export const TestDateAndCategoryContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
`;

export const DateAndFileContainer = styled.div`
	display: inline-flex;
	align-items: center;
	flex-direction: row;
	gap: 12px;
`;

export const TestDate = styled(CommonCardDate)`
	color: ${colors.darkGrey};
	font-weight: 900;
	font-size: 14px;
	line-height: 19px;
`;
