import React from 'react';

import { useDispatch } from 'react-redux';

import { healthTrackingBodyCompositionCards } from 'utils/healthTrackBodyCompositionCards';
import {
	BODY_COMPOSITION_EMPTY_SUB_TEXT,
	BODY_COMPOSITION_EMPTY_TEXT,
} from 'utils/internationalization/en';

import EmptyStateCard from '../EmptyStateCard/EmptyStateCard';

import CompositionCard from './CompositionCard/CompositionCard';

import * as Styled from './BodyComposition.styled';

const BodyComposition = ({ openChart }) => {
	const isDataAvailable = healthTrackingBodyCompositionCards.length > 0;

	const dispatch = useDispatch();

	const renderCompositionCards = () => {
		return healthTrackingBodyCompositionCards.map((item) => (
			<CompositionCard
				{...item}
				key={item.name}
				handleSeeMore={handleOpenChart}
			/>
		));
	};

	const handleOpenChart = (data) => {
		openChart();
		dispatch.chartData.update(data);
	};

	return (
		<Styled.Container>
			<Styled.Title>Body Composition</Styled.Title>

			<Styled.Row>
				{isDataAvailable ? (
					renderCompositionCards()
				) : (
					<EmptyStateCard
						text={BODY_COMPOSITION_EMPTY_TEXT}
						subText={BODY_COMPOSITION_EMPTY_SUB_TEXT}
					/>
				)}
			</Styled.Row>
		</Styled.Container>
	);
};

export default BodyComposition;
