// Because of https://caniuse.com/resizeobserver
// adding polyfill for ResizeObserver
import 'resize-observer-polyfill/dist/ResizeObserver.global';

import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router as BrowserRouter } from 'react-router-dom';

import store from 'store';
import { initializeGtag, GOOGLE_ANALYTICS_ID } from 'utils/analytics';
import history from 'utils/history';

import App from './App';

initializeGtag(GOOGLE_ANALYTICS_ID);

if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
	// skip sentry in dev mode
} else {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
			}),
		],
		tracesSampleRate: 1.0,
	});
}

if (process.env.NODE_ENV === 'development') {
	const { worker } = require('./mocks/browser');
	worker.start();
}

const onRedirectCallback = (appState) => {
	const returnTo = appState?.returnTo || window.location.pathname;
	history.push(returnTo);
};

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter history={history}>
				{window.nativeContext ? (
					<App />
				) : (
					<Auth0Provider
						domain={process.env.REACT_APP_AUTH0_DOMAIN}
						clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
						audience={process.env.REACT_APP_AUTH0_AUDIENCE}
						redirectUri={window.location.origin}
						onRedirectCallback={onRedirectCallback}
					>
						<App />
					</Auth0Provider>
				)}
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
