const orderTrackingState = {
	isApproved: false,
	isDeclined: false,
	orderTrackingNumber: null,
};

const orderTracking = {
	state: orderTrackingState,
	reducers: {
		updateApproval: (state: any, payload: any) => ({
			...state,
			isApproved: payload.isApproved,
			orderTrackingNumber: payload.orderTrackingNumber,
		}),
		updateDecline: (state: any, payload: any) => ({
			...state,
			isDeclined: payload.isDeclined,
			orderTrackingNumber: payload.orderTrackingNumber,
		}),
	},
};

export default orderTracking;
