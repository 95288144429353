import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

export const BackButtonContainer = styled.div`
	margin-bottom: 20px;
`;

export const BackIcon = styled.img`
	margin-right: 10px;
`;

export const BackButtonText = styled.p`
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 24px;
	/* identical to box height, or 100% */

	display: flex;
	align-items: center;

	color: ${colors.black};
`;

export const BackButtonAnchorTag = styled.a`
	display: flex;
	cursor: pointer;
`;
