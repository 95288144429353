import React from 'react';

import Tippy from '@tippyjs/react';

import infoIcon from 'assets/svg/icons/info.svg';

import { icons, tooltips } from './Tooltip.helper';

import * as Styled from './Tooltip.styled';

const Tooltip = ({
	mapKey,
	color,
	title,
	rightAligned = false,
	hPadding = 0,
}) => {
	let titleContent;
	if (title) {
		titleContent = (
			<>
				<div>
					<Styled.ToolTipTitle>{title}</Styled.ToolTipTitle>
				</div>
				<Styled.ToolTipText>{tooltips[mapKey]}</Styled.ToolTipText>
			</>
		);
	}

	return (
		<Styled.Container
			name='tooltip'
			padding={hPadding}
			justifyContent={rightAligned && 'flex-end'}
		>
			<Tippy
				content={titleContent || tooltips[mapKey]}
				className='tooltip'
				allowHTML
			>
				<img src={icons[color] || infoIcon} alt='Information' />
			</Tippy>
		</Styled.Container>
	);
};

export default Tooltip;
