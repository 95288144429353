import React from 'react';

import useWindowDimensions from 'hooks/useWIndowDimensions';

import * as Styled from './Routes.styled';

const routes = [
	{
		url: '/',
		icon: 'users',
		label: 'My Team',
		showOnDesktop: true,
	},
	{
		url: '/my-health',
		icon: 'heart',
		label: 'My Health',
		showOnDesktop: false,
	},
	{
		url: '/orders',
		icon: 'cart-full',
		label: 'Orders',
		showOnDesktop: false,
	},
	{
		url: '/my-health/reports',
		icon: 'files',
		label: 'Reports',
		showOnDesktop: true,
		showOnMobile: false,
	},
];

const Routes = () => {
	const { width } = useWindowDimensions();

	let isLargeWidth = width > 768;

	return (
		<Styled.Container>
			{routes.map((route) => {
				if (route.showOnDesktop === false && isLargeWidth) {
					return null;
				} else if (route.showOnMobile === false && !isLargeWidth) {
					return null;
				} else {
					return (
						<Styled.Route key={route.url} to={route.url} name={route.label}>
							<Styled.Wrapper>
								<Styled.Background>
									<Styled.Text>
										<i className={`lni lni-${route.icon}`}></i>
										{route.label}
									</Styled.Text>
								</Styled.Background>
							</Styled.Wrapper>
						</Styled.Route>
					);
				}
			})}
		</Styled.Container>
	);
};

export default Routes;
