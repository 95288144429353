import { BiomarkerTestResult } from 'components/BiomarkerCard/BiomarkerCard.helper';

export const biomarker = [];

export enum BiomarkerTestCategory {
	chem_screen = 'Chem-Screen',
	cholesterol_panel = 'Cholesterol Panel',
	cardio_iq = 'Cardio IQ',
	lipoprotein_fractionation = 'Lipoprotein Fractionation',
	inflammation_endothelial_health = 'Inflammation and Endothelial Health',
	omega_check = 'OmegaCheck',
	CBC_differential_platelet = 'CBC w/ Differential & Platelet',
	cbc_other = 'CBC (Other)',
	hormone_panel = 'Hormone Panel',
	thyroid_panel = 'Thyroid Panel',
	urinalysis = 'Urinalysis',
	infectious_disease = 'Infectious Disease',
	other = 'Other',
	patient_demographics = 'Patient Demographics',
}

export const BiomarkerTestCategoryArray = [
	BiomarkerTestCategory.chem_screen,
	BiomarkerTestCategory.cholesterol_panel,
	BiomarkerTestCategory.cardio_iq,
	BiomarkerTestCategory.lipoprotein_fractionation,
	BiomarkerTestCategory.inflammation_endothelial_health,
	BiomarkerTestCategory.omega_check,
	BiomarkerTestCategory.CBC_differential_platelet,
	BiomarkerTestCategory.cbc_other,
	BiomarkerTestCategory.hormone_panel,
	BiomarkerTestCategory.thyroid_panel,
	BiomarkerTestCategory.urinalysis,
	BiomarkerTestCategory.infectious_disease,
	BiomarkerTestCategory.other,
	BiomarkerTestCategory.patient_demographics,
];

export interface OptionProps {
	id: number;
	name: string;
}

export interface BiomarkerResultProps {
	data: any;
	loading: boolean;
}
const getYear = (date: string) => {
	try {
		return new Date(date).getFullYear();
	} catch (err) {}
	return '-';
};

export type FormattedChartData = {
	label: string[];
	data: string[];
	unit: string;
};

export const getYearFromDateArray = (dateArray: string[]) => {
	return dateArray.reduce((acc, item, idx, arr) => {
		const curYear = getYear(item);
		const prevYear = idx === 0 ? '0' : getYear(arr[idx - 1]);
		//@ts-ignore
		acc.push(curYear === prevYear ? '' : curYear.toString());
		return acc;
	}, []);
};

export function getFormattedChartData(
	graphData: BiomarkerTestResult[]
): FormattedChartData {
	graphData = graphData.sort(
		(a: BiomarkerTestResult, b: BiomarkerTestResult) => {
			try {
				return (
					new Date(a?.Collected_Date_Time__c).valueOf() -
					new Date(b?.Collected_Date_Time__c).valueOf()
				);
			} catch (err) {
				return 0;
			}
		}
	);
	const label = graphData.reduce((acc, item, idx, arr) => {
		//@ts-ignore
		acc.push(item.Collected_Date_Time__c);
		return acc;
	}, []);

	const data = graphData.map((el) => el.Result__c);
	const unit = graphData[0].Unit_of_Measure__c;
	return { label, data, unit };
}

export const getDates = (dates: any) => {
	const newDate = dates.split('-');
	const year = newDate[0];
	return year;
};

export const DateDropDownOptions = [
	{
		name: 'All',
		value: 'all_time',
	},
	{
		name: 'Last 2 years',
		value: 'two_years',
	},
	{
		name: 'Last 3 years',
		value: 'three_years',
	},
];
