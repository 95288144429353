import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export function setTabFontSize(isOrderTracking: boolean | undefined) {
	if (isOrderTracking) {
		return fontStyles[fontSizes.fourteen];
	} else {
		return fontStyles[fontSizes.eighteen];
	}
}

export function setTabBgImage(
	isActive: boolean,
	isOrderTracking: boolean | undefined
) {
	if (isActive && !isOrderTracking) {
		return `linear-gradient(rgba(0, 145, 148, 1), rgba(72, 71, 112, 1)), ${colors.gradientBlue}`;
	} else {
		return 'unset';
	}
}

export function setTabBgClip(
	isActive?: boolean,
	isOrderTracking?: boolean | undefined
) {
	if (isActive && !isOrderTracking) {
		return 'content-box, border-box';
	} else {
		return 'unset';
	}
}

export function setTabBgColor(
	isActive?: boolean,
	isOrderTracking?: boolean | undefined
) {
	if (isActive && isOrderTracking) {
		return `${colors.softBlue}`;
	} else if (isOrderTracking) {
		return `${colors.white}`;
	} else {
		return 'unset';
	}
}
