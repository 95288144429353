import { useEffect, useState } from 'react';

import { sendBirdSelectors, useSendbirdStateContext } from 'sendbird-uikit';
let ID = 0;

const useConnectionStatus = () => {
	const context = useSendbirdStateContext();
	const sdk = sendBirdSelectors.getSdk(context);

	const [connectionStatus, setConnectionStatus] = useState('Not Connected');

	useEffect(() => {
		if (sdk && sdk.ConnectionHandler) {
			if (sdk.isSessionOpened) setConnectionStatus('Connected');
			const h = new sdk.ConnectionHandler();
			h.onReconnectStarted = () => setConnectionStatus('Connecting');
			h.onReconnectSucceeded = () => setConnectionStatus('Connected');
			h.onReconnectFailed = () => setConnectionStatus('Failed');
			const id = ID++;
			sdk.addConnectionHandler(id, h);
			return () => sdk.removeConnectionHandler(id);
		}
	}, [sdk]);
	return connectionStatus;
};

export default useConnectionStatus;
