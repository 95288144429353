import { getDefaultTagsList } from '../TestFilters.helper';
import { TestFiltersType } from '../TestFilters.types';

import {
	SET_PERIOD_FILTER,
	SET_TAGS_FILTER,
	SET_TYPE_FILTER,
	TestResultsFiltersActions,
} from './TestResultsActions';

export const testResultsFiltersInitialState: TestFiltersType = {
	testType: null,
	testPeriod: null,
	testTags: [],
};

export const testResultsFilterReducer = (
	state: TestFiltersType,
	action: TestResultsFiltersActions
) => {
	switch (action.type) {
		case SET_TYPE_FILTER:
			return {
				...state,
				testType: action.payload,
				testTags: getDefaultTagsList(action.payload as number | null),
			};
		case SET_PERIOD_FILTER:
			return {
				...state,
				testPeriod: action.payload,
			};
		case SET_TAGS_FILTER:
			return {
				...state,
				testTags: action.payload,
			};
		default: {
			return { ...state };
		}
	}
};
