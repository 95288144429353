import React from 'react';

import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Tooltip from 'components/Tooltip/Tooltip';
import { device } from 'styles/device';
import { getWholeNumber } from 'utils/helpers';

import * as Styled from './CompositionCard.styled';

const CompositionCard = ({
	chartId,
	name,
	value,
	type,
	icon,
	backgroundColor,
	mapKey,
	handleSeeMore,
	healthIndicator,
}) => {
	const biometrics = useSelector((state) => state.biometrics);

	const isMobile = useMediaQuery({ query: device.tablet });
	const onClickHandler = () => handleSeeMore({ chartId, name });

	return (
		<Styled.Container
			background={backgroundColor}
			tabIndex='0'
			onClick={onClickHandler}
			name='openChart'
		>
			<Styled.Column>
				<Styled.Row>
					{isMobile && (
						<Styled.SeeMore name='openChart'>See More</Styled.SeeMore>
					)}

					<Styled.Icon name='icon'>
						<img src={icon} alt='Parameter' />
					</Styled.Icon>
				</Styled.Row>

				<Styled.Column>
					<Styled.Data name={chartId}>
						{getWholeNumber(biometrics[mapKey]) || value}
					</Styled.Data>
					<Styled.DataType name='dataType'>{type}</Styled.DataType>
				</Styled.Column>
			</Styled.Column>

			<Styled.Wrapper>
				<Styled.Buttons>
					<Tooltip mapKey={mapKey} />
				</Styled.Buttons>

				<Styled.Text title={name}>{name}</Styled.Text>
			</Styled.Wrapper>
		</Styled.Container>
	);
};

export default CompositionCard;
