import { useState, useEffect } from 'react';

import {
	bodyComposition,
	healthTrackingCards,
	healthTrackingHealthCards,
} from 'components/Biometrics/Biometrics.helper';

interface BiometricHealthDataState {
	chartId: string;
	healthIndicator: string | null;
	icon: string;
	mapKey: string;
	name: string;
	type: string;
	value: string;
}

export const useFormatBiometrics = (
	activeTabProps?: string,
	biometricData?: any
) => {
	const [healthData, setBiometricHealthData] =
		useState<BiometricHealthDataState[]>();

	useEffect(() => {
		switch (activeTabProps) {
			case 'health':
				const healthData = healthTrackingHealthCards;
				setBiometricHealthData(healthData);
				break;
			case 'fitness':
				const fitnessData = healthTrackingCards;
				setBiometricHealthData(fitnessData);
				break;
			case 'body_composition':
				const bodyCompositionData = bodyComposition;
				setBiometricHealthData(bodyCompositionData);
				break;
			default:
				setBiometricHealthData(healthTrackingHealthCards);
		}
	}, [activeTabProps]);

	const biometricsHealthData = healthData?.filter(
		(data: any) =>
			Object.keys(biometricData)?.indexOf(data.chartId || data.mapKey) !== -1 ||
			Object.keys(biometricData).some(
				(key: string) => key === data.chartId || data.mapKey
			)
	);
	return { biometricsHealthData };
};
