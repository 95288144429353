import React from 'react';

import { formatDate } from 'utils/helpers';
import { PRODUCT_COMPONENT_NAME } from 'utils/internationalization/en';

import { productIcons } from './ProductCard.helper';

import ProductAccordion from './ProductAccordion';
import ProductCardMain from './ProductCardMain/ProductCardMain';

import * as Styled from './ProductCard.styled';

const ProductCard = ({
	title,
	category,
	packaging,
	usage,
	lastRefill,
	nextRefill,
	productId,
}) => {
	return (
		<ProductCardMain
			description={packaging}
			icon={productIcons[category]}
			pdfId={productId}
			title={title}
			componentName={PRODUCT_COMPONENT_NAME}
		>
			<ProductAccordion title={'Details'}>
				{usage && (
					<Styled.UsageContainer>
						<Styled.Usage>{usage}</Styled.Usage>
					</Styled.UsageContainer>
				)}
				{(lastRefill || nextRefill) && (
					<>
						<Styled.RefillContainer>
							<Styled.Divider />
							{nextRefill && (
								<Styled.Refill>{`Next Refill: ${formatDate(
									nextRefill
								)}`}</Styled.Refill>
							)}
							{lastRefill && (
								<Styled.Refill>{`Last Refill: ${formatDate(
									lastRefill
								)}`}</Styled.Refill>
							)}
						</Styled.RefillContainer>
					</>
				)}
			</ProductAccordion>
		</ProductCardMain>
	);
};

export default ProductCard;
