import React, { useState, useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import {
	useGetBiometrics,
	useGetUser,
	useGetBiometricContents,
} from 'api/services';
import manIcon from 'assets/svg/icons/user.svg';
import {
	healthTrackingCards,
	healthTrackingHealthCards,
} from 'components/Biometrics/Biometrics.helper';
import Item from 'components/Biometrics/BiometricsCard/Item/Item';
import BodyComposition from 'components/BodyComposition/BodyComposition';
import EducationalContent from 'components/EducationalContent/EducationalContent';
import EmptyStateCard from 'components/EmptyStateCard/EmptyStateCard';
import NavigateBackButton from 'components/NavigateBackButton/NavigateBackButton';
import PageContainer from 'components/PageContainer/PageContainer';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import HealthTrackingLoader from 'components/Skeleton/HealthTrackingLoader/HealthTrackingLoader';
import Tabs from 'components/Tabs/Tabs';
import Titles from 'components/Titles/Titles';
import useAnalytics from 'hooks/useAnalytics';
import { device } from 'styles/device';
import {
	BIOMETRIC_MAIN_TITLE,
	BIOMETRIC_SUB_TITLE,
	BIOMETRIC_SUB_TITLE_MORE,
	BODY_COMPOSITION_TAB,
	BODY_COMPOSITY,
	FITNESS_TAB,
	FITNESS_TITLE,
	HEALTH_TITLE,
} from 'utils/internationalization/en';

import {
	biometricsMapping,
	BiometricsTabs,
	options,
	ChartData,
	BiometricsType,
	BiometricsPayload,
	EducationContentApiProps,
	BiometricContents,
	ChartDataProps,
} from './HealthTracking.helper';

import { ChartComponent } from './chartComponent';
import { useFormatBiometrics } from './useGetBiometrics';

import * as Styled from './HealthTracking.styled';

const HealthTracking = ({
	hasBottomNavigation,
}: {
	hasBottomNavigation?: boolean;
}) => {
	const [activeTab, setActiveTab] = useState<BiometricsTabs>(
		BiometricsTabs.body_composition
	);
	const { chartMapping, setIsChartOpen } = ChartComponent();

	const userState = useSelector((state: any) => state.user);
	const { chartId } = useSelector((state: ChartData) => state.chartData);

	const dispatch = useDispatch();
	const history = useHistory();

	const healthData = healthTrackingHealthCards;

	const fitnessData = healthTrackingCards;

	const { data: user } = useGetUser({});

	const biometricsApiService = useGetBiometrics({
		queryParams: { updated: 'false' },
	});
	const biometricsService: EducationContentApiProps = biometricsApiService;
	const { loading, data: biometricsData } = biometricsService;

	const biometricEducationalContent: EducationContentApiProps =
		useGetBiometricContents({
			queryParams: { biometric: BiometricContents[chartId] },
		});
	const { data: biometricEducationalContentData } = biometricEducationalContent;

	const { logScreenView } = useAnalytics();
	useEffect(() => {
		logScreenView?.();
	}, [logScreenView]);

	const biometricsDataStore = useSelector(
		(state: BiometricsType) => state.biometrics
	);
	const openChart = () => setIsChartOpen(true);

	const handleOpenChart = (data: ChartData) => {
		openChart();
		dispatch.chartData.update(data.chartData);
	};

	const isMobile = useMediaQuery({ query: device.mobileL });
	const isTablet = useMediaQuery({ query: device.tablet });

	const isMobileOrTablet = isMobile || isTablet;

	const getUser = useCallback(() => {
		let userRes = { ...userState };
		if (user) userRes = { ...user };

		dispatch.user.updateUser(userRes);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (user) {
			getUser();
		}

		if (biometricsData) {
			const payload: BiometricsPayload = {};
			for (const biometric in biometricsData) {
				if (biometricsData[biometric] === null) continue;
				payload[biometricsMapping[biometric]] = biometricsData[biometric];
			}
			dispatch.biometrics.update(payload);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUser, biometricsData]);

	const { biometricsHealthData } = useFormatBiometrics(
		activeTab,
		biometricsDataStore
	);

	const detailsPage = (data: ChartDataProps) => {
		history.push('/my-health/biometrics/detail', {
			chartId: data.chartData.chartId,
			activeTabName: data.chartData.activeTabName,
			title: data.chartData.title,
		});
	};

	const displayHealthTrackData = () => {
		return (
			<>
				<Titles
					isMyHealthSection={true}
					mainTitle={BIOMETRIC_MAIN_TITLE}
					subTitle={BIOMETRIC_SUB_TITLE}
					subTitleMore={BIOMETRIC_SUB_TITLE_MORE}
				/>
				{isMobileOrTablet ? (
					<Tabs<BiometricsTabs>
						options={options}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						hasFlexStart={true}
					/>
				) : null}
				<Styled.Content>
					{biometricsData === 'No records found' ? (
						<EmptyStateCard
							text={`No Records Found`}
							subText={``}
							image={manIcon}
						/>
					) : !isMobileOrTablet ? (
						<>
							<Styled.Row>
								<Styled.CardWrapper>
									<ProfileCard openChart={openChart} />
								</Styled.CardWrapper>

								<BodyComposition openChart={openChart} />
							</Styled.Row>
							<Styled.RowBiometricCards>
								<Styled.Container>
									<Styled.Title>{FITNESS_TITLE}</Styled.Title>
									<Styled.BiometricRow>
										{fitnessData.map((item) => (
											<Item
												{...item}
												key={item.name}
												data={biometricsDataStore}
												onClick={() =>
													handleOpenChart({
														chartData: {
															chartId: item.chartId,
															name: item.name,
														},
													})
												}
											/>
										))}
									</Styled.BiometricRow>
								</Styled.Container>
								<Styled.Container>
									<Styled.Title>{HEALTH_TITLE}</Styled.Title>
									<Styled.BiometricRow>
										{healthData.map((item) => (
											<Item
												{...item}
												key={item.name}
												data={biometricsDataStore}
												onClick={() =>
													handleOpenChart({
														chartData: {
															chartId: item.chartId,
															name: item.name,
														},
													})
												}
											/>
										))}
									</Styled.BiometricRow>
								</Styled.Container>
							</Styled.RowBiometricCards>
						</>
					) : biometricsHealthData!.length > 0 ? (
						<Styled.RowBiometricCards>
							<Styled.Container>
								<Styled.BiometricRow>
									{biometricsHealthData!.map((item) => {
										return (
											<Item
												{...item}
												key={item.chartId}
												data={biometricsDataStore}
												onClick={() =>
													detailsPage({
														chartData: {
															chartId: item.chartId,
															activeTabName: activeTabName(activeTab),
															title: item.name,
														},
													})
												}
											/>
										);
									})}
								</Styled.BiometricRow>
							</Styled.Container>
						</Styled.RowBiometricCards>
					) : (
						<Styled.AlignCenter>
							<EmptyStateCard
								text={`No Records Found`}
								subText={``}
								image={manIcon}
							/>
						</Styled.AlignCenter>
					)}
				</Styled.Content>
				{chartMapping[chartId]}
			</>
		);
	};

	const checkIfChartIdIsNotEmpty = () => {
		return chartId === '';
	};

	const handleNavigateBack = () => {
		dispatch.chartData.update({ chartId: '', name: '' });
	};

	const activeTabName = (activeTab: string) => {
		if (activeTab === BODY_COMPOSITION_TAB) {
			return BODY_COMPOSITY;
		} else if (activeTab === FITNESS_TAB) {
			return FITNESS_TITLE;
		} else {
			return HEALTH_TITLE;
		}
	};

	const renderChart = () => {
		return checkIfChartIdIsNotEmpty()
			? displayHealthTrackData()
			: displayOnlyChartOnMobileOrTablet();
	};

	const displayOnlyChartOnMobileOrTablet = () => {
		return (
			<>
				<NavigateBackButton
					label={activeTabName(activeTab)}
					callback={handleNavigateBack}
				/>
				{chartMapping[chartId]}
				{biometricEducationalContentData && (
					<EducationalContent
						about={biometricEducationalContentData?.About__c}
						healthImpact={biometricEducationalContentData?.Health_Impact__c}
						lifeStyle={biometricEducationalContentData?.Lifestyle__c}
						fuel={biometricEducationalContentData?.Fuel__c}
						exercise={biometricEducationalContentData?.Exercise__c}
						title={biometricEducationalContentData?.Name}
					/>
				)}
			</>
		);
	};

	if (isMobileOrTablet) {
		return (
			<PageContainer
				id='healthTracking'
				hasBottomNavigation={hasBottomNavigation}
			>
				{loading ? <HealthTrackingLoader /> : renderChart()}
			</PageContainer>
		);
	}

	return (
		<PageContainer id='healthTracking'>
			{loading ? <HealthTrackingLoader /> : <>{displayHealthTrackData()}</>}
		</PageContainer>
	);
};

export default HealthTracking;
