import React from 'react';

import Dropdown from '../Dropdown/Dropdown';

import {
	SET_PERIOD_FILTER,
	SET_TYPE_FILTER,
} from './context/TestResultsActions';
import { useTestResultsContext } from './context/useTestResultsContext';
import { periodOptions, testTypeOptions } from './TestFiltersConstants';
import TestTags from './TestTags/TestTags';

import {
	TestFiltersContainer,
	TestFiltersFilterContainer,
	TestFiltersItemContainer,
	TestFiltersTitle,
} from './TestFilters.styled';

const TestFilters = () => {
	const { dispatch, testPeriod, testType } = useTestResultsContext();
	return (
		<>
			<TestFiltersContainer>
				<TestFiltersItemContainer>
					<TestFiltersTitle>Test type</TestFiltersTitle>
					<TestFiltersFilterContainer>
						<Dropdown
							isSearchable={true}
							options={testTypeOptions}
							selected={testType}
							onSelectCallback={(option) => {
								dispatch({
									type: SET_TYPE_FILTER,
									payload: option as number | null,
								});
							}}
						/>
					</TestFiltersFilterContainer>
				</TestFiltersItemContainer>
				<TestFiltersItemContainer>
					<TestFiltersTitle>Date</TestFiltersTitle>
					<TestFiltersFilterContainer>
						<Dropdown
							options={periodOptions}
							selected={testPeriod}
							onSelectCallback={(option) => {
								dispatch({ type: SET_PERIOD_FILTER, payload: option });
							}}
						/>
					</TestFiltersFilterContainer>
				</TestFiltersItemContainer>
			</TestFiltersContainer>
			<TestTags />
		</>
	);
};

export default TestFilters;
