import styled from 'styled-components/macro';

import Button from 'components/ButtonTs/Button';
import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const CardWrapper = styled.div`
	flex: 0 0 calc(20% - 20px);
	min-height: 350px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin: 0 0 20px 20px;
	min-width: 200px;
	background: ${colors.gradient};
	cursor: pointer;
	transition: transform 0.15s linear;

	&:hover {
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		transform: translateY(-5px);
	}

	@media ${device.laptopL} {
		flex: 0 0 calc(25% - 20px);
	}

	@media ${device.tablet} {
		flex: 0 0 calc(33.33% - 20px);
	}

	@media ${device.mobileL} {
		flex: 0 1 100%;
	}
`;

export const Card = styled.div`
	background: ${colors.white};
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const GeneralInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	width: 80%;
`;

export const Icon = styled.img`
	height: 54px;
	width: 54px;
`;

export const Title = styled.div`
	font-weight: 600;
	${fontStyles[fontSizes.twenty]};
	margin: 25px 0 10px 0;
	text-align: center;
	word-break: break-word;
`;

export const Package = styled.div`
	text-align: center;
	color: ${colors.darkGrey};
`;

export const UsageContainer = styled.div`
	width: 100%;
`;

export const Divider = styled.div`
	height: 1px;
	background: ${colors.lightGrey};
	margin-bottom: 20px;
`;

export const Usage = styled.div`
	margin-bottom: 10px;
	text-align: center;
	color: ${colors.white};
	word-break: break-word;
`;

export const RefillContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin-bottom: 20px;
`;

export const Refill = styled.div`
	color: ${colors.white};
	text-align: center;
	@media ${device.mobileL} {
		font-size: 13px;
	}
`;

export const PDFButton = styled(Button)`
	color: ${colors.blue3};
	@media ${device.mobileL} {
		font-size: 14px;
	}
`;
