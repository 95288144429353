const messagingState = { channelUrl: '' };

const messaging = {
	state: messagingState,
	reducers: {
		update: (state, payload) => ({
			...state,
			...payload,
		}),
	},
};

export default messaging;
