import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 24px;
`;

export const Contact = styled.span`
	${fontStyles[fontSizes.twelve]};
	color: ${colors.darkGrey};
	margin-top: 8px;
`;

export const Email = styled.a.attrs({
	href: 'mailto:app@cenegenics.com',
})`
	color: ${colors.darkGrey};
	font-weight: 600;
	text-decoration: none;
	&:hover {
		color: ${colors.green};
	}
`;

export const Copyright = styled.span`
	${fontStyles[fontSizes.twelve]};
	color: ${colors.darkGrey};
	text-align: center;
`;

export const Privacy = styled.span`
	${fontStyles[fontSizes.twelve]};
	color: ${colors.darkGrey};
	margin-top: 20px;
`;

export const Link = styled.a.attrs((props) => ({
	href: props.link,
	target: '_blank',
}))`
	color: ${colors.blue3};
	align-items: center;
	font-weight: 600;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`;

export const IconImage = styled.img`
	margin-right: 5px;
`;
