import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import Button from 'components/ButtonTs/Button';
import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	padding-top: 15px;
	height: 100vh;
	margin-bottom: 20px;
`;

export const SubHeading = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;

	@media ${device.mobileM} {
		flex-flow: column wrap;
		flex-direction: column-reverse;
	}
`;

export const GenericMessage = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@media ${device.mobileL} {
		align-items: flex-start;
	}

	@media ${device.mobileM} {
		flex-direction: column;
	}
`;

export const SeeOrders = styled.div`
	display: flex;
	padding-top: 30px;
	justify-content: center;
	align-items: center;
	padding-bottom: 20px;

	@media ${device.mobileL} {
		align-items: center;
		justify-content: center;
	}

	@media ${device.mobileM} {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const OrdersLink = styled(Link)`
	color: ${colors.textBlue};
	border-bottom: 1px solid ${colors.blue};
	font-weight: bold;
	text-decoration: none;
`;

export const Message = styled.p`
	font-weight: 800;
	color: ${colors.navy};
	${fontStyles[fontSizes.eighteen]};
	margin-right: 32px;

	@media ${device.mobileL} {
		margin-bottom: 18px;
	}
`;

export const CardsContainer = styled.div`
	margin-top: 30px;
	margin-left: -10px;
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
`;

export const EmptyContainer = styled.div`
	height: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: ${colors.darkGrey};
	text-align: center;
`;

export const Icon = styled.img``;

export const IconWrapper = styled.div`
	display: flex;
	width: 230px;
	height: 140px;
	border-radius: 13px;
	background: ${colors.softBlue};
	align-items: center;
	justify-content: center;
`;

export const EmptyTitle = styled.div`
	margin: 20px 0;
	${fontStyles[fontSizes.eighteen]}
`;

export const EmptySubtitle = styled.div`
	${fontStyles[fontSizes.fourteen]}
`;

export const SendMessageButton = styled(Button)`
	border-radius: 20px;
	width: 100%;
`;
