import advanced from 'assets/svg/icons/test_result_tags/advanced.svg';
import advancedActive from 'assets/svg/icons/test_result_tags/advanced_active.svg';
import advancedGenetics from 'assets/svg/icons/test_result_tags/advanced_genetic.svg';
import advancedGeneticsActive from 'assets/svg/icons/test_result_tags/advanced_genetic_active.svg';
import cardiovascular from 'assets/svg/icons/test_result_tags/cardiovascular.svg';
import cardiovascularActive from 'assets/svg/icons/test_result_tags/cardiovascular_active.svg';
import cognitive from 'assets/svg/icons/test_result_tags/cognitive.svg';
import cognitiveActive from 'assets/svg/icons/test_result_tags/cognitive_active.svg';
import environmental from 'assets/svg/icons/test_result_tags/environmental.svg';
import environmentalActive from 'assets/svg/icons/test_result_tags/environmental_active.svg';
import foodSens from 'assets/svg/icons/test_result_tags/food_sensitivities.svg';
import foodSensActive from 'assets/svg/icons/test_result_tags/food_sensitivities_active.svg';
import metabolic from 'assets/svg/icons/test_result_tags/metabolic.svg';
import metabolicActive from 'assets/svg/icons/test_result_tags/metabolic_active.svg';
import metabolicAnalysis from 'assets/svg/icons/test_result_tags/metabolic_analysis.svg';
import metabolicAnalysisActive from 'assets/svg/icons/test_result_tags/metabolic_analysis_active.svg';
import muscleBone from 'assets/svg/icons/test_result_tags/muscle_bone.svg';
import muscleBoneActive from 'assets/svg/icons/test_result_tags/muscle_bone_active.svg';
import outsideDiagnostics from 'assets/svg/icons/test_result_tags/outside_diagnostics.svg';
import outsideDiagnosticsActive from 'assets/svg/icons/test_result_tags/outside_diagnostics_active.svg';
import primaryCare from 'assets/svg/icons/test_result_tags/primary_care.svg';
import primaryCareActive from 'assets/svg/icons/test_result_tags/primary_care_active.svg';
import sleep from 'assets/svg/icons/test_result_tags/sleep.svg';
import sleepActive from 'assets/svg/icons/test_result_tags/sleep_active.svg';
import strengthFunctional from 'assets/svg/icons/test_result_tags/strength_functional.svg';
import strengthFunctionalActive from 'assets/svg/icons/test_result_tags/strength_functional_active.svg';
import stressAdrenal from 'assets/svg/icons/test_result_tags/stress_adrenal.svg';
import stressAdrenalActive from 'assets/svg/icons/test_result_tags/stress_adrenal_active.svg';

import { TestFiltersTag, TestTypeToCategory } from './TestFilters.types';

export const periodOptions = [
	{ value: null, title: 'All' },
	{ value: 1, title: 'Last Year' },
	{ value: 2, title: 'Last 2 Years' },
	{ value: 5, title: 'Last 5 Years' },
];

export const testTypeOptions = [
	{ value: null, title: 'All' },
	{ value: 1, title: 'Alletess Medical Laboratory' },
	{ value: 2, title: 'Cell Science Systems' },
	{ value: 3, title: 'CIMT' },
	{ value: 4, title: 'Cleerly' },
	{ value: 5, title: 'Cleveland HeartLab' },
	{ value: 6, title: 'CNS Vital Signs' },
	{ value: 7, title: 'DEXA' },
	{ value: 8, title: "Doctor's Data, inc." },
	{ value: 9, title: 'ECG' },
	{ value: 10, title: 'Exercise Recommendations' },
	{ value: 11, title: 'Food Allergy' },
	{ value: 12, title: 'Genomics' },
	{ value: 13, title: 'Genova Diagnostics' },
	{ value: 14, title: 'Grail' },
	{ value: 15, title: 'Great Plains Laboratory' },
	{ value: 16, title: 'Life Length' },
	{ value: 17, title: 'New Amsterdam Genomics' },
	{ value: 18, title: 'Nutrition Recommendations' },
	{ value: 19, title: 'Other' },
	{ value: 20, title: 'Outside Consult (notes)' },
	{ value: 21, title: 'Outside Radiology' },
	{ value: 22, title: 'PULS' },
	{ value: 23, title: 'Quicksilver Scientific' },
	{ value: 24, title: 'RMR' },
	{ value: 25, title: 'Spectracell' },
	{ value: 26, title: 'Spectracell Laboratories' },
	{ value: 27, title: 'Telomere' },
	{ value: 28, title: 'TruAge' },
	{ value: 29, title: 'TruDiagnostics' },
	{ value: 30, title: 'VibrantAmerica' },
	{ value: 31, title: 'VO2 Max' },
	{ value: 32, title: 'WAVi' },
];

export const tagsList: TestFiltersTag[] = [
	{ id: 3, value: 'Cognitive Health', related: true },
	{ id: 4, value: 'Cardiovascular Health', related: true },
	{ id: 5, value: 'Environmental', related: true },
	{ id: 6, value: 'Food Sensitivities', related: true },
	{ id: 7, value: 'Genetics', related: true },
	{ id: 8, value: 'Gut Health', related: true },
	{ id: 9, value: 'Metabolic Analysis', related: true },
	{ id: 10, value: 'Metabolic Health', related: true },
	{ id: 11, value: 'Muscle & Bone Health', related: true },
	{ id: 12, value: 'Outside diagnostics', related: true },
	{ id: 14, value: 'Screening', related: true },
	{ id: 16, value: 'Stress & Adrenal Health', related: true },
	{ id: 17, value: 'Strength & Functional Health', related: true },
];

export const typeToCategoryMap: TestTypeToCategory = {
	1: [6],
	2: [6],
	3: [4],
	4: [4],
	5: [4],
	6: [3],
	7: [10, 11],
	8: [5, 8, 9],
	9: [4],
	10: [4, 10, 11, 17],
	11: [6],
	12: [7],
	13: [8, 9],
	14: [14],
	15: [5, 6, 9],
	16: [7],
	17: [7],
	18: [10, 11],
	19: [],
	20: [12],
	21: [12],
	22: [4, 14],
	23: [9],
	24: [10, 11],
	25: [10, 11],
	26: [9],
	27: [7],
	28: [7, 16],
	29: [7, 16],
	30: [8, 9, 14, 16],
	31: [4, 16],
	32: [3],
};

export const tagsListIcons = [
	{ id: 1, icon: advanced },
	{ id: 2, icon: advancedGenetics },
	{ id: 3, icon: cognitive },
	{ id: 4, icon: cardiovascular },
	{ id: 5, icon: environmental },
	{ id: 6, icon: foodSens },
	{ id: 7, icon: advancedGenetics },
	{ id: 8, icon: metabolic },
	{ id: 9, icon: metabolicAnalysis },
	{ id: 10, icon: metabolic },
	{ id: 11, icon: muscleBone },
	{ id: 12, icon: outsideDiagnostics },
	{ id: 13, icon: primaryCare },
	{ id: 14, icon: advanced },
	{ id: 15, icon: sleep },
	{ id: 16, icon: stressAdrenal },
	{ id: 17, icon: strengthFunctional },
];

export const tagsListIconsActive = [
	{ id: 1, icon: advancedActive },
	{ id: 2, icon: advancedGeneticsActive },
	{ id: 3, icon: cognitiveActive },
	{ id: 4, icon: cardiovascularActive },
	{ id: 5, icon: environmentalActive },
	{ id: 6, icon: foodSensActive },
	{ id: 7, icon: advancedGeneticsActive },
	{ id: 8, icon: metabolicActive },
	{ id: 9, icon: metabolicAnalysisActive },
	{ id: 10, icon: metabolicActive },
	{ id: 11, icon: muscleBoneActive },
	{ id: 12, icon: outsideDiagnosticsActive },
	{ id: 13, icon: primaryCareActive },
	{ id: 14, icon: advancedActive },
	{ id: 15, icon: sleepActive },
	{ id: 16, icon: stressAdrenalActive },
	{ id: 17, icon: strengthFunctionalActive },
];
