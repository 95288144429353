export const buttonPadding = {
	smallAndIcon: '7px 14px',
	icon: '10px 30px 10px 16px',
	small: '5px 10px',
	default: '10px 30px',
};

export const buttonFontSize = {
	small: '12px',
	medium: '16px',
	large: '18px',
};

export const buttonLineHeight = {
	small: '16px',
	medium: '20px',
	large: '24px',
};

export const buttonBorderRadius = {
	rounded: {
		min: '5px',
		max: '6px',
	},
	circle: '39px',
};

export const buttonFontWeight = {
	default: 'normal',
	xxl: 800,
};

export const setButtonPadding = (icon, small) => {
	if (small && icon) {
		return buttonPadding.smallAndIcon;
	} else if (icon) {
		return buttonPadding.icon;
	} else if (small) {
		return buttonPadding.small;
	} else {
		return buttonPadding.default;
	}
};

export const setButtonFontSize = (variant, small) => {
	if (variant === 'secondary') {
		return buttonFontSize.large;
	} else if (small) {
		return buttonFontSize.small;
	} else {
		return buttonFontSize.medium;
	}
};

export const setButtonLineHeight = (variant, small) => {
	if (variant === 'secondary') {
		return buttonLineHeight.large;
	} else if (small) {
		return buttonLineHeight.small;
	} else {
		return buttonLineHeight.medium;
	}
};
