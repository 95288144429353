import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Row = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const DateContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 20px;

	@media ${device.tablet} {
		display: none;
	}
`;

export const ChartDate = styled.div`
	text-align: center;
	font-weight: 800;
	color: ${colors.navy};
	font-size: 14px;
`;

export const ChartPointer = styled.div`
	width: 13px !important;
	height: 13px;
	border-radius: 100px;
	border: 3px solid ${colors.green};
	margin: 0 5px;
`;

export const ColorsList = styled.div`
	width: 70%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;
	overflow: hidden;
	margin-top: 20px;

	@media ${device.tablet} {
		width: 100%;
	}

	@media ${device.mobileL} {
		justify-content: center;
	}
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	padding: 4px;
`;

export const RefName = styled.p`
	margin-left: 5px;
	font-weight: 800;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;

	@media ${device.tablet} {
		${fontStyles[fontSizes.twelve]};
	}
`;

export const Circle = styled.div`
	min-width: 14px;
	min-height: 14px;
	border-radius: 100px;
	background: ${({ background }) => background || 'transparent'};

	@media ${device.tablet} {
		min-width: 11px;
		min-height: 11px;
	}
`;
