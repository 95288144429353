export enum Routes {
	root = '/',
	login = '/login',
	forgot_password = '/forgot-password',
	forgot_password_success = '/forgot-password-success',
	registration_success = '/registration-success',
	biometric_detail = '/biometrics/detail',
	biometrics = '/biometrics',
	biomarkers_detail = '/biomarkers/detail',
	biomarkers = '/biomarkers',
	diagnostics = '/diagnostics',
	products = '/products',
	products_file = '/products-view-file',
	profile = '/profile',
	orders = '/orders',
	order_invoice_file = '/order-invoice-view-file',
	messaging = '/messaging',
	privacy_policy = '/privacy-policy',
}
