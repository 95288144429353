import React, { useState, useEffect } from 'react';

import { useGetFatMass } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';
import { chartsKey } from 'utils/constants';

import { getGradientBackground } from './utils/chartColorizing';
import {
	defaultChartAxisValues,
	handleChartData,
	getChartMaxValue,
	increaseOrDecreaseMaxYThreshold,
} from './utils/chartHelpers';
import { defaultConfig, styledChartElements } from './utils/customViewChart';

const FatMass = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [chartConfig, setChartConfig] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { data: fatMassData, loading } = useGetFatMass();
	const isDataAvailable = fatMassData?.data && fatMassData.data.length > 0;

	useEffect(() => {
		if (isDataAvailable) {
			const dataAdapted = handleChartData(fatMassData.data);

			setData(dataAdapted);
			setLabelsData(dataAdapted?.labels);
			handleChartConfig();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fatMassData]);

	const handleChartConfig = () => {
		const minY = defaultChartAxisValues[chartsKey.fatMass].minY;
		let maxY = defaultChartAxisValues[chartsKey.fatMass].maxY;

		const maxValueOfY = getChartMaxValue(fatMassData.data, 'value');

		if (maxValueOfY && maxValueOfY >= maxY) {
			maxY = maxValueOfY + increaseOrDecreaseMaxYThreshold.large;
		}

		const config = {
			...defaultConfig,
			elements: styledChartElements(fatMassData.data.length - 1),
			scales: {
				...defaultConfig.scales,
				y: {
					...defaultConfig.scales.y,
					min: minY,
					max: maxY,
				},
			},
		};

		setChartConfig(config);
	};

	const getChartData = (canvas) => {
		const gradient = getGradientBackground(canvas);

		return {
			...data,
			datasets: [
				{
					data: data?.datasets[0]?.data,
					fill: true,
					backgroundColor: gradient,
				},
			],
		};
	};

	return (
		<ChartModal
			loading={loading}
			data={getChartData}
			labelsData={labelsData}
			closeChart={closeChart}
			chartConfig={chartConfig}
			isOpen={isOpen}
		/>
	);
};

export default FatMass;
