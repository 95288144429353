import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

export const TestTagsContainer = styled.div`
	display: flex;
	gap: 10px;
	margin-top: 10px;
	overflow-x: scroll;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	&::-webkit-scrollbar {
		/* for Chrome */
		display: none;
	}
`;

export const TestTagsItem = styled.div<{
	active?: boolean;
	disabled?: boolean;
}>`
  display: flex;
  background: ${colors.white};
  border: 1px solid
  ${({ active, disabled }) => {
		if (disabled) {
			return `${colors.lightGrey}`;
		}
		return active ? `${colors.blue}` : `${colors.grey}`;
	}};
  border-radius: 28px;
  font-weight: ${({ active }) => (active ? 900 : 400)};
  font-size: 16px;
  line-height: 22px;
  color: ${({ active, disabled }) => {
		if (disabled) {
			return `${colors.grey}`;
		}
		return active ? `${colors.blue}` : `${colors.black}`;
	}};
  padding: 7px 13px;
  cursor: ${({ disabled }) => {
		return disabled ? 'not-allowed' : 'pointer';
	}};
  align-items: center;
  gap: 10px;
  img {
    filter: ${({ disabled }) => {
			return disabled ? 'opacity(0.3)' : 'none';
		}};
  }
}
`;

export const TestTagsItemTitle = styled.span`
	white-space: nowrap;
`;
