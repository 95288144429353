import React, { useCallback } from 'react';

import { AiOutlineBarChart } from 'react-icons/ai';
import { BsClipboardData } from 'react-icons/bs';
import { LuMicroscope } from 'react-icons/lu';
import { MdOutlineMedication } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import PageContainer from 'components/PageContainer/PageContainer';
import Titles from 'components/Titles/Titles';
import useUniversalContext from 'hooks/useUniversalContext';
import useWindowDimensions from 'hooks/useWIndowDimensions';
import Biomarkers from 'pages/Biomarkers/Biomarkers';
import HealthTracking from 'pages/HealthTracking/HealthTracking';
import MyProducts from 'pages/MyProducts/MyProducts';
import TestResults from 'pages/TestResults/TestResults';
import { device } from 'styles/device';
import {
	MY_REGIMEN_TITLE,
	MY_REGIMENT_SUBTITLE,
} from 'utils/internationalization/en';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ScreenContent = styled.div`
	display: flex;
	flex-direction: column;
	// background-color: aqua;
`;

export const TitleContainer = styled.div`
	display: flex;
	padding: 16px 28px 0px;
`;

export const MenuContainer = styled.section`
	display: flex;
	padding: 0 28px;
	justify-content: space-between;
`;

export const SubHeading = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;

	@media ${device.mobileM} {
		flex-flow: column wrap;
		flex-direction: column-reverse;
	}
`;

export const MyHealthButton = styled.div`
	display: flex;
	padding: 10px 0px 2px 0px;

	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: transparent;
	outline: none;
`;

export const ButtonLabel = styled.p<{
	active: boolean;
}>`
	font-family: 'Avenir';
	font-style: normal;
	font-weight: ${({ active }) => (active ? '800' : '')};
	font-size: 13px;
	padding: 6px 0px 4px 0px;
`;

export const Icon = styled.img`
	width: 26px;
	height: 26px;
`;

interface MenuItemProps {
	label: string;
	onClick: () => void;
	isActive: boolean;
	children: React.ReactNode;
}

const MenuItem = ({ label, onClick, children, isActive }: MenuItemProps) => {
	return (
		<MyHealthButton aria-label={label} onClick={onClick}>
			{children}
			<ButtonLabel active={isActive}>{label}</ButtonLabel>
		</MyHealthButton>
	);
};

const MyRegimenSection = ({
	hasBottomNavigation,
}: {
	hasBottomNavigation?: boolean;
}) => {
	return (
		<PageContainer hasBottomNavigation={hasBottomNavigation}>
			<Titles
				isMyHealthSection={true}
				mainTitle={MY_REGIMEN_TITLE}
				subTitle={MY_REGIMENT_SUBTITLE}
			/>
			<MyProducts />
		</PageContainer>
	);
};

const MyHealth = () => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { hasBottomNavigation, isBrowser } = useUniversalContext();
	const { width } = useWindowDimensions();
	let isLargeWidth = width && width > 768;
	const goTo = useCallback(
		(pathname: string) => {
			history.push({
				pathname,
				state: {},
			});
		},
		[history]
	);

	return (
		<Container>
			{(!isLargeWidth || !isBrowser) && (
				<>
					<TitleContainer>
						<Titles mainTitle={'My Health'} subTitle='' />
					</TitleContainer>
					<MenuContainer>
						<MenuItem
							label='Biomarkers'
							onClick={() => goTo('/my-health/biomarkers')}
							isActive={pathname?.includes('biomarkers')}
						>
							<LuMicroscope size={20} />
						</MenuItem>
						<MenuItem
							label='Biometrics'
							onClick={() => goTo('/my-health/biometrics')}
							isActive={pathname?.includes('biometrics')}
						>
							<AiOutlineBarChart size={20} />
						</MenuItem>
						<MenuItem
							label='Reports'
							onClick={() => goTo('/my-health/reports')}
							isActive={pathname?.includes('reports')}
						>
							<BsClipboardData size={20} />
						</MenuItem>
						<MenuItem
							label='Regimen'
							onClick={() => goTo('/my-health/regimen')}
							isActive={pathname?.includes('regimen')}
						>
							<MdOutlineMedication size={20} />
						</MenuItem>
					</MenuContainer>
				</>
			)}

			<ScreenContent>
				{pathname?.includes('biomarkers') && (
					<Biomarkers hasBottomNavigation={hasBottomNavigation} />
				)}
				{pathname?.includes('biometrics') && (
					<HealthTracking hasBottomNavigation={hasBottomNavigation} />
				)}
				{pathname?.includes('reports') && (
					<TestResults hasBottomNavigation={hasBottomNavigation} />
				)}
				{pathname?.includes('regimen') && (
					<MyRegimenSection hasBottomNavigation={hasBottomNavigation} />
				)}
			</ScreenContent>
		</Container>
	);
};

export default MyHealth;
