import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { SendBirdProvider } from 'sendbird-uikit';

import { useGetUser, useGetAvatar, useGetMyCareTeam } from 'api/services';
import avatarImg from 'assets/svg/icons/user-avatar.svg';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import useAnalytics from 'hooks/useAnalytics';
import useUniversalContext from 'hooks/useUniversalContext';
import {
	adaptTeamData,
	getMembersIds,
	removeDuplicatedMembers,
} from 'pages/MyCareTeam/MyCareTeam.helper';
import { colors } from 'styles/colors';
import { size } from 'styles/device';
import { AnalyticsMethod } from 'utils/constants';
import { isMessagingExcludeCenter, toAgeGroup } from 'utils/helpers';
import { DELETE_MODAL_TITLE } from 'utils/internationalization/en';

import * as Styled from './MainLayout.styled';

const deleteByValue = (object) => {
	for (let key in object) {
		if (!object[key].id) delete object[key];
	}
	return object;
};

const myColorSet = {
	'--sendbird-light-primary-400': colors.chatPurple,
	'--sendbird-light-primary-300': colors.grey30,
	'--sendbird-light-primary-200': colors.lightGrey,
	'--sendbird-light-primary-100': colors.gradient,
};

const MainLayout = ({ children }) => {
	const isResponsive = useMediaQuery({ maxWidth: size.laptop });
	const userState = useSelector((state) => state.user);
	const { accessToken, userId, allowMessaging } = userState;
	const [stringSet] = useState({
		MODAL__DELETE_MESSAGE__TITLE: DELETE_MODAL_TITLE,
	});

	const dispatch = useDispatch();
	const { data: userResponse } = useGetUser();
	const { logAnalytics } = useAnalytics();
	const { hasBottomNavigation } = useUniversalContext();

	const { data: avatarImage } = useGetAvatar({
		resolve: (data) => {
			return data.blob().then((blob) => {
				return URL.createObjectURL(blob);
			});
		},
	});

	const setUser = () => {
		const userData = {
			firstName: userResponse?.first_name || '',
			lastName: userResponse?.last_name || '',
			age: userResponse?.age || 0,
			weight: userResponse?.weight || '0 lbs',
			height: userResponse?.height || '0"',
			accessToken: userResponse?.access_token || null,
			userId: userResponse.account_id,
			gender: userResponse.gender || 'n/a',
			program_type: userResponse.program_type || '',
			center: userResponse.center || '',
			allowMessaging:
				userResponse.center?.length &&
				!isMessagingExcludeCenter(userResponse.center)
					? true
					: false,
		};
		try {
			logAnalytics(AnalyticsMethod.setUserProperties, {
				age_group: toAgeGroup(userData.age),
				user_gender: userData.gender,
				program_type: userData.program_type || 'n/a',
				center: userData.center || 'n/a',
			});
			userData.userId?.length &&
				logAnalytics(AnalyticsMethod.setUserId, userData.userId);
		} catch (err) {
			console.error(err);
			Sentry.captureException(err);
		}

		dispatch.user.updateUser(userData || userState);
	};

	useEffect(() => {
		async function fetchAvatarImage() {
			if (avatarImage) {
				await avatarImage
					.then((img) => {
						dispatch.user.uploadPicture(img);
					})
					.catch(() => {
						dispatch.user.uploadPicture(avatarImg);
					});
			}
		}

		fetchAvatarImage();

		if (userResponse) {
			setUser();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [avatarImage, userResponse, userId]);

	const { data, loading, error } = useGetMyCareTeam({});

	useEffect(() => {
		const p = {
			data: data,
			loading: loading,
			error: error,
		};

		if (data) {
			const team = deleteByValue(data);
			const teamKeys = Object.keys(team);
			const teamAdapted = adaptTeamData(teamKeys, team);
			const ids = getMembersIds(
				teamAdapted.sort((a, b) => a.sort_key - b.sort_key)
			);
			p.data = removeDuplicatedMembers(teamAdapted, ids);
		}

		dispatch.user.setTeam(p);
	}, [data, loading, dispatch.user, error]);

	return (
		<Styled.Layout>
			<ErrorBoundary>{!isResponsive && <Sidebar />}</ErrorBoundary>

			<Styled.Content>
				<SendBirdProvider
					userId={userId}
					accessToken={accessToken}
					appId={process.env.REACT_APP_ID_SENDBIRD}
					colorSet={myColorSet}
					stringSet={stringSet}
					config={{ logLevel: undefined }}
				>
					{!hasBottomNavigation && (
						<ErrorBoundary>
							<Header
								showMessaging={allowMessaging}
								isResponsive={isResponsive}
							/>
						</ErrorBoundary>
					)}

					<ErrorBoundary>
						<Styled.Container hasBottomNavigation={hasBottomNavigation}>
							{children}
						</Styled.Container>
					</ErrorBoundary>
				</SendBirdProvider>
			</Styled.Content>
		</Styled.Layout>
	);
};
export default MainLayout;
