import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

interface ContainerProps {
	isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
	border: 1px solid ${colors.white};
	background-color: ${colors.white};
	max-height: ${({ isOpen }) => (isOpen ? '100%' : '61px')};
	border-radius: 10px;
	margin: 10px 0 0 0;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 10px;
`;

export const Title = styled.p`
	${fontStyles[fontSizes.fifteen]};
	font-weight: 900;
	color: ${colors.black};
	flex-grow: 1;
	font-style: normal;
	font-size: 18px;
	line-height: 25px;
`;

export const Body = styled.div`
	padding: 0 10px 5px;
	font-size: 16px;
	line-height: 25px;
	color: ${colors.darkGrey};

	& > p {
		margin-bottom: 5px;

		& > span {
			display: block;
		}
	}
`;
