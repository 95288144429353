import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const HFCardContainer = styled.div`
	width: 50%;
	border: 1px solid ${colors.lightGrey};
	border-radius: 10px;
	margin: 10px;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const CompositionCardWrapper = styled.div`
	border: 1px solid ${colors.lightGrey};
	margin: 10px;
	border-radius: 10px;
	width: 25%;

	@media ${device.mobileL} {
		width: 100%;
	}

	& > svg {
		// These two props are deliberately separated due to ts-styled-plugin
		@media ${device.mobileL} and (${device.portrait}) {
			width: 100%;
		}
		@media ${device.mobileXL} and (${device.landscape}) {
			width: 100%;
		}
	}
`;

export const HeadingWrapper = styled.div`
	// These two props are deliberately separated due to ts-styled-plugin
	& > svg {
		@media ${device.mobileL} and (${device.portrait}) {
			width: 100%;
		}
		@media ${device.mobileXL} and (${device.landscape}) {
			width: 100%;
		}
	}
`;

export const HeaderWrapper = styled.div`
	margin-bottom: 10px;
`;

export const UserLoaderWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${device.laptop} {
		width: 90%;
	}

	@media ${device.mobileL} {
		width: 100%;
		margin: 10px 0;
	}
`;
