import React from 'react';

import { pull } from 'lodash';

import { SET_TAGS_FILTER } from '../context/TestResultsActions';
import { useTestResultsContext } from '../context/useTestResultsContext';
import { getTestTypeTagsList } from '../TestFilters.helper';

import TestTagIcon from './TestTagIcon';

import {
	TestTagsContainer,
	TestTagsItem,
	TestTagsItemTitle,
} from './TestTags.styled';

const TestTags = () => {
	const { dispatch, testTags, testType } = useTestResultsContext();
	const categoryTagsList = getTestTypeTagsList(testType as number | null);
	return (
		<TestTagsContainer>
			{categoryTagsList.map((tagItem) => {
				const isActive: boolean = testTags.includes(tagItem.value);
				return (
					<TestTagsItem
						onClick={() => {
							if (testType || !tagItem.related) {
								return;
							}
							dispatch({
								type: SET_TAGS_FILTER,
								payload: testTags.includes(tagItem.value)
									? pull(testTags, tagItem.value)
									: [...testTags, tagItem.value],
							});
						}}
						disabled={!tagItem.related}
						active={isActive}
						key={tagItem.id}
					>
						<TestTagIcon tagId={tagItem.id} active={isActive} />
						<TestTagsItemTitle>{tagItem.value}</TestTagsItemTitle>
					</TestTagsItem>
				);
			})}
		</TestTagsContainer>
	);
};

export default TestTags;
