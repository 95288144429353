import React, { VFC } from 'react';

import * as Styled from './ProductCardMain.styled';

interface ProductCardMainProps {
	children?: React.ReactNode;
	description: string;
	getPdfFile: any;
	icon: string;
	title: string;
}

const ProductCardMain: VFC<ProductCardMainProps> = ({
	children,
	description,
	getPdfFile,
	icon,
	title,
}) => {
	return (
		<Styled.CardWrapper onClick={getPdfFile}>
			<Styled.Card data-testid='cardContainer'>
				<Styled.Title>{title}</Styled.Title>
				<Styled.Icon src={icon} alt={title} />
				<Styled.GeneralInfo>
					<Styled.Description>{description}</Styled.Description>
				</Styled.GeneralInfo>
			</Styled.Card>
			{children}
		</Styled.CardWrapper>
	);
};

export default ProductCardMain;
