import React, { useState, useEffect } from 'react';

import { useGetWaistHipRatio } from 'api/services';
import ChartModal from 'components/ChartModal/ChartModal';
import { chartsKey } from 'utils/constants';

import { getChartBgColors } from './utils/chartColorizing';
import {
	handleChartData,
	gradientBgPlugin,
	adaptHipRatioTable,
	renameObjectsProp,
	defaultChartAxisValues,
	increaseOrDecreaseMaxYThreshold,
	getChartMaxValue,
	getChartMinValue,
	strip,
} from './utils/chartHelpers';
import {
	defaultConfig,
	tooltipCallback,
	ticksCallback,
	styledChartElements,
} from './utils/customViewChart';

const WaistHipRatio = ({ closeChart, isOpen }) => {
	const [data, setData] = useState({});
	const [tableData, setTableData] = useState(null);
	const [chartConfig, setChartConfig] = useState(null);
	const [labelsData, setLabelsData] = useState(undefined);

	const { data: hipRatioData, loading } = useGetWaistHipRatio();
	const isDataAvailable = hipRatioData?.data && hipRatioData.data.length > 0;
	const lastElement = isDataAvailable && hipRatioData.data.length - 1;

	useEffect(() => {
		if (isDataAvailable) {
			const refValues = getRefValues(hipRatioData?.referential_values);
			const dataAdapted = handleChartData(hipRatioData.data);
			const lastResultHipRatio = hipRatioData.data[lastElement];
			const tableDataAdapted = adaptHipRatioTable(
				hipRatioData?.referential_values,
				lastResultHipRatio
			);

			setData(dataAdapted);
			setTableData(tableDataAdapted);
			setLabelsData(dataAdapted?.labels);
			handleChartConfig(refValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hipRatioData]);

	const getRefValues = (data) =>
		(data && data.length > 0 && renameObjectsProp(data, 'value', 'risk')) ||
		null;

	const handleChartConfig = (refValues) => {
		let minY = defaultChartAxisValues[chartsKey.waistHipRatio].minY;
		let maxY = defaultChartAxisValues[chartsKey.waistHipRatio].maxY;

		const minValueOfY = getChartMinValue(hipRatioData.data, 'value');
		const maxValueOfY = getChartMaxValue(hipRatioData.data, 'value');

		if (maxValueOfY && maxValueOfY >= maxY) {
			maxY = strip(maxValueOfY + increaseOrDecreaseMaxYThreshold.xs, 2);
		}

		if (minValueOfY && minValueOfY <= minY) {
			minY = strip(minValueOfY - increaseOrDecreaseMaxYThreshold.xs, 1);
		}
		const chartColors =
			(refValues && getChartBgColors(refValues, maxY, minY)) || [];

		const config = {
			...defaultConfig,
			plugins: {
				...defaultConfig.plugins,
				backgrounds: {
					hbars: chartColors,
				},
				tooltip: {
					...defaultConfig.plugins.tooltip,
					callbacks: { label: tooltipCallback },
				},
			},
			elements: styledChartElements(lastElement),
			scales: {
				...defaultConfig.scales,
				y: {
					...defaultConfig.scales.y,
					min: minY,
					max: maxY,
					ticks: {
						...defaultConfig.scales.y.ticks,
						callback: ticksCallback,
					},
				},
			},
		};

		setChartConfig(config);
	};

	return (
		<ChartModal
			data={data}
			loading={loading}
			tableData={tableData}
			labelsData={labelsData}
			closeChart={closeChart}
			chartConfig={chartConfig}
			chartPlugins={gradientBgPlugin}
			isOpen={isOpen}
		/>
	);
};

export default WaistHipRatio;
