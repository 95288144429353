import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import NotFound from 'pages/NotFound/NotFound';

const RouteGuard = () => {
	const { token } = useSelector((state) => state.user);
	return token ? <NotFound /> : <Redirect to='/login' />;
};

export default RouteGuard;
