import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	flex: 0 0 49%;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	background: ${colors.gradient};
	border-radius: 10px;
	max-width: 49%;
	cursor: pointer;
	transition: transform 0.15s linear;
	@media ${device.mobileM},
		${device.mobileL},
		${device.mobileXL} and (${device.mobileS}) {
		margin-bottom: 16px;
	}
	&:hover {
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		transform: translateY(-5px);
	}

	@media ${device.mobileL} {
		flex: 0 0 100%;
		max-width: 100%;

		:first-child {
			margin-bottom: 16px;
		}
	}
`;

export const Content = styled.div`
	background: ${colors.white};
	border-radius: 10px;
	margin: auto 1px 1px;
	padding: 10px 10px;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	overflow: hidden;
	align-items: center;
`;

export const Measurement = styled.div`
	${fontStyles[fontSizes.fourty]};
	padding-right: 10px;
	color: ${colors.black};
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
	font-weight: 600;

	@media ${device.laptopL} {
		font-size: 32px;
	}

	@media ${device.tablet} {
		font-size: 35px;
		padding-right: 5px;
	}

	// prettier-ignore
	@media ${device.mobileXL} and (${device.landscape}), ${device.mobileXL} and (${device.portrait}) {
		font-size: 50px;
		padding-right: 10px;
	}

	@media ${device.mobileM} and (${device.portrait}) {
		font-size: 30px;
	}
`;

export const ItemHeader = styled.div`
	margin: 7px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ItemTitle = styled.div`
	${fontStyles[fontSizes.fourteen]};
	color: ${colors.white};
	display: flex;
	align-items: center;

	// 30px is not random, it's
	// 23px which is image width
	// and 7px is gap between text
	max-width: calc(100% - 30px);

	> button {
		margin-left: 10px;
	}
`;

export const ItemTitleText = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
`;

export const Units = styled.div`
	font-size: 20px;
	color: ${colors.black};
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;

	@media ${device.laptopL} {
		font-size: 16px;
	}

	@media ${device.tablet} {
		font-size: 18px;
	}

	@media ${device.mobileXL} {
		font-size: 24px;
	}
`;

export const Value = styled.div`
	display: inline-flex;
	align-items: baseline;
`;

export const Button = styled.button`
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
`;
