import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

import { AvailableReferenceValues } from '../BiomarkerCard.helper';

interface RangeProps {
	min?: number;
	size?: number;
}

interface DotProps {
	isOutOfRange?: boolean;
	rangeColor?: number;
}
interface SingleRangeValueProps {
	position: 'high' | 'low';
}

const DOT_RADIUS = 5;

export const Container = styled.div`
	position: relative;
	width: 99%;
	height: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	height: 9px;
	background-color: #d9e5e9;
	z-index: 0;
`;

export const NormalRange = styled.div<RangeProps>`
	position: relative;
	height: 9px;
	z-index: 2;
	border-radius: 3px;
	background-color: #6fcf97;
	width: ${({ size }) => size}%;
`;

export const PercentNormalRange = styled.div<RangeProps>`
	position: absolute;
	height: 9px;
	z-index: 2;
	border-radius: 3px;
	background-color: #6fcf97;
	width: ${({ size }) => size}%;
	left: 0;
`;

export const BlackDot = styled.div<RangeProps>`
	position: absolute;
	border-radius: 50px;
	height: 10px;
	width: 2px;
	z-index: 2;
	left: ${({ min }) => min}%;
`;

export const BlackDotInner = styled.div<DotProps>`
	position: absolute;
	border-radius: 50px;
	height: 10px;
	background-color: ${({ isOutOfRange }) =>
		isOutOfRange ? colors.yellow70 : colors.black};
	border: 2px solid ${colors.white};
	width: ${DOT_RADIUS * 2}px;
	z-index: 2;
	left: -${DOT_RADIUS}px;
`;

export const GreenDot = styled.div<RangeProps>`
	position: absolute;
	border-radius: 50px;
	height: 10px;
	width: 2px;
	z-index: 2;
	left: ${({ min }) => min}%;
`;

export const GreenDotInner = styled.div<DotProps>`
	position: absolute;
	border-radius: 50px;
	height: 10px;
	background-color: ${({ isOutOfRange }) =>
		isOutOfRange ? colors.yellow70 : colors.black};
	border: 2px solid ${colors.white};
	width: ${DOT_RADIUS * 2}px;
	z-index: 2;
	left: -${DOT_RADIUS}px;
`;

export const SingleRangeContainer = styled.div<SingleRangeValueProps>`
	position: absolute;
	width: 10%;
	height: 9px;
	left: ${({ position }) => (position === 'high' ? 65 : 35)}%;
`;

export const SingleRangeInnerContainer = styled.div`
	display: flex;
	background-color: white;
	width: 5%;
`;
export const LeftBorder = styled.div<{
	rangeLeft?: boolean;
	rangeRight?: boolean;
}>`
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	background-color: ${({ rangeLeft }) =>
		rangeLeft ? colors.graphPointGreen : colors.lightGrey};
	width: 64%;
	height: 9px;
`;
export const RightBorder = styled.div`
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: #d9e5e9;
	width: 30%;
	height: 9px;
`;
export const MidPoint = styled.div`
	background-color: #a7b0bf;
	height: 9px;
	width: 5px;
	border-radius: 20px;
	margin: 0 5px;
`;

export const SingleRangeLeftBar = styled.div<{
	rangeLeft?: boolean;
	rangeRight?: boolean;
	barColor: string;
	width: number;
}>`
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: ${({ barColor }) =>
		barColor === AvailableReferenceValues.HIGH_ONLY
			? colors.graphPointGreen
			: colors.lightgrey1};
	width: ${({ width }) => width}%;
	height: 9px;
`;
export const SingleRangeRightBar = styled.div<{
	barColor: string;
	width: number;
}>`
	background-color: ${({ barColor }) =>
		barColor === AvailableReferenceValues.LOW_ONLY
			? colors.graphPointGreen
			: colors.lightgrey1};
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	width: ${({ width }) => width}%;
	height: 9px;
`;

export const MINMAX = styled.div<{ position: number }>`
	position: absolute;
	bottom: 200%;
	color: ${colors.crayola};
	right: ${({ position }) => position}%;
	height: 9px;
`;

export const MIN = styled.div`
	position: absolute;
	bottom: 200%;
	color: ${colors.crayola};
	left: 18%;
	height: 9px;
`;

export const MAX = styled.div<{ position: number }>`
	position: absolute;
	bottom: 200%;
	color: ${colors.crayola};
	right: 18%;
	height: 9px;
`;

export const MINMAXContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

export const SpaceBetween = styled.div<{ width: number }>`
	width: ${({ width }) => width}%;
`;
