import { useCallback } from 'react';

import { useLocation } from 'react-router';

import { AnalyticsMethod } from 'utils/constants';

import useUniversalContext from './useUniversalContext';

const useAnalytics = () => {
	const { isBrowser } = useUniversalContext();
	const { pathname } = useLocation();

	const logAnalytics = useCallback(
		(
			methodName: string,
			params: string | object,
			params2?: string | object
		) => {
			if (!isBrowser && methodName) {
				(window as any)?.ReactNativeWebView?.postMessage(
					JSON.stringify({
						analytics: {
							methodName,
							params,
							params2,
						},
					})
				);
			}
		},
		[isBrowser]
	);

	const logScreenView = useCallback(
		(name?: string) => {
			if (logAnalytics && pathname) {
				logAnalytics(AnalyticsMethod.logScreenView, {
					screen_name: (name || pathname).replace('/', ''),
				});
			}
		},
		[pathname, logAnalytics]
	);

	return {
		logAnalytics,
		logScreenView,
	};
};
export default useAnalytics;
