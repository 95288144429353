import React from 'react';

import ContentLoader from 'react-content-loader';

import * as Styled from './BiomarkerLoader.styled';

const Loader = () => (
	<ContentLoader
		height={'100%'}
		width={`100%`}
		speed={1}
		viewBox='0 0 340 115'
		backgroundColor='#D9E5E9'
		foregroundColor='#ecebeb'
	>
		<rect x='11' y='50' rx='5' ry='10' width='90' height='25' />
		<rect x='125' y='50' rx='5' ry='10' width='90' height='25' />
		<rect x='240' y='50' rx='5' ry='10' width='90' height='25' />
	</ContentLoader>
);

function AvatarLoader() {
	return (
		<Styled.LoaderContainer>
			<Loader />
		</Styled.LoaderContainer>
	);
}

export default AvatarLoader;
