import React from 'react';

import { useHistory } from 'react-router-dom';

import useUniversalContext from 'hooks/useUniversalContext';

import * as Styled from './LogoutDropdown.styled';

const LogoutDropdown = () => {
	const history = useHistory();
	const { logOut } = useUniversalContext();
	return (
		<Styled.Container>
			<Styled.DropdownItem onClick={() => history.push('/profile')}>
				My Account
			</Styled.DropdownItem>
			<Styled.DropdownItem onClick={logOut}>Log Out</Styled.DropdownItem>
		</Styled.Container>
	);
};

export default LogoutDropdown;
