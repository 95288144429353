import React from 'react';

import Footer from 'components/Footer/Footer';
import Logo from 'components/Logo/Logo';
import Routes from 'components/Routes/Routes';

import * as Styled from './Sidebar.styled';

const Sidebar = () => (
	<Styled.Container>
		<Styled.LogoWrapper>
			<Logo width='220px' height='40px' />
		</Styled.LogoWrapper>

		<Styled.RoutesContainer>
			<Routes />
			<Footer />
		</Styled.RoutesContainer>
	</Styled.Container>
);

export default Sidebar;
