import React from 'react';

import { formatDateDespiteTimeZone } from 'utils/helpers';

import {
	calculateDimensions,
	getAvailableReferenceValues,
	AvailableReferenceValues,
	BiomarkerTestResult,
	getReferenceValuePosition,
	isFirst3CharactersLetters,
	nonNumericaTestResult,
	nonNumericaTestResultRefRange,
} from './BiomarkerCard.helper';

import TestResultScaleWithRange from './TestResultScale/TestResultScaleWithRange';
import TestResultWithSingleRange from './TestResultScale/TestResultWithSingleRange';

import * as Styled from './BiomarkerCard.styled';

const renderTestResultScale = (data: BiomarkerTestResult) => {
	const availableReference = getAvailableReferenceValues(data);
	if (data?.Unit_of_Measure__c?.includes('%')) {
		return (
			<>
				<TestResultScaleWithRange
					rangeSize={parseInt(data?.Result__c)}
					isOutOfRange={false}
					isPercentage={true}
				/>
			</>
		);
	}

	if (availableReference === AvailableReferenceValues.NONE) {
		return <span></span>;
	}

	if (
		availableReference === AvailableReferenceValues.HIGH_ONLY ||
		availableReference === AvailableReferenceValues.LOW_ONLY
	) {
		const refValue =
			availableReference === AvailableReferenceValues.HIGH_ONLY
				? data.Range_high
				: data.Range_Low;

		const resultPosition = getReferenceValuePosition(
			data.Result__c,
			refValue!,
			availableReference
		);

		return (
			<TestResultWithSingleRange
				resultPosition={resultPosition}
				isOutOfRange={false}
				isPercent={false}
				rangeLeft={availableReference}
				rangeRight={availableReference}
				high={data.Range_high}
				low={data.Range_Low}
				result={data.Result__c}
			/>
		);
	}

	const resultDimension = calculateDimensions(
		data.Range_Low!,
		data.Range_high!,
		data.Result__c
	);

	return (
		<TestResultScaleWithRange
			rangeSize={resultDimension?.size!}
			resultPosition={resultDimension?.dotPos!}
			isOutOfRange={resultDimension?.isOutOfRange}
			high={data.Range_high}
			low={data.Range_Low}
		/>
	);
};

interface BiomarkerCardProps {
	testResult: BiomarkerTestResult;
	onClick: (
		testId: string,
		name: string,
		unit: string,
		min?: number,
		max?: number,
		testResultType?: string
	) => void;
}

function BiomarkerCard({ testResult, onClick }: BiomarkerCardProps) {
	const isNonNumericValue = isFirst3CharactersLetters(testResult.Result__c);

	return (
		<Styled.Container
			onClick={() =>
				onClick(
					testResult.Cenegenics_Test_Standards__r.Id,
					testResult.Specific_Test_Name__c || testResult.Display_Name,
					testResult.Unit_of_Measure__c,
					testResult.Range_Low,
					testResult.Range_high,
					testResult.Type
				)
			}
		>
			<Styled.Header>
				<Styled.BiomarkerName>
					{testResult.Display_Name || testResult.Specific_Test_Name__c}
				</Styled.BiomarkerName>
				<Styled.BiomarkerDate>
					{formatDateDespiteTimeZone(
						testResult.Collected_Date_Time__c,
						'MMM dd'
					)}{' '}
					{`>`}
				</Styled.BiomarkerDate>
			</Styled.Header>
			<Styled.Content isNonNumericValue={isNonNumericValue}>
				{isNonNumericValue ? (
					<Styled.NonNumericBiomarkerReading>
						<Styled.NonNumericValue>
							{nonNumericaTestResult(testResult.Result__c)}
						</Styled.NonNumericValue>

						<Styled.NonNumericMeasure>
							{nonNumericaTestResultRefRange(testResult.Result_Range__c)}
						</Styled.NonNumericMeasure>
					</Styled.NonNumericBiomarkerReading>
				) : (
					<Styled.BiomarkerReading>
						<Styled.Value>{testResult.Result__c}</Styled.Value>
						<Styled.Measure>{testResult.Unit_of_Measure__c}</Styled.Measure>
					</Styled.BiomarkerReading>
				)}
			</Styled.Content>
			<Styled.BiomarkerIndicator></Styled.BiomarkerIndicator>
			{renderTestResultScale(testResult)}
		</Styled.Container>
	);
}

export default BiomarkerCard;
