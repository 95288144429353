import React, { useState } from 'react';

import arrowDown from 'assets/svg/icons/arrow_down_white.svg';
import arrowUp from 'assets/svg/icons/arrow_up_white.svg';

import * as Styled from './ProductAccordion.styled';

interface ProductAccordionProps {
	title: string;
	children: React.ReactNode;
}

const ProductAccordion = ({ title, children }: ProductAccordionProps) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<Styled.Container isOpen={isOpen}>
			<Styled.Header onClick={() => setIsOpen(!isOpen)}>
				<Styled.Title>{title}</Styled.Title>
				<img
					src={isOpen ? arrowUp : arrowDown}
					alt='white caret'
					style={{ color: 'white' }}
				/>
			</Styled.Header>
			{isOpen && <Styled.Body>{children}</Styled.Body>}
		</Styled.Container>
	);
};

export default ProductAccordion;
