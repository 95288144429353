import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Container = styled.div`
	@media ${device.mobileM || device.mobileS} {
		margin-top: 8px;
	}
	@media ${device.mobileL ||
		device.desktop ||
		device.landscape ||
		device.mobileXL} {
		margin: 8px;
	}
	margin: 5px;
	background-color: white;
`;

export const LoaderContainer = styled.div`
	width: 100%;
	display: flex;
	margin-top: 5px;
	flex-wrap: wrap;
	border-radius: 10px;
	@media ${device.mobileL} {
		flex-direction: column;
	}
`;
