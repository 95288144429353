import React, { useCallback, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RestfulProvider } from 'restful-react';

import useUniversalContext from 'hooks/useUniversalContext';
import Router from 'router';
import { getToken, parseTokenPayload } from 'utils/helpers';
import {
	TOAST_TOKEN_EXPIRED_ERROR_TEXT,
	TOAST_UNAUTHORIZED_ERROR_TEXT,
} from 'utils/internationalization/en';

import GlobalStyle from './styles/globalStyle';

import 'react-toastify/dist/ReactToastify.min.css';

toast.configure();

const App = () => {
	const { logOut } = useUniversalContext();
	const { length, goBack } = useHistory();
	const { key, pathname } = useLocation();
	const dispatch = useDispatch();

	const handleNativeGoBack = useCallback(() => {
		if (
			key &&
			pathname &&
			pathname !== '/' &&
			pathname !== '/registration-success' &&
			pathname !== '/messaging' &&
			length > 2
		) {
			goBack();
		}
	}, [key, pathname, length, goBack]);

	const syncSharedState = useCallback(
		(e) => {
			if (dispatch) {
				dispatch.native.sync(e.detail.stateAsString);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		window.addEventListener('onNativeGoBack', handleNativeGoBack);
		window.addEventListener('onSyncSharedState', syncSharedState);
		return () => {
			window.removeEventListener('onNativeGoBack', handleNativeGoBack);
			window.removeEventListener('onSyncSharedState', syncSharedState);
		};
	}, [handleNativeGoBack, syncSharedState]);

	return (
		<RestfulProvider
			base={`${process.env.REACT_APP_BASE_URL}/api/1.0`}
			requestOptions={() => ({
				headers: { Authorization: 'Bearer ' + getToken() },
			})}
			onResponse={async (response) => {
				const accessToken = getToken();
				if (response.status === 401) {
					let reason = TOAST_UNAUTHORIZED_ERROR_TEXT;
					try {
						const body = await response.json();
						if (body?.message?.includes('expired')) {
							reason = TOAST_TOKEN_EXPIRED_ERROR_TEXT;
						}
						logOut(reason);
						Sentry.captureException(
							new Error('API Request Error: Token Expired'),
							{
								extra: {
									appVersion: window.nativeContext?.appVersion || '',
									...parseTokenPayload(accessToken),
								},
							}
						);
					} catch (err) {
						logOut(reason);
					}
				}
			}}
		>
			<GlobalStyle />
			<Router />
		</RestfulProvider>
	);
};

export default App;
