import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	width: 98%;
	background-color: ${colors.white};
	border: 1px solid ${colors.deepBlue};
	border-radius: 10px;
	padding: 20px;
	margin-top: 12px;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
`;
export const Content = styled.div<{ isNonNumericValue: boolean }>`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${({ isNonNumericValue }) =>
		isNonNumericValue ? '' : '20px'};
	align-items: center;
`;
export const BiomarkerIndicator = styled.div``;
export const BiomarkerName = styled.div`
	font-weight: 900;
	${fontStyles[fontSizes.fourteen]};
	line-height: 19px;
	text-transform: uppercase;

	/* Dark grey */

	color: ${colors.darkGrey};
`;
export const BiomarkerDate = styled.div`
	width: 35%;
	display: flex;
	align-items: flex-start;
	justify-content: end;
	font-weight: 900;
	${fontStyles[fontSizes.fourteen]};
	line-height: 19px;

	/* Dark grey */

	color: ${colors.darkGrey};
`;
export const BiomarkerReading = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
`;
export const NonNumericBiomarkerReading = styled(BiomarkerReading)`
	width: 100%;
	text-transform: none;
`;
export const Value = styled.div`
	font-weight: 800;
	font-size: 34px;
	line-height: 46px;
	color: ${colors.black};
	margin-right: 5px;
`;
export const NonNumericValue = styled(Value)`
	font-size: 30px;
	width: 60%;
	text-transform: none;
	color: ${colors.black};
`;
export const Measure = styled.div`
	font-weight: 800;
	font-size: 20px;
	line-height: 46px;

	/* Dark Grey */

	color: ${colors.darkGrey};
`;

export const NonNumericMeasure = styled(Measure)`
	font-size: 21px;
	display: flex;
	align-items: center;
	color: ${colors.lightGrey};
`;
export const Range = styled.div`
	font-weight: 900;
	${fontStyles[fontSizes.fourteen]};
	line-height: 16px;
	text-align: right;

	color: #a7b0bf;
`;
