import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';

import PageLoader from 'components/PageLoader/PageLoader';
// import useUniversalContext from 'hooks/useUniversalContext';
import MainLayout from 'layouts/MainLayout/MainLayout';

const PrivateRoute = ({ children, ...props }) => {
	const { token: storeToken } = useSelector((state) => state.user);
	const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const loc = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	// TODO: check if we need to logout user manually
	// const { logoutUser } = useUniversalContext();

	const injectedToken = window.nativeContext?.accessToken;

	useEffect(() => {
		if (!injectedToken && !storeToken && isAuthenticated) {
			async function saveTokenToStore() {
				try {
					const accessToken = await getAccessTokenSilently();
					dispatch.biometrics.reset();
					sessionStorage.setItem('token', accessToken);
					dispatch.user.updateUser({ token: accessToken });
				} catch (e) {
					//logoutUser(e.message || 'Error saving token to store');
					Sentry.captureException(new Error(e), {
						extra: {
							appVersion: window.nativeContext?.appVersion || '',
						},
					});
				}
			}
			saveTokenToStore();
		}
	}, [
		isAuthenticated,
		injectedToken,
		storeToken,
		getAccessTokenSilently,
		dispatch,
		history,
	]);

	useEffect(() => {
		if (injectedToken && !storeToken) {
			dispatch.biometrics.reset();
			sessionStorage.setItem('token', injectedToken);
			dispatch.user.updateUser({ token: injectedToken });
		}
	}, [injectedToken, storeToken, dispatch]);

	let isAuthenticating = false;
	if (!storeToken && (injectedToken || isAuthenticated || isLoading)) {
		isAuthenticating = true;
	}

	if (isAuthenticating) {
		return <PageLoader />;
	}

	const toObj = {
		pathname: '/login',
	};

	if (loc.pathname !== '/') {
		toObj.search = '?loc=' + loc.pathname;
	}

	return storeToken ? (
		<MainLayout>
			<Route {...props}> {children} </Route>
		</MainLayout>
	) : (
		<Redirect to={toObj} />
	);
};

export default PrivateRoute;
