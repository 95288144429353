import styled from 'styled-components/macro';

import {
	CommonCardWrapper,
	CommonCard,
	CommonCardGeneralInfo,
	CommonCardIcon,
	CommonCardTitle,
	CommonCardDescription,
	CommonCardDate,
} from '../../CommonCard/CommonCard.styled';

export const CardWrapper = CommonCardWrapper;

export const Card = styled(CommonCard)`
	padding-top: 20px;
`;

export const GeneralInfo = CommonCardGeneralInfo;

export const Icon = CommonCardIcon;

export const Title = CommonCardTitle;

export const Description = CommonCardDescription;

export const Date = CommonCardDate;
