import React from 'react';

import { TestFiltersType } from '../TestFilters.types';

import { TestResultsFiltersActions } from './TestResultsActions';
import { testResultsFiltersInitialState } from './TestResultsReducer';

export type TestFiltersContextType = {
	dispatch: React.Dispatch<TestResultsFiltersActions>;
} & TestFiltersType;

const initialContext: TestFiltersContextType = {
	...testResultsFiltersInitialState,
	dispatch: () => undefined,
};

export const TestResultsContext =
	React.createContext<TestFiltersContextType>(initialContext);
