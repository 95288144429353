import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import AnaerobicThreshold from 'components/Charts/AnaerobicThreshold';
import ArterialThickness from 'components/Charts/ArterialThickness';
import BloodPressure from 'components/Charts/BloodPressure';
import FatMass from 'components/Charts/FatMass';
import MaxWattage from 'components/Charts/MaxWattage';
import MuscleMass from 'components/Charts/MuscleMass';
import PeakHeartRate from 'components/Charts/PeakHeartRate';
import RestingHeartRate from 'components/Charts/RestingHeartRate';
import TotalBodyFat from 'components/Charts/TotalBodyFat';
import UpperTorsoFat from 'components/Charts/UpperTorsoFat';
import VO2Max from 'components/Charts/VO2Max';
import WaistHipRatio from 'components/Charts/WaistHipRatio';
import Weight from 'components/Charts/Weight';
import { chartsKey } from 'utils/constants';

export const ChartComponent = () => {
	const [isChartOpen, setIsChartOpen] = useState(false);
	const dispatch = useDispatch();
	const closeChart = () => {
		setIsChartOpen(false);
		dispatch.chartData.update({ chartId: '', name: '' });
	};

	const chartProps = {
		closeChart: closeChart,
		isOpen: isChartOpen,
	};

	const chartMapping = {
		[chartsKey.anaerobicThreshold]: <AnaerobicThreshold {...chartProps} />,
		[chartsKey.arterialThickness]: <ArterialThickness {...chartProps} />,
		[chartsKey.bloodPressure]: <BloodPressure {...chartProps} />,
		[chartsKey.fatMass]: <FatMass {...chartProps} />,
		[chartsKey.maxWattage]: <MaxWattage {...chartProps} />,
		[chartsKey.muscleMass]: <MuscleMass {...chartProps} />,
		[chartsKey.peakHeartRate]: <PeakHeartRate {...chartProps} />,
		[chartsKey.restingHeartRate]: <RestingHeartRate {...chartProps} />,
		[chartsKey.totalBodyFat]: <TotalBodyFat {...chartProps} />,
		[chartsKey.upperTorsoFat]: <UpperTorsoFat {...chartProps} />,
		[chartsKey.vo2Max]: <VO2Max {...chartProps} />,
		[chartsKey.waistHipRatio]: <WaistHipRatio {...chartProps} />,
		[chartsKey.weight]: <Weight {...chartProps} />,
	};

	return {
		chartMapping,
		isChartOpen,
		setIsChartOpen,
	};
};
