import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 70px;
	right: 32px;
	min-width: 180px;
	background: ${colors.white};
	box-sizing: border-box;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	border: 1px solid ${colors.grey30};
	text-align: left;
`;

export const DropdownItem = styled.a`
	padding: 15px 0 10px 20px;
	cursor: pointer;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.fourteen]};

	:not(:last-child) {
		border-bottom: 1px solid ${colors.grey30};
	}

	&:hover {
		color: ${colors.blue};
	}
`;
