import React from 'react';

import { uniqueId } from 'lodash';
import { useHistory } from 'react-router-dom';

import { TestCard } from '../../TestCard/TestCard';
import { TestResultsArrayProps } from '../TestResultsBlock.types';

export const TestResultsList = ({ testCollection }: TestResultsArrayProps) => {
	const history = useHistory();

	const handlePdf = (
		fileId: string,
		fileType: string,
		title: string,
		date: string,
		document_name: string
	) => {
		history.push({
			pathname: `/my-health/reports/${fileId}`,
			state: {
				fileId,
				fileType,
				title,
				date,
				document_name,
				path: '/diagnostics',
				backButtonLabel: 'Reports',
			},
		});
	};
	return (
		<>
			{testCollection?.map((testItem, idx) => (
				<TestCard
					description={testItem.description}
					getPdfFile={() =>
						handlePdf(
							testItem.document_id,
							testItem.filetype,
							testItem.title,
							testItem.date,
							testItem.document_name
						)
					}
					key={`${testItem.document_id}_${uniqueId()}`}
					title={testItem.title}
					date={testItem.date}
					fileType={testItem.filetype}
					tags={testItem.healthStoreCategories || []}
				/>
			))}
		</>
	);
};
