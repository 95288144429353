import React, { useState } from 'react';

import { uniqueId } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSendbirdStateContext, sendBirdSelectors } from 'sendbird-uikit';

import { useGetMyProducts } from 'api/services';
import boxIcon from 'assets/svg/icons/box.svg';
import { ReactComponent as NetworkError } from 'assets/svg/icons/networkerror.svg';
import sendIcon from 'assets/svg/icons/send.svg';
import Error from 'components/Error/Error';
import ProductCard from 'components/ProductCard/ProductCard';
import MyProductsLoader from 'components/Skeleton/MyProductsLoader/MyProductsLoader';
import Tabs from 'components/Tabs/Tabs';
import useUniversalContext from 'hooks/useUniversalContext';
import {
	ERROR_MYPRODUCTS_ISEMPTY,
	ERROR_FETCHING_MYPRODUCTS,
	NETWORK_ERROR_SUBTITLE,
	SUBTITLE_CONTACT_CENEGENICS_TEAM,
} from 'utils/internationalization/en';
import { handleCreateChannel, cenegenicsID } from 'utils/sendBird';

import { MyProductsTabs, options } from './MyProducts.helper';

import * as Styled from './MyProducts.styled';

const MyProducts = () => {
	const { pushDestination, destinations } = useUniversalContext();
	const context = useSendbirdStateContext();
	const dispatch = useDispatch();
	let { state: locationState }: any = useLocation();

	const { data, loading, error } = useGetMyProducts({});
	const [activeTab, setActiveTab] = useState<MyProductsTabs>(
		locationState?.activeTab || MyProductsTabs.recurring
	);

	const { myCareTeam: teamData } = useSelector((state: any) => state.user);

	const createChannelChat = async () => {
		let teamMemberName = cenegenicsID;
		let teamMemberID = cenegenicsID;

		const serviceCoordinator = teamData.find(
			(elem: any) => elem.key === 'service_coordinator'
		);

		if (serviceCoordinator) {
			teamMemberName = serviceCoordinator.full_name;
			teamMemberID = serviceCoordinator.id;
		}

		const sdk = sendBirdSelectors.getSdk(context);
		const createChannel = sendBirdSelectors.getCreateChannel(context);

		if (sdk && sdk.GroupChannelParams) {
			const channelUrl = await handleCreateChannel(
				sdk,
				createChannel,
				teamMemberName,
				[teamMemberID]
			);

			dispatch.messaging.update({ channelUrl });
			pushDestination(destinations.messaging);
		}
	};

	const checkIfProductsExist = (
		productsData: any,
		activeProductTab: MyProductsTabs
	) => {
		return productsData && productsData[activeProductTab]?.length > 0;
	};

	const checkAndDisplayProducts = (
		dataArgs: any,
		activeTabArgs: MyProductsTabs
	) => {
		return checkIfProductsExist(dataArgs, activeTabArgs) ? (
			displayProducts(dataArgs)
		) : (
			<Styled.EmptyContainer>
				<Styled.IconWrapper>
					<Styled.Icon src={boxIcon} />
				</Styled.IconWrapper>
				<Styled.EmptyTitle>{ERROR_MYPRODUCTS_ISEMPTY}</Styled.EmptyTitle>
				<Styled.EmptySubtitle>
					{SUBTITLE_CONTACT_CENEGENICS_TEAM}
				</Styled.EmptySubtitle>
			</Styled.EmptyContainer>
		);
	};

	const displayProducts = (productsData: any) => {
		return productsData[activeTab]?.map((product: any) => (
			<ProductCard
				key={`${product.document_id}-${uniqueId()}`}
				title={product.title}
				category={product.category}
				packaging={product.package}
				usage={product.usage}
				lastRefill={product.last_refill}
				nextRefill={product.next_refill}
				productId={product.document_id}
			/>
		));
	};

	if (error) {
		return (
			<Error
				Image={NetworkError}
				message={ERROR_FETCHING_MYPRODUCTS}
				subTitleMessage={NETWORK_ERROR_SUBTITLE}
			/>
		);
	}

	return (
		<Styled.Container>
			{loading ? (
				<MyProductsLoader />
			) : (
				<>
					<Styled.SubHeading>
						<Tabs<MyProductsTabs>
							options={options}
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							isOrderTracking
						/>
					</Styled.SubHeading>
					<Styled.CardsContainer>
						{checkAndDisplayProducts(data, activeTab)}
					</Styled.CardsContainer>
					<Styled.GenericMessage>
						<Styled.SendMessageButton
							variant='primary'
							icon={sendIcon}
							id='sendMsgBtn'
							onClick={() => createChannelChat()}
						>
							Need to Change Something?
						</Styled.SendMessageButton>
					</Styled.GenericMessage>
				</>
			)}
		</Styled.Container>
	);
};

export default MyProducts;
