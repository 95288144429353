import { colors } from 'styles/colors';

// Default Green background gradient
export const getGradientBackground = (canvas) => {
	const ctx = canvas.getContext('2d');

	const gradient = ctx.createLinearGradient(0, 200, 0, 0);

	gradient.addColorStop(0, 'rgba(54, 97, 168, 0.15)');
	gradient.addColorStop(1, 'rgba(255, 255, 255, 0.15)');

	return gradient;
};

export const getChartBgColors = (refData, max, from = 0) => {
	return refData.map(({ classification, value }) => {
		let currentRefValues = [];
		let chartRowValue = {
			from: null,
			to: null,
		};

		if (value.includes('<=')) {
			currentRefValues = value.split('<=');

			chartRowValue = {
				from,
				to: parseFloat(currentRefValues[1]),
			};
		} else if (value.includes('>=')) {
			currentRefValues = value.split('>=');

			chartRowValue = {
				from: parseFloat(currentRefValues[1]),
				to: max,
			};
		} else if (value.includes('<')) {
			currentRefValues = value.split('<');

			chartRowValue = {
				from,
				to: parseFloat(currentRefValues[1]),
			};
		} else if (value.includes('>')) {
			currentRefValues = value.split('>');

			chartRowValue = {
				from: parseFloat(currentRefValues[1]),
				to: max,
			};
		} else if (value.includes('-')) {
			currentRefValues = value.split('-');

			chartRowValue = {
				from: parseFloat(currentRefValues[0]),
				to: parseFloat(currentRefValues[1]),
			};
		}

		return {
			...chartRowValue,
			color: getRowColor(classification),
		};
	});
};

const getRowColor = (value) => {
	switch (value) {
		case 'Lean':
		case 'Good':
		case 'Moderate':
			return colors.blue10;
		case 'Optimal':
		case 'Excellent':
		case 'Low':
			return colors.green10;
		case 'Average':
		case 'High':
			return colors.yellow10;
		case 'Moderately Overweight':
		case 'Fair':
			return colors.orange10;
		case 'Overweight':
		case 'Poor':
		case 'Very High':
			return colors.red10;
		default:
			return 'transparent';
	}
};
