import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const Container = styled.button<{
	justifyContent: string;
	padding: number;
}>`
	border: none;
	background: transparent;
	display: inline-flex;
	justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
	cursor: pointer;
	position: relative;
	padding-right: ${({ padding }) => `${padding}px` || '0px'};
	padding-left: ${({ padding }) => `${padding}px` || '0px'};

	&:hover {
		> div {
			visibility: visible;
		}
	}

	@media ${device.laptop} {
		img {
			width: 13px;
			height: 13px;
		}
	}

	@media ${device.mobileL} {
		img {
			width: 24px;
			height: 24px;
		}
	}
`;

export const ToolTipTitle = styled.div`
	font-weight: 900;
	font-size: 16px;
`;

export const ToolTipText = styled.div`
	color: ${colors.darkGrey};
`;
