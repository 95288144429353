import {
	commonRefColors,
	fatBodyReflColors,
	hipRatioRefColors,
} from 'components/Charts/utils/customViewChart';

export const colorsListMapping = {
	vo2Max: commonRefColors,
	totalBodyFat: fatBodyReflColors,
	restingHeartRate: commonRefColors,
	waistHipRatio: hipRatioRefColors,
};
