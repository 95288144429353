import React, { VFC } from 'react';

import { formatDateDespiteTimeZone, getFileSVG, iconType } from 'utils/helpers';

import { TestCardTags } from './TestCardTags/TestCardTags';

import * as Styled from './TestCard.styled';

interface TestCardProps {
	children?: React.ReactNode;
	description: string;
	getPdfFile: any;
	title: string;
	date: string;
	fileType?: string;
	tags?: string[];
}

export const TestCard: VFC<TestCardProps> = ({
	children,
	description,
	getPdfFile,
	title,
	date,
	fileType,
	tags = [],
}) => {
	return (
		<Styled.CardWrapper onClick={getPdfFile}>
			<Styled.TestCard data-testid='cardContainer'>
				<Styled.TestDateAndCategoryContainer>
					<Styled.DateAndFileContainer>
						<img
							src={(fileType && iconType(fileType)) || getFileSVG.pdf}
							alt={fileType}
							style={{ filter: 'grayscale(100%)' }}
							width={25}
							height={25}
						/>
						<Styled.TestDate>
							{date && formatDateDespiteTimeZone(date, 'MMM d, yyyy')}
						</Styled.TestDate>
					</Styled.DateAndFileContainer>
					<TestCardTags tags={tags} />
				</Styled.TestDateAndCategoryContainer>
				<Styled.TestTitle>{title}</Styled.TestTitle>
				<Styled.TestGeneralInfo>
					<Styled.TestDescription>{description}</Styled.TestDescription>
				</Styled.TestGeneralInfo>
			</Styled.TestCard>
			{children}
		</Styled.CardWrapper>
	);
};
