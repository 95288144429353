import React, { FC, useReducer } from 'react';

import { TestFiltersType } from '../TestFilters.types';

import {
	TestFiltersContextType,
	TestResultsContext,
} from './TestResultsContext';
import {
	testResultsFilterReducer,
	testResultsFiltersInitialState,
} from './TestResultsReducer';

export const TestResultsFilterProvider: FC = ({ children }) => {
	const [state, dispatch] = useReducer<any>(
		testResultsFilterReducer,
		testResultsFiltersInitialState
	);

	const value: TestFiltersContextType = {
		...(state as TestFiltersType),
		dispatch,
	};

	return (
		<TestResultsContext.Provider value={value}>
			{children}
		</TestResultsContext.Provider>
	);
};
