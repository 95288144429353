import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';

export const Container = styled.div`
	text-align: center;
	margin: auto;
	height: 50%;
	padding: 10px;
`;

export const Text = styled.p`
	color: ${colors.navy};
	font-size: 18px;
	font-weight: 600;
	line-height: 25px;
	margin: 20px 0 10px;
`;

export const TryAgainButton = styled.div`
	background-color: ${colors.navy};
	color: white;
	font-weight: bold;
	padding: 15px 30px;
	margin: 20px;
	max-width: 150px;
	border-radius: 30px;
	text-decoration: none;
	cursor: pointer;
`;

export const Email = styled.a.attrs({
	href: 'mailto:appo@cenegenics.com',
})`
	color: ${colors.darkGrey};
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;

	&:hover {
		color: ${colors.green};
	}

	@media ${device.mobileL} {
		margin-bottom: 20px;
	}
`;
