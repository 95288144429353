import React from 'react';

import { uniqueId } from 'lodash';
import ContentLoader from 'react-content-loader';

import * as Styled from './TestResultsLoader.styled';

const Loader = (props) => (
	<Styled.Container>
		<ContentLoader
			speed={2}
			width={207}
			height={278}
			viewBox='0 0 207 278'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
			{...props}
		>
			<rect x='66' y='89' rx='38' ry='38' width='75' height='75' />
			<rect x='41' y='34' rx='4' ry='4' width='124' height='26' />
			<rect x='16' y='208' rx='4' ry='4' width='175' height='16' />
			<rect x='41' y='229' rx='4' ry='4' width='125' height='16' />
		</ContentLoader>
	</Styled.Container>
);

const Header = (props) => (
	<Styled.HeaderContainer>
		<ContentLoader
			speed={2}
			width={833}
			height={116}
			viewBox='0 0 833 116'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
			{...props}
		>
			<rect x='0' y='46' rx='4' ry='4' width='526' height='21' />
			<rect x='0' y='95' rx='4' ry='4' width='833' height='21' />
			<rect x='0' y='0' rx='4' ry='4' width='132' height='32' />
		</ContentLoader>
	</Styled.HeaderContainer>
);

const TestResultsLoader = (props) => (
	<>
		<Header />
		<Styled.LoaderContainer>
			{[...Array(8)].map((index) => (
				<Loader key={`${index}_${uniqueId()}`} {...props} />
			))}
		</Styled.LoaderContainer>
	</>
);

export default TestResultsLoader;
