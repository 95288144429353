/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";
export const SPEC_VERSION = "1.18.3"; 
export interface Token {
  token: string;
}

export interface Login {
  email: string;
  password: string;
}

export interface CreateFeatureFlag {[key: string]: any}

export interface CreateFeatureFlagUserGroup {[key: string]: any}

export interface ModifyUserGroup {[key: string]: any}

export interface ModifyFeatureFlag {[key: string]: any}

export interface CreateSendbirdChannel {
  /**
   * Array of Eighteen character Salesforce User Id(s) - Id usually begins with `005`
   */
  care_team_member_sf_id?: {};
  /**
   * Eighteen character Salesforce Patient Id - usually begins with `001`
   */
  patient_sf_id?: {};
}

export interface FreezeSendbirdChannel {
  /**
   * Eighteen character Salesforce Patient Id - usually begins with `001`
   */
  sf_userid?: {};
  /**
   * Boolean for indicating whether to freeze or unfreeze channels
   */
  freeze?: {};
}

export interface CreatePhaReport {
  /**
   * Report Id
   */
  report_id?: {};
  /**
   * Score Id
   */
  score_id?: {};
}

export interface PhaLambdaCallback {
  /**
   * Report Id
   */
  report_id?: {};
  /**
   * Score Id
   */
  score_id?: {};
  /**
   * PHA Report Name as is in the S3 bucket. This value should not contain any of the following characters(/, <, >, :, ', \, |, ?, *, ")
   */
  s3_object_key?: {};
}

export interface BiomarkersFilter {
  period: string;
  biomarker_name: string;
}

export interface Registration {
  email: string;
  given_name: string;
  family_name: string;
  sf_userid: string;
}

export interface ApproveOrder {
  sf_orderid: string;
}

export interface RequestOrderChange {
  sf_orderid: string;
  order_change_type: {};
  request_content: string;
}

export interface UpdateSalesforceAccountState {
  sf_userid: string;
}

export interface Error {
  code: string;
  message: string;
}

export interface ValidationError1 {
  error: {
  code: string;
  message: string;
};
}

export interface AuthError1 {
  error: {
  code: string;
  message: string;
};
}

export interface ConflictError1 {
  error: {
  code: string;
  message: string;
};
}

export interface ConflictError2 {
  error: {
  code: string;
  message: string;
};
}

export interface DependencyError1 {
  error: {
  code: string;
  message: string;
};
}

export interface BiometricError {[key: string]: any}

export interface Email {
  email: string;
}

export interface Message {
  message: string;
}

export interface ChangePassword {
  password: string;
  confirm_password: string;
}

export interface UpdateUser {
  phone?: string;
}

export interface User {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  age?: number;
  gender?: string;
  username: string;
  profile_picture: string;
  access_token?: string;
  physician?: string;
  health_coach?: string;
  service_coordinator?: string;
}

export interface Biometric {
  weight?: number;
  height?: number;
  total_body_fat?: number;
  total_body_fat_indicator?: "green" | "yellow";
  muscle_mass?: number;
  fat_mass?: number;
  upper_torso_fat?: number;
  vo2_max?: number;
  vo2_max_indicator?: "green" | "yellow";
  peak_heart_rate?: number;
  anaerobic_threshold?: number;
  max_wattage?: number;
  resting_heart_rate?: number;
  resting_heart_rate_indicator?: "green" | "yellow";
  blood_pressure_systolic?: number;
  blood_pressure_diastolic?: number;
  blood_pressure_indicator?: "green" | "yellow";
  arterial_thickness?: number;
  waist_hip_ratio?: number;
  waist_hip_ratio_indicator?: "green" | "yellow";
}

export interface BiometricData {
  data?: {
  date?: string;
  value?: number;
}[];
}

export type TotalBodyFat = BiometricData & {
  referential_values?: {
  general?: {
  classification?: "Lean" | "Optimal" | "Average" | "Moderately Overweight" | "Overweight";
  value?: string;
}[];
  athlete?: {
  classification?: "Lean" | "Optimal" | "Average" | "Moderately Overweight" | "Overweight";
  value?: string;
}[];
};
};

export type VO2Max = BiometricData & {
  referential_values?: {
  rating?: "Excellent" | "Good" | "Average" | "Fair" | "Poor";
  value?: string;
}[];
  age_group?: "20-29" | "30-39" | "40-49" | "50-59" | "60+";
};

export interface BloodPressure {
  data?: {
  date?: string;
  systolic?: number;
  diastolic?: number;
}[];
  referential_values?: {
  rating?: "Normal" | "Pre-Hypertension" | "Stage 1 Hypertension" | "Stage 2 Hypertension";
  systolic?: string;
  diastolic?: string;
}[];
}

export type RestingHeartRate = BiometricData & {
  referential_values?: {
  rating?: "Excellent" | "Good" | "Average" | "Fair" | "Poor";
  score?: string;
}[];
};

export type WaistHipRatio = BiometricData & {
  referential_values?: {
  risk?: "Low" | "Moderate" | "High" | "Very High";
  value?: string;
}[];
  age_group?: "20-29" | "30-39" | "40-49" | "50-59" | "60-69";
};

export interface OrderAddress {
  ups_att?: string;
  shipping_street?: string;
  city?: string;
  postal_code?: string;
  country?: string;
}

export interface DeviceNotificationToken {
  device_id?: string;
  device_token: string;
}

/**
 * Unauthenticated
 */
export type UnauthenticatedResponse = Error;

/**
 * Unauthorized
 */
export type UnauthorizedResponse = Error;

/**
 * Unauthorized
 */
export type AuthError1Response = AuthError1;

/**
 * The specified resource was not found
 */
export type NotFoundResponse = Error;

/**
 * Unprocessable Entity
 */
export type UnprocessableEntityResponse = Error;

/**
 * Unprocessable Entity
 */
export type UnprocessableEntityNewResponse = ValidationError1;

/**
 * Dependency Failure
 */
export type DependencyFailureResponse = Error;

/**
 * Dependency Failure
 */
export type DependencyError1Response = DependencyError1;

/**
 * Conflict Error Type 2
 */
export type ConflictError1Response = ConflictError1;

/**
 * Conflict Error Type 2
 */
export type ConflictError2Response = ConflictError2;

/**
 * Login response for successfull login
 */
export type LoginResponseResponse = Token;

/**
 * Forgot password response
 */
export type ForgotPasswordResponseResponse = Message;

/**
 * Update user response
 */
export type UpdateUserResponse = UpdateUser;

/**
 * Get user response
 */
export type GetUserResponseResponse = User;

/**
 * Get user response
 */
export type GetBiometricsResponseResponse = Biometric;

/**
 * Biometric Bad Request Error
 */
export type GetBiometricsErrorResponse = BiometricError;

/**
 * User biometric data response
 */
export type GetBiometricDataResponseResponse = BiometricData;

/**
 * Patient total body fat response
 */
export type GetTotalBodyFatResponseResponse = TotalBodyFat;

/**
 * Patient VO2 max response
 */
export type GetVO2MaxResponseResponse = VO2Max;

/**
 * Patient blood pressure response
 */
export type GetBloodPressureResponseResponse = BloodPressure;

/**
 * Patient resting heart rate response
 */
export type GetRestingHeartRateResponseResponse = RestingHeartRate;

/**
 * Patient waist to hip ratio response
 */
export type GetWaistHipRatioResponseResponse = WaistHipRatio;

/**
 * Get patient's care team response
 */
export interface GetMyCareTeamResponseResponse {
  physician?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  health_coach?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  service_coordinator?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  clinical_physician?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  primary_performance_health_coach?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  patient_experience_coordinator?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  primary_patient_experience_coordinator?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  client_experience_coordinator?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  pharmacy_lab_liaison?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  preferred_phlebotomist?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  secondary_health_coach?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  secondary_patient_experience_coordinator?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  secondary_physician?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
  tertiary_service_coordinator?: {
  id?: string;
  full_name?: string;
  title?: string;
  city?: string;
  member_role?: string;
  sort_key?: number;
  state?: string;
  photo?: string;
  user_in_sendbird?: boolean;
};
}

/**
 * Get patient's test results
 */
export type GetTestResultFileResponseResponse = void;

/**
 * Get patient's test results
 */
export interface GetTestResultsResponseResponse {
  cimt?: {[key: string]: any}[];
  DEXA?: {[key: string]: any}[];
}

/**
 * Get Biomarker Educational Content
 */
export interface GetBiomarkerEducationalContentResponseResponse {
  id?: string;
  Overview__c?: string;
  Prescriptions__c?: string;
  Mitigation_Peak_Fitness_Training__c?: string;
  Mitigation_Nutraceuticals__c?: string;
  Mitigation_Lifestyle__c?: string;
  Mitigation_Fuel__c?: string;
  Health_Impacts__c?: string;
}

export type GetTestResultsFileResponseResponse = GetTestResultsResponseResponse;

/**
 * Get my products
 */
export type GetMyProductsResponseResponse = {
  recurring?: {
  title?: string;
  document_id?: string;
  package?: string;
  usage?: string;
  next_refill?: string;
  last_refill?: string;
  category?: "rx" | "vitamins" | "lightning" | "other";
}[];
  extras?: {
  title?: string;
  document_id?: string;
  package?: string;
  usage?: string;
  next_refill?: string;
  last_refill?: string;
  category?: "rx" | "vitamins" | "lightning" | "other";
}[];
};

/**
 * Get my product file
 */
export type GetMyProductFileResponseResponse = void;

/**
 * Register response for successfully created user
 */
export type RegisterResponseResponse = Token;

/**
 * Response for successful channel creation
 */
export interface CreateSendbirdChannelResponseResponse {
  message?: string;
  staus_code?: number;
}

/**
 * Response for Feature Flags Statuses
 */
export interface FeatureFlagsResponseResponse {
  response?: {
  data?: {[key: string]: any};
};
}

/**
 * Fetch User's PHA Reports
 */
export interface GetMyPHAReportResponseResponse {
  response?: {
  data?: {
  assessment_date?: string;
  report_id?: string;
  score_id?: string;
}[];
};
}

/**
 * Response for user group creation
 */
export interface CreateUserGroupResponseResponse {
  response?: {
  data?: {[key: string]: any};
};
}

/**
 * Response for user group modification
 */
export interface ModifyUserGroupResponseResponse {
  response?: {
  data?: {[key: string]: any};
};
}

/**
 * Response for successful channel creation
 */
export interface FreezeSendbirdChannelResponseResponse {
  response?: {
  data?: {[key: string]: any};
};
}

/**
 * Response for successful initiation of PHA report creation
 */
export interface CreatePHAReportResponseResponse {
  message?: string;
  staus_code?: number;
}

/**
 * Response for successful Salesforce update with the associated S3 URI for PHA report PDF.
 */
export interface PHALambdaCallbackResponseResponse {
  message?: string;
  staus_code?: number;
}

/**
 * Get my orders
 */
export type GetMyOrdersResponseResponse = {
  orders?: {
  order_id?: string;
  shipment_preference?: string;
  prescription_tracking_number?: string;
  patient_requested_change?: boolean;
  order_type?: "Prescriptions" | "Nutraceuticals";
  nutraceuticals_tracking_number?: string;
  formulary_pharmacy?: string;
  estimated_delivery_date?: string;
  actual_date_delivered?: string;
  delivery_status?: boolean;
  order_status?: string;
  shipment_status?: string;
  products?: {
  formulary_pharmacy?: string;
  name?: string;
  tracking_number?: string;
}[];
  order_details?: {
  address?: OrderAddress;
  patient_name?: string;
  order_date?: string;
  physician_name?: string;
};
}[];
  total?: number;
};

/**
 * Get patient's test results file
 */
export type GetOrderInvoiceFileResponseResponse = void;

export interface RootHealthCheckResponse {
  healthy: boolean;
}

export type RootHealthCheckProps = Omit<GetProps<RootHealthCheckResponse, void, void, void>, "path">;

/**
 * Health endpoint
 */
export const RootHealthCheck = (props: RootHealthCheckProps) => (
  <Get<RootHealthCheckResponse, void, void, void>
    path={`/`}
    
    {...props}
  />
);

export type UseRootHealthCheckProps = Omit<UseGetProps<RootHealthCheckResponse, void, void, void>, "path">;

/**
 * Health endpoint
 */
export const useRootHealthCheck = (props: UseRootHealthCheckProps) => useGet<RootHealthCheckResponse, void, void, void>(`/`, props);


export interface HealthCheckResponse {
  healthy: boolean;
}

export type HealthCheckProps = Omit<GetProps<HealthCheckResponse, void, void, void>, "path">;

/**
 * Health endpoint
 */
export const HealthCheck = (props: HealthCheckProps) => (
  <Get<HealthCheckResponse, void, void, void>
    path={`/health_check`}
    
    {...props}
  />
);

export type UseHealthCheckProps = Omit<UseGetProps<HealthCheckResponse, void, void, void>, "path">;

/**
 * Health endpoint
 */
export const useHealthCheck = (props: UseHealthCheckProps) => useGet<HealthCheckResponse, void, void, void>(`/health_check`, props);


export type RedisCheckProps = Omit<GetProps<void, void, void, void>, "path">;

/**
 * Check Redis Connection
 */
export const RedisCheck = (props: RedisCheckProps) => (
  <Get<void, void, void, void>
    path={`/redis_check`}
    
    {...props}
  />
);

export type UseRedisCheckProps = Omit<UseGetProps<void, void, void, void>, "path">;

/**
 * Check Redis Connection
 */
export const useRedisCheck = (props: UseRedisCheckProps) => useGet<void, void, void, void>(`/redis_check`, props);


export type CreateNewFeatureFlagProps = Omit<MutateProps<FeatureFlagsResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, CreateFeatureFlag, void>, "path" | "verb">;

/**
 * Create a new feature flag.
 */
export const CreateNewFeatureFlag = (props: CreateNewFeatureFlagProps) => (
  <Mutate<FeatureFlagsResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, CreateFeatureFlag, void>
    verb="POST"
    path={`/create-feature-flag`}
    
    {...props}
  />
);

export type UseCreateNewFeatureFlagProps = Omit<UseMutateProps<FeatureFlagsResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, CreateFeatureFlag, void>, "path" | "verb">;

/**
 * Create a new feature flag.
 */
export const useCreateNewFeatureFlag = (props: UseCreateNewFeatureFlagProps) => useMutate<FeatureFlagsResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, CreateFeatureFlag, void>("POST", `/create-feature-flag`, props);


export type GetFeatureFlagsProps = Omit<GetProps<FeatureFlagsResponseResponse, void | AuthError1Response | DependencyError1Response, void, void>, "path">;

/**
 * Retrieve global feature flags and their statuses
 */
export const GetFeatureFlags = (props: GetFeatureFlagsProps) => (
  <Get<FeatureFlagsResponseResponse, void | AuthError1Response | DependencyError1Response, void, void>
    path={`/feature-flags`}
    
    {...props}
  />
);

export type UseGetFeatureFlagsProps = Omit<UseGetProps<FeatureFlagsResponseResponse, void | AuthError1Response | DependencyError1Response, void, void>, "path">;

/**
 * Retrieve global feature flags and their statuses
 */
export const useGetFeatureFlags = (props: UseGetFeatureFlagsProps) => useGet<FeatureFlagsResponseResponse, void | AuthError1Response | DependencyError1Response, void, void>(`/feature-flags`, props);


export type ModifyFeatureFlagsProps = Omit<MutateProps<FeatureFlagsResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, ModifyFeatureFlag, void>, "path" | "verb">;

/**
 * Modify Global Feature Flags
 */
export const ModifyFeatureFlags = (props: ModifyFeatureFlagsProps) => (
  <Mutate<FeatureFlagsResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, ModifyFeatureFlag, void>
    verb="PUT"
    path={`/modify-feature-flags`}
    
    {...props}
  />
);

export type UseModifyFeatureFlagsProps = Omit<UseMutateProps<FeatureFlagsResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, ModifyFeatureFlag, void>, "path" | "verb">;

/**
 * Modify Global Feature Flags
 */
export const useModifyFeatureFlags = (props: UseModifyFeatureFlagsProps) => useMutate<FeatureFlagsResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, ModifyFeatureFlag, void>("PUT", `/modify-feature-flags`, props);


export type CreateFeatureFlagsUserGroupProps = Omit<MutateProps<CreateUserGroupResponseResponse, void | AuthError1Response | ConflictError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, CreateFeatureFlagUserGroup, void>, "path" | "verb">;

/**
 * Create a new feature flag user group.
 */
export const CreateFeatureFlagsUserGroup = (props: CreateFeatureFlagsUserGroupProps) => (
  <Mutate<CreateUserGroupResponseResponse, void | AuthError1Response | ConflictError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, CreateFeatureFlagUserGroup, void>
    verb="POST"
    path={`/create-user-group`}
    
    {...props}
  />
);

export type UseCreateFeatureFlagsUserGroupProps = Omit<UseMutateProps<CreateUserGroupResponseResponse, void | AuthError1Response | ConflictError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, CreateFeatureFlagUserGroup, void>, "path" | "verb">;

/**
 * Create a new feature flag user group.
 */
export const useCreateFeatureFlagsUserGroup = (props: UseCreateFeatureFlagsUserGroupProps) => useMutate<CreateUserGroupResponseResponse, void | AuthError1Response | ConflictError1Response | UnprocessableEntityNewResponse | DependencyError1Response, void, CreateFeatureFlagUserGroup, void>("POST", `/create-user-group`, props);


export type ModifyUserGroupProps = Omit<MutateProps<ModifyUserGroupResponseResponse, void | AuthError1Response | ConflictError2Response | UnprocessableEntityNewResponse | DependencyError1Response, void, ModifyUserGroup, void>, "path" | "verb">;

/**
 * modify user group.
 */
export const ModifyUserGroup = (props: ModifyUserGroupProps) => (
  <Mutate<ModifyUserGroupResponseResponse, void | AuthError1Response | ConflictError2Response | UnprocessableEntityNewResponse | DependencyError1Response, void, ModifyUserGroup, void>
    verb="PUT"
    path={`/modify-user-group`}
    
    {...props}
  />
);

export type UseModifyUserGroupProps = Omit<UseMutateProps<ModifyUserGroupResponseResponse, void | AuthError1Response | ConflictError2Response | UnprocessableEntityNewResponse | DependencyError1Response, void, ModifyUserGroup, void>, "path" | "verb">;

/**
 * modify user group.
 */
export const useModifyUserGroup = (props: UseModifyUserGroupProps) => useMutate<ModifyUserGroupResponseResponse, void | AuthError1Response | ConflictError2Response | UnprocessableEntityNewResponse | DependencyError1Response, void, ModifyUserGroup, void>("PUT", `/modify-user-group`, props);


export interface GetUserGroupsQueryParams {
  /**
   * Group name of the feature flag user group
   */
  group_name?: string;
}

export type GetUserGroupsProps = Omit<GetProps<ModifyUserGroupResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, GetUserGroupsQueryParams, void>, "path">;

/**
 * Get User Groups
 */
export const GetUserGroups = (props: GetUserGroupsProps) => (
  <Get<ModifyUserGroupResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, GetUserGroupsQueryParams, void>
    path={`/user-groups`}
    
    {...props}
  />
);

export type UseGetUserGroupsProps = Omit<UseGetProps<ModifyUserGroupResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, GetUserGroupsQueryParams, void>, "path">;

/**
 * Get User Groups
 */
export const useGetUserGroups = (props: UseGetUserGroupsProps) => useGet<ModifyUserGroupResponseResponse, void | AuthError1Response | UnprocessableEntityNewResponse | DependencyError1Response, GetUserGroupsQueryParams, void>(`/user-groups`, props);


export type TriggerErrorProps = Omit<GetProps<void, void, void, void>, "path">;

/**
 * Sentry endpoint
 */
export const TriggerError = (props: TriggerErrorProps) => (
  <Get<void, void, void, void>
    path={`/debug-sentry`}
    
    {...props}
  />
);

export type UseTriggerErrorProps = Omit<UseGetProps<void, void, void, void>, "path">;

/**
 * Sentry endpoint
 */
export const useTriggerError = (props: UseTriggerErrorProps) => useGet<void, void, void, void>(`/debug-sentry`, props);


export type CreateNewSendbirdChannelProps = Omit<MutateProps<CreateSendbirdChannelResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, CreateSendbirdChannel, void>, "path" | "verb">;

/**
 * Create a new channel between a care team member and a patient.
 */
export const CreateNewSendbirdChannel = (props: CreateNewSendbirdChannelProps) => (
  <Mutate<CreateSendbirdChannelResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, CreateSendbirdChannel, void>
    verb="POST"
    path={`/create-sendbird-channel`}
    
    {...props}
  />
);

export type UseCreateNewSendbirdChannelProps = Omit<UseMutateProps<CreateSendbirdChannelResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, CreateSendbirdChannel, void>, "path" | "verb">;

/**
 * Create a new channel between a care team member and a patient.
 */
export const useCreateNewSendbirdChannel = (props: UseCreateNewSendbirdChannelProps) => useMutate<CreateSendbirdChannelResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, CreateSendbirdChannel, void>("POST", `/create-sendbird-channel`, props);


export type FreezeSendbirdChannelProps = Omit<MutateProps<FreezeSendbirdChannelResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, FreezeSendbirdChannel, void>, "path" | "verb">;

/**
 * Freeze all channels that has patient as a member.
 */
export const FreezeSendbirdChannel = (props: FreezeSendbirdChannelProps) => (
  <Mutate<FreezeSendbirdChannelResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, FreezeSendbirdChannel, void>
    verb="PUT"
    path={`/freeze-sendbird-channel`}
    
    {...props}
  />
);

export type UseFreezeSendbirdChannelProps = Omit<UseMutateProps<FreezeSendbirdChannelResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, FreezeSendbirdChannel, void>, "path" | "verb">;

/**
 * Freeze all channels that has patient as a member.
 */
export const useFreezeSendbirdChannel = (props: UseFreezeSendbirdChannelProps) => useMutate<FreezeSendbirdChannelResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, FreezeSendbirdChannel, void>("PUT", `/freeze-sendbird-channel`, props);


export type CreatePhaReportProps = Omit<MutateProps<CreatePHAReportResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, CreatePhaReport, void>, "path" | "verb">;

/**
 * Create a new PHA report for patient.
 */
export const CreatePhaReport = (props: CreatePhaReportProps) => (
  <Mutate<CreatePHAReportResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, CreatePhaReport, void>
    verb="POST"
    path={`/create-pha-report`}
    
    {...props}
  />
);

export type UseCreatePhaReportProps = Omit<UseMutateProps<CreatePHAReportResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, CreatePhaReport, void>, "path" | "verb">;

/**
 * Create a new PHA report for patient.
 */
export const useCreatePhaReport = (props: UseCreatePhaReportProps) => useMutate<CreatePHAReportResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, CreatePhaReport, void>("POST", `/create-pha-report`, props);


export type GetMyPhaReportsProps = Omit<GetProps<GetMyPHAReportResponseResponse, void | UnauthenticatedResponse | UnprocessableEntityResponse | DependencyFailureResponse, void, void>, "path">;

/**
 * Retrieve details of current user's PHA reports
 */
export const GetMyPhaReports = (props: GetMyPhaReportsProps) => (
  <Get<GetMyPHAReportResponseResponse, void | UnauthenticatedResponse | UnprocessableEntityResponse | DependencyFailureResponse, void, void>
    path={`/my-pha-reports`}
    
    {...props}
  />
);

export type UseGetMyPhaReportsProps = Omit<UseGetProps<GetMyPHAReportResponseResponse, void | UnauthenticatedResponse | UnprocessableEntityResponse | DependencyFailureResponse, void, void>, "path">;

/**
 * Retrieve details of current user's PHA reports
 */
export const useGetMyPhaReports = (props: UseGetMyPhaReportsProps) => useGet<GetMyPHAReportResponseResponse, void | UnauthenticatedResponse | UnprocessableEntityResponse | DependencyFailureResponse, void, void>(`/my-pha-reports`, props);


export interface GetMyPhaReportDataSectionsPathParams {
  /**
   * User's PHA Score Id.
   */
  score_id: string;
  /**
   * The PHA report section accepts any of the following values:
   * * intro
   * * heatlh_overview
   * * progress_over_time
   * * cognitive_health
   * * cardiovascular_health
   * * metabolic_performance_health
   * * strength_and_functional_health
   * * strength_and_functional_health
   * * recommendations
   * 
   */
  section: string
}

export type GetMyPhaReportDataSectionsProps = Omit<GetProps<GetMyPHAReportResponseResponse, void | UnauthenticatedResponse | UnprocessableEntityResponse | DependencyFailureResponse, void, GetMyPhaReportDataSectionsPathParams>, "path"> & GetMyPhaReportDataSectionsPathParams;

/**
 * Retrieve details of current user's PHA report
 */
export const GetMyPhaReportDataSections = ({score_id, section, ...props}: GetMyPhaReportDataSectionsProps) => (
  <Get<GetMyPHAReportResponseResponse, void | UnauthenticatedResponse | UnprocessableEntityResponse | DependencyFailureResponse, void, GetMyPhaReportDataSectionsPathParams>
    path={`/my-pha-report-data/${score_id}/${section}`}
    
    {...props}
  />
);

export type UseGetMyPhaReportDataSectionsProps = Omit<UseGetProps<GetMyPHAReportResponseResponse, void | UnauthenticatedResponse | UnprocessableEntityResponse | DependencyFailureResponse, void, GetMyPhaReportDataSectionsPathParams>, "path"> & GetMyPhaReportDataSectionsPathParams;

/**
 * Retrieve details of current user's PHA report
 */
export const useGetMyPhaReportDataSections = ({score_id, section, ...props}: UseGetMyPhaReportDataSectionsProps) => useGet<GetMyPHAReportResponseResponse, void | UnauthenticatedResponse | UnprocessableEntityResponse | DependencyFailureResponse, void, GetMyPhaReportDataSectionsPathParams>((paramsInPath: GetMyPhaReportDataSectionsPathParams) => `/my-pha-report-data/${paramsInPath.score_id}/${paramsInPath.section}`, {  pathParams: { score_id, section }, ...props });


export type PhaLambdaCallbackProps = Omit<MutateProps<PHALambdaCallbackResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, PhaLambdaCallback, void>, "path" | "verb">;

/**
 * Webhook Endpoint for PHA Lambda function. The endpoint updates the related Salesforce record with the S3 URI for the PHA PDF report generated for the `score_id` and `patient_id` provided in the request body
 */
export const PhaLambdaCallback = (props: PhaLambdaCallbackProps) => (
  <Mutate<PHALambdaCallbackResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, PhaLambdaCallback, void>
    verb="POST"
    path={`/pha-lambda-callback`}
    
    {...props}
  />
);

export type UsePhaLambdaCallbackProps = Omit<UseMutateProps<PHALambdaCallbackResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, PhaLambdaCallback, void>, "path" | "verb">;

/**
 * Webhook Endpoint for PHA Lambda function. The endpoint updates the related Salesforce record with the S3 URI for the PHA PDF report generated for the `score_id` and `patient_id` provided in the request body
 */
export const usePhaLambdaCallback = (props: UsePhaLambdaCallbackProps) => useMutate<PHALambdaCallbackResponseResponse, void | UnprocessableEntityResponse | DependencyFailureResponse, void, PhaLambdaCallback, void>("POST", `/pha-lambda-callback`, props);


export type RegisterProps = Omit<MutateProps<RegisterResponseResponse, void, void, Registration, void>, "path" | "verb">;

/**
 * Register a new user
 */
export const Register = (props: RegisterProps) => (
  <Mutate<RegisterResponseResponse, void, void, Registration, void>
    verb="POST"
    path={`/auth/register`}
    
    {...props}
  />
);

export type UseRegisterProps = Omit<UseMutateProps<RegisterResponseResponse, void, void, Registration, void>, "path" | "verb">;

/**
 * Register a new user
 */
export const useRegister = (props: UseRegisterProps) => useMutate<RegisterResponseResponse, void, void, Registration, void>("POST", `/auth/register`, props);


export type LoginProps = Omit<MutateProps<LoginResponseResponse, void, void, Login, void>, "path" | "verb">;

/**
 * Login to the system
 */
export const Login = (props: LoginProps) => (
  <Mutate<LoginResponseResponse, void, void, Login, void>
    verb="POST"
    path={`/auth/login`}
    
    {...props}
  />
);

export type UseLoginProps = Omit<UseMutateProps<LoginResponseResponse, void, void, Login, void>, "path" | "verb">;

/**
 * Login to the system
 */
export const useLogin = (props: UseLoginProps) => useMutate<LoginResponseResponse, void, void, Login, void>("POST", `/auth/login`, props);


export type LogoutProps = Omit<GetProps<void, void | UnauthenticatedResponse, void, void>, "path">;

/**
 * Logout from the system
 */
export const Logout = (props: LogoutProps) => (
  <Get<void, void | UnauthenticatedResponse, void, void>
    path={`/auth/logout`}
    
    {...props}
  />
);

export type UseLogoutProps = Omit<UseGetProps<void, void | UnauthenticatedResponse, void, void>, "path">;

/**
 * Logout from the system
 */
export const useLogout = (props: UseLogoutProps) => useGet<void, void | UnauthenticatedResponse, void, void>(`/auth/logout`, props);


export interface CheckAccountQueryParams {
  account: string;
}

export type CheckAccountProps = Omit<GetProps<void, void, CheckAccountQueryParams, void>, "path">;

/**
 * Check for existing account in system
 */
export const CheckAccount = (props: CheckAccountProps) => (
  <Get<void, void, CheckAccountQueryParams, void>
    path={`/auth/check-account`}
    
    {...props}
  />
);

export type UseCheckAccountProps = Omit<UseGetProps<void, void, CheckAccountQueryParams, void>, "path">;

/**
 * Check for existing account in system
 */
export const useCheckAccount = (props: UseCheckAccountProps) => useGet<void, void, CheckAccountQueryParams, void>(`/auth/check-account`, props);


export type ForgotPasswordProps = Omit<MutateProps<ForgotPasswordResponseResponse, void, void, Email, void>, "path" | "verb">;

/**
 * Forgot password
 */
export const ForgotPassword = (props: ForgotPasswordProps) => (
  <Mutate<ForgotPasswordResponseResponse, void, void, Email, void>
    verb="POST"
    path={`/auth/forgot-password`}
    
    {...props}
  />
);

export type UseForgotPasswordProps = Omit<UseMutateProps<ForgotPasswordResponseResponse, void, void, Email, void>, "path" | "verb">;

/**
 * Forgot password
 */
export const useForgotPassword = (props: UseForgotPasswordProps) => useMutate<ForgotPasswordResponseResponse, void, void, Email, void>("POST", `/auth/forgot-password`, props);


export type UploadImageProps = Omit<MutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>, "path" | "verb">;

/**
 * Upload profile image
 */
export const UploadImage = (props: UploadImageProps) => (
  <Mutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>
    verb="POST"
    path={`/profile/upload`}
    
    {...props}
  />
);

export type UseUploadImageProps = Omit<UseMutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>, "path" | "verb">;

/**
 * Upload profile image
 */
export const useUploadImage = (props: UseUploadImageProps) => useMutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>("POST", `/profile/upload`, props);


export type GetAvatarProps = Omit<GetProps<void, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, void>, "path">;

/**
 * Get patient avatar image
 */
export const GetAvatar = (props: GetAvatarProps) => (
  <Get<void, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, void>
    path={`/profile/avatar`}
    
    {...props}
  />
);

export type UseGetAvatarProps = Omit<UseGetProps<void, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, void>, "path">;

/**
 * Get patient avatar image
 */
export const useGetAvatar = (props: UseGetAvatarProps) => useGet<void, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, void>(`/profile/avatar`, props);


export type GetUserProps = Omit<GetProps<GetUserResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get user information
 */
export const GetUser = (props: GetUserProps) => (
  <Get<GetUserResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/profile/me`}
    
    {...props}
  />
);

export type UseGetUserProps = Omit<UseGetProps<GetUserResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get user information
 */
export const useGetUser = (props: UseGetUserProps) => useGet<GetUserResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/profile/me`, props);


export type RemoveImageProps = Omit<MutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>, "path" | "verb">;

/**
 * Remove profile image
 */
export const RemoveImage = (props: RemoveImageProps) => (
  <Mutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>
    verb="DELETE"
    path={`/profile/remove-image`}
    
    {...props}
  />
);

export type UseRemoveImageProps = Omit<UseMutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>, "path" | "verb">;

/**
 * Remove profile image
 */
export const useRemoveImage = (props: UseRemoveImageProps) => useMutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, void, void>("DELETE", `/profile/remove-image`, {   ...props });


export type ChangePasswordProps = Omit<MutateProps<void, void, void, ChangePassword, void>, "path" | "verb">;

/**
 * Change user password
 */
export const ChangePassword = (props: ChangePasswordProps) => (
  <Mutate<void, void, void, ChangePassword, void>
    verb="POST"
    path={`/profile/change-password`}
    
    {...props}
  />
);

export type UseChangePasswordProps = Omit<UseMutateProps<void, void, void, ChangePassword, void>, "path" | "verb">;

/**
 * Change user password
 */
export const useChangePassword = (props: UseChangePasswordProps) => useMutate<void, void, void, ChangePassword, void>("POST", `/profile/change-password`, props);


export type UpdateUserProps = Omit<MutateProps<UpdateUserResponse, void | UnauthenticatedResponse | UnauthorizedResponse, void, UpdateUser, void>, "path" | "verb">;

/**
 * Update user's profile
 */
export const UpdateUser = (props: UpdateUserProps) => (
  <Mutate<UpdateUserResponse, void | UnauthenticatedResponse | UnauthorizedResponse, void, UpdateUser, void>
    verb="PATCH"
    path={`/profile/update-user`}
    
    {...props}
  />
);

export type UseUpdateUserProps = Omit<UseMutateProps<UpdateUserResponse, void | UnauthenticatedResponse | UnauthorizedResponse, void, UpdateUser, void>, "path" | "verb">;

/**
 * Update user's profile
 */
export const useUpdateUser = (props: UseUpdateUserProps) => useMutate<UpdateUserResponse, void | UnauthenticatedResponse | UnauthorizedResponse, void, UpdateUser, void>("PATCH", `/profile/update-user`, props);


export type CeleryStartTaskProps = Omit<GetProps<void, void, void, void>, "path">;

/**
 * Create Celery Task
 */
export const CeleryStartTask = (props: CeleryStartTaskProps) => (
  <Get<void, void, void, void>
    path={`/celery/create-task`}
    
    {...props}
  />
);

export type UseCeleryStartTaskProps = Omit<UseGetProps<void, void, void, void>, "path">;

/**
 * Create Celery Task
 */
export const useCeleryStartTask = (props: UseCeleryStartTaskProps) => useGet<void, void, void, void>(`/celery/create-task`, props);


export interface CeleryTaskStatusPathParams {
  /**
   * Celery Task Id
   */
  task_id: string
}

export type CeleryTaskStatusProps = Omit<GetProps<void, void, void, CeleryTaskStatusPathParams>, "path"> & CeleryTaskStatusPathParams;

/**
 * Celery Task Status
 */
export const CeleryTaskStatus = ({task_id, ...props}: CeleryTaskStatusProps) => (
  <Get<void, void, void, CeleryTaskStatusPathParams>
    path={`/celery/task-status/${task_id}`}
    
    {...props}
  />
);

export type UseCeleryTaskStatusProps = Omit<UseGetProps<void, void, void, CeleryTaskStatusPathParams>, "path"> & CeleryTaskStatusPathParams;

/**
 * Celery Task Status
 */
export const useCeleryTaskStatus = ({task_id, ...props}: UseCeleryTaskStatusProps) => useGet<void, void, void, CeleryTaskStatusPathParams>((paramsInPath: CeleryTaskStatusPathParams) => `/celery/task-status/${paramsInPath.task_id}`, {  pathParams: { task_id }, ...props });


export interface CeleryTaskResultPathParams {
  /**
   * Celery Task Id
   */
  task_id: string
}

export type CeleryTaskResultProps = Omit<GetProps<void, void, void, CeleryTaskResultPathParams>, "path"> & CeleryTaskResultPathParams;

/**
 * Celery Task Result
 */
export const CeleryTaskResult = ({task_id, ...props}: CeleryTaskResultProps) => (
  <Get<void, void, void, CeleryTaskResultPathParams>
    path={`/celery/task-result/${task_id}`}
    
    {...props}
  />
);

export type UseCeleryTaskResultProps = Omit<UseGetProps<void, void, void, CeleryTaskResultPathParams>, "path"> & CeleryTaskResultPathParams;

/**
 * Celery Task Result
 */
export const useCeleryTaskResult = ({task_id, ...props}: UseCeleryTaskResultProps) => useGet<void, void, void, CeleryTaskResultPathParams>((paramsInPath: CeleryTaskResultPathParams) => `/celery/task-result/${paramsInPath.task_id}`, {  pathParams: { task_id }, ...props });


export interface GetBiometricsQueryParams {
  /**
   * Set the value of this parameter to `true` to force updates API response
   */
  updated?: string;
}

export type GetBiometricsProps = Omit<GetProps<GetBiometricsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, GetBiometricsQueryParams, void>, "path">;

/**
 * Get biometrics educational content
 */
export const GetBiometrics = (props: GetBiometricsProps) => (
  <Get<GetBiometricsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, GetBiometricsQueryParams, void>
    path={`/biometrics`}
    
    {...props}
  />
);

export type UseGetBiometricsProps = Omit<UseGetProps<GetBiometricsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, GetBiometricsQueryParams, void>, "path">;

/**
 * Get biometrics educational content
 */
export const useGetBiometrics = (props: UseGetBiometricsProps) => useGet<GetBiometricsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, GetBiometricsQueryParams, void>(`/biometrics`, props);


export interface GetBiometricsDetailsPathParams {
  /**
   * Name of biometric as returned in the first screen
   */
  biometric_name: string
}

export type GetBiometricsDetailsProps = Omit<GetProps<GetBiometricDataResponseResponse, GetBiometricsErrorResponse | UnauthenticatedResponse | UnauthorizedResponse, void, GetBiometricsDetailsPathParams>, "path"> & GetBiometricsDetailsPathParams;

/**
 * Returns data for biometrics' second screen graph
 */
export const GetBiometricsDetails = ({biometric_name, ...props}: GetBiometricsDetailsProps) => (
  <Get<GetBiometricDataResponseResponse, GetBiometricsErrorResponse | UnauthenticatedResponse | UnauthorizedResponse, void, GetBiometricsDetailsPathParams>
    path={`/biometric/details/${biometric_name}`}
    
    {...props}
  />
);

export type UseGetBiometricsDetailsProps = Omit<UseGetProps<GetBiometricDataResponseResponse, GetBiometricsErrorResponse | UnauthenticatedResponse | UnauthorizedResponse, void, GetBiometricsDetailsPathParams>, "path"> & GetBiometricsDetailsPathParams;

/**
 * Returns data for biometrics' second screen graph
 */
export const useGetBiometricsDetails = ({biometric_name, ...props}: UseGetBiometricsDetailsProps) => useGet<GetBiometricDataResponseResponse, GetBiometricsErrorResponse | UnauthenticatedResponse | UnauthorizedResponse, void, GetBiometricsDetailsPathParams>((paramsInPath: GetBiometricsDetailsPathParams) => `/biometric/details/${paramsInPath.biometric_name}`, {  pathParams: { biometric_name }, ...props });


export type GetWeightProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patiens weight data over time
 */
export const GetWeight = (props: GetWeightProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/weight`}
    
    {...props}
  />
);

export type UseGetWeightProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patiens weight data over time
 */
export const useGetWeight = (props: UseGetWeightProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/weight`, props);


export type GetTotalBodyFatProps = Omit<GetProps<GetTotalBodyFatResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get total body fat data
 */
export const GetTotalBodyFat = (props: GetTotalBodyFatProps) => (
  <Get<GetTotalBodyFatResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/total-body-fat`}
    
    {...props}
  />
);

export type UseGetTotalBodyFatProps = Omit<UseGetProps<GetTotalBodyFatResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get total body fat data
 */
export const useGetTotalBodyFat = (props: UseGetTotalBodyFatProps) => useGet<GetTotalBodyFatResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/total-body-fat`, props);


export type GetMuscleMassProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get muscle mass data
 */
export const GetMuscleMass = (props: GetMuscleMassProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/muscle-mass`}
    
    {...props}
  />
);

export type UseGetMuscleMassProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get muscle mass data
 */
export const useGetMuscleMass = (props: UseGetMuscleMassProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/muscle-mass`, props);


export type GetFatMassProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get fat mass data
 */
export const GetFatMass = (props: GetFatMassProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/fat-mass`}
    
    {...props}
  />
);

export type UseGetFatMassProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get fat mass data
 */
export const useGetFatMass = (props: UseGetFatMassProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/fat-mass`, props);


export type GetUpperTorsoFatProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get upper torso fat data
 */
export const GetUpperTorsoFat = (props: GetUpperTorsoFatProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/upper-torso-fat`}
    
    {...props}
  />
);

export type UseGetUpperTorsoFatProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get upper torso fat data
 */
export const useGetUpperTorsoFat = (props: UseGetUpperTorsoFatProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/upper-torso-fat`, props);


export type GetVo2MaxProps = Omit<GetProps<GetVO2MaxResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get VO2 max data
 */
export const GetVo2Max = (props: GetVo2MaxProps) => (
  <Get<GetVO2MaxResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/vo2-max`}
    
    {...props}
  />
);

export type UseGetVo2MaxProps = Omit<UseGetProps<GetVO2MaxResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get VO2 max data
 */
export const useGetVo2Max = (props: UseGetVo2MaxProps) => useGet<GetVO2MaxResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/vo2-max`, props);


export type GetPeakHeartRateProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get peak heart rate data
 */
export const GetPeakHeartRate = (props: GetPeakHeartRateProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/peak-heart-rate`}
    
    {...props}
  />
);

export type UseGetPeakHeartRateProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get peak heart rate data
 */
export const useGetPeakHeartRate = (props: UseGetPeakHeartRateProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/peak-heart-rate`, props);


export type GetAnaerobicThresholdProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get anaerobic threshold data
 */
export const GetAnaerobicThreshold = (props: GetAnaerobicThresholdProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/anaerobic-threshold`}
    
    {...props}
  />
);

export type UseGetAnaerobicThresholdProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get anaerobic threshold data
 */
export const useGetAnaerobicThreshold = (props: UseGetAnaerobicThresholdProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/anaerobic-threshold`, props);


export type GetMaxWattageProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get max wattage data
 */
export const GetMaxWattage = (props: GetMaxWattageProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/max-wattage`}
    
    {...props}
  />
);

export type UseGetMaxWattageProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get max wattage data
 */
export const useGetMaxWattage = (props: UseGetMaxWattageProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/max-wattage`, props);


export type GetRestingHeartRateProps = Omit<GetProps<GetRestingHeartRateResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get resting heart rate data
 */
export const GetRestingHeartRate = (props: GetRestingHeartRateProps) => (
  <Get<GetRestingHeartRateResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/resting-heart-rate`}
    
    {...props}
  />
);

export type UseGetRestingHeartRateProps = Omit<UseGetProps<GetRestingHeartRateResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get resting heart rate data
 */
export const useGetRestingHeartRate = (props: UseGetRestingHeartRateProps) => useGet<GetRestingHeartRateResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/resting-heart-rate`, props);


export type GetBloodPressureProps = Omit<GetProps<GetBloodPressureResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get blood pressure data
 */
export const GetBloodPressure = (props: GetBloodPressureProps) => (
  <Get<GetBloodPressureResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/blood-pressure`}
    
    {...props}
  />
);

export type UseGetBloodPressureProps = Omit<UseGetProps<GetBloodPressureResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get blood pressure data
 */
export const useGetBloodPressure = (props: UseGetBloodPressureProps) => useGet<GetBloodPressureResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/blood-pressure`, props);


export type GetWaistHipRatioProps = Omit<GetProps<GetWaistHipRatioResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get waist hip ratio data
 */
export const GetWaistHipRatio = (props: GetWaistHipRatioProps) => (
  <Get<GetWaistHipRatioResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/waist-hip-ratio`}
    
    {...props}
  />
);

export type UseGetWaistHipRatioProps = Omit<UseGetProps<GetWaistHipRatioResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get waist hip ratio data
 */
export const useGetWaistHipRatio = (props: UseGetWaistHipRatioProps) => useGet<GetWaistHipRatioResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/waist-hip-ratio`, props);


export type GetLatestBiomarkersProps = Omit<GetProps<void, void | UnauthenticatedResponse, void, void>, "path">;

/**
 * Get biomarkers information
 */
export const GetLatestBiomarkers = (props: GetLatestBiomarkersProps) => (
  <Get<void, void | UnauthenticatedResponse, void, void>
    path={`/biomarkers`}
    
    {...props}
  />
);

export type UseGetLatestBiomarkersProps = Omit<UseGetProps<void, void | UnauthenticatedResponse, void, void>, "path">;

/**
 * Get biomarkers information
 */
export const useGetLatestBiomarkers = (props: UseGetLatestBiomarkersProps) => useGet<void, void | UnauthenticatedResponse, void, void>(`/biomarkers`, props);


export interface GetLatestBiomarkersSuperAccessPathParams {
  /**
   * Salesforce Id for which to pull latest Biomarkers
   */
  salesforce_id: string
}

export type GetLatestBiomarkersSuperAccessProps = Omit<GetProps<void, void | UnauthenticatedResponse, void, GetLatestBiomarkersSuperAccessPathParams>, "path"> & GetLatestBiomarkersSuperAccessPathParams;

/**
 * Get biomarkers information
 */
export const GetLatestBiomarkersSuperAccess = ({salesforce_id, ...props}: GetLatestBiomarkersSuperAccessProps) => (
  <Get<void, void | UnauthenticatedResponse, void, GetLatestBiomarkersSuperAccessPathParams>
    path={`/biomarkers-super-access/${salesforce_id}`}
    
    {...props}
  />
);

export type UseGetLatestBiomarkersSuperAccessProps = Omit<UseGetProps<void, void | UnauthenticatedResponse, void, GetLatestBiomarkersSuperAccessPathParams>, "path"> & GetLatestBiomarkersSuperAccessPathParams;

/**
 * Get biomarkers information
 */
export const useGetLatestBiomarkersSuperAccess = ({salesforce_id, ...props}: UseGetLatestBiomarkersSuperAccessProps) => useGet<void, void | UnauthenticatedResponse, void, GetLatestBiomarkersSuperAccessPathParams>((paramsInPath: GetLatestBiomarkersSuperAccessPathParams) => `/biomarkers-super-access/${paramsInPath.salesforce_id}`, {  pathParams: { salesforce_id }, ...props });


export type BiomarkerGraphProps = Omit<MutateProps<void, void | UnauthenticatedResponse, void, BiomarkersFilter, void>, "path" | "verb">;

/**
 * Get values for plotting a biomarker's graph
 */
export const BiomarkerGraph = (props: BiomarkerGraphProps) => (
  <Mutate<void, void | UnauthenticatedResponse, void, BiomarkersFilter, void>
    verb="POST"
    path={`/biomarker_graph`}
    
    {...props}
  />
);

export type UseBiomarkerGraphProps = Omit<UseMutateProps<void, void | UnauthenticatedResponse, void, BiomarkersFilter, void>, "path" | "verb">;

/**
 * Get values for plotting a biomarker's graph
 */
export const useBiomarkerGraph = (props: UseBiomarkerGraphProps) => useMutate<void, void | UnauthenticatedResponse, void, BiomarkersFilter, void>("POST", `/biomarker_graph`, props);


export interface BiomarkerEducationalContentPathParams {
  /**
   * Biomarker Educational Content
   */
  standard_id: string
}

export type BiomarkerEducationalContentProps = Omit<GetProps<GetBiomarkerEducationalContentResponseResponse, void, void, BiomarkerEducationalContentPathParams>, "path"> & BiomarkerEducationalContentPathParams;

/**
 * Get biomarkers eductaional contents by parsing the value Cenegenics_Test_Standards__r.Id from the biomarker payload
 */
export const BiomarkerEducationalContent = ({standard_id, ...props}: BiomarkerEducationalContentProps) => (
  <Get<GetBiomarkerEducationalContentResponseResponse, void, void, BiomarkerEducationalContentPathParams>
    path={`/biomarker-educational-content/${standard_id}`}
    
    {...props}
  />
);

export type UseBiomarkerEducationalContentProps = Omit<UseGetProps<GetBiomarkerEducationalContentResponseResponse, void, void, BiomarkerEducationalContentPathParams>, "path"> & BiomarkerEducationalContentPathParams;

/**
 * Get biomarkers eductaional contents by parsing the value Cenegenics_Test_Standards__r.Id from the biomarker payload
 */
export const useBiomarkerEducationalContent = ({standard_id, ...props}: UseBiomarkerEducationalContentProps) => useGet<GetBiomarkerEducationalContentResponseResponse, void, void, BiomarkerEducationalContentPathParams>((paramsInPath: BiomarkerEducationalContentPathParams) => `/biomarker-educational-content/${paramsInPath.standard_id}`, {  pathParams: { standard_id }, ...props });


export type GetArterialThicknessProps = Omit<GetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get arterial thickness data
 */
export const GetArterialThickness = (props: GetArterialThicknessProps) => (
  <Get<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/arterial-thickness`}
    
    {...props}
  />
);

export type UseGetArterialThicknessProps = Omit<UseGetProps<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get arterial thickness data
 */
export const useGetArterialThickness = (props: UseGetArterialThicknessProps) => useGet<GetBiometricDataResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/arterial-thickness`, props);


export type GetMyCareTeamProps = Omit<GetProps<GetMyCareTeamResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patient's care team data
 */
export const GetMyCareTeam = (props: GetMyCareTeamProps) => (
  <Get<GetMyCareTeamResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/my-care-team`}
    
    {...props}
  />
);

export type UseGetMyCareTeamProps = Omit<UseGetProps<GetMyCareTeamResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patient's care team data
 */
export const useGetMyCareTeam = (props: UseGetMyCareTeamProps) => useGet<GetMyCareTeamResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/my-care-team`, props);


export type GetTestResultsProps = Omit<GetProps<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patient's test results
 */
export const GetTestResults = (props: GetTestResultsProps) => (
  <Get<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/test-results`}
    
    {...props}
  />
);

export type UseGetTestResultsProps = Omit<UseGetProps<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get patient's test results
 */
export const useGetTestResults = (props: UseGetTestResultsProps) => useGet<GetTestResultsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/test-results`, props);


export type SendbirdWebhookProps = Omit<MutateProps<void, void, void, void, void>, "path" | "verb">;

/**
 * Sendbird Webhook
 */
export const SendbirdWebhook = (props: SendbirdWebhookProps) => (
  <Mutate<void, void, void, void, void>
    verb="POST"
    path={`/sendbird-webhook`}
    
    {...props}
  />
);

export type UseSendbirdWebhookProps = Omit<UseMutateProps<void, void, void, void, void>, "path" | "verb">;

/**
 * Sendbird Webhook
 */
export const useSendbirdWebhook = (props: UseSendbirdWebhookProps) => useMutate<void, void, void, void, void>("POST", `/sendbird-webhook`, props);


export interface GetTestResultsFilePathParams {
  /**
   * String ID of the test results file
   */
  document_id: string
}

export type GetTestResultsFileProps = Omit<GetProps<GetTestResultFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetTestResultsFilePathParams>, "path"> & GetTestResultsFilePathParams;

/**
 * Get a patient's test results file
 */
export const GetTestResultsFile = ({document_id, ...props}: GetTestResultsFileProps) => (
  <Get<GetTestResultFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetTestResultsFilePathParams>
    path={`/test-results-file/${document_id}`}
    
    {...props}
  />
);

export type UseGetTestResultsFileProps = Omit<UseGetProps<GetTestResultFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetTestResultsFilePathParams>, "path"> & GetTestResultsFilePathParams;

/**
 * Get a patient's test results file
 */
export const useGetTestResultsFile = ({document_id, ...props}: UseGetTestResultsFileProps) => useGet<GetTestResultFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetTestResultsFilePathParams>((paramsInPath: GetTestResultsFilePathParams) => `/test-results-file/${paramsInPath.document_id}`, {  pathParams: { document_id }, ...props });


export type GetMyProductsProps = Omit<GetProps<GetMyProductsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get my products
 */
export const GetMyProducts = (props: GetMyProductsProps) => (
  <Get<GetMyProductsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>
    path={`/my-products`}
    
    {...props}
  />
);

export type UseGetMyProductsProps = Omit<UseGetProps<GetMyProductsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>, "path">;

/**
 * Get my products
 */
export const useGetMyProducts = (props: UseGetMyProductsProps) => useGet<GetMyProductsResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, void, void>(`/my-products`, props);


export interface GetMyProductFilePathParams {
  /**
   * String ID of the my product file
   */
  document_id: string
}

export type GetMyProductFileProps = Omit<GetProps<GetMyProductFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetMyProductFilePathParams>, "path"> & GetMyProductFilePathParams;

/**
 * Get my product file
 */
export const GetMyProductFile = ({document_id, ...props}: GetMyProductFileProps) => (
  <Get<GetMyProductFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetMyProductFilePathParams>
    path={`/my-product-file/${document_id}`}
    
    {...props}
  />
);

export type UseGetMyProductFileProps = Omit<UseGetProps<GetMyProductFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetMyProductFilePathParams>, "path"> & GetMyProductFilePathParams;

/**
 * Get my product file
 */
export const useGetMyProductFile = ({document_id, ...props}: UseGetMyProductFileProps) => useGet<GetMyProductFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetMyProductFilePathParams>((paramsInPath: GetMyProductFilePathParams) => `/my-product-file/${paramsInPath.document_id}`, {  pathParams: { document_id }, ...props });


export interface GetMyOrdersQueryParams {
  order_category: "next" | "past";
  page_number: number;
  page_size?: number;
  days_before?: number;
  sort_order?: "asc" | "desc";
}

export type GetMyOrdersProps = Omit<GetProps<GetMyOrdersResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, GetMyOrdersQueryParams, void>, "path">;

/**
 * Get my orders
 */
export const GetMyOrders = (props: GetMyOrdersProps) => (
  <Get<GetMyOrdersResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, GetMyOrdersQueryParams, void>
    path={`/my-orders/`}
    
    {...props}
  />
);

export type UseGetMyOrdersProps = Omit<UseGetProps<GetMyOrdersResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, GetMyOrdersQueryParams, void>, "path">;

/**
 * Get my orders
 */
export const useGetMyOrders = (props: UseGetMyOrdersProps) => useGet<GetMyOrdersResponseResponse, UnauthenticatedResponse | UnauthorizedResponse, GetMyOrdersQueryParams, void>(`/my-orders/`, props);


export interface GetOrderInvoiceFilePathParams {
  /**
   * String ID of the order invoice file
   */
  order_id: string
}

export type GetOrderInvoiceFileProps = Omit<GetProps<GetOrderInvoiceFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetOrderInvoiceFilePathParams>, "path"> & GetOrderInvoiceFilePathParams;

/**
 * Get order invoice file
 */
export const GetOrderInvoiceFile = ({order_id, ...props}: GetOrderInvoiceFileProps) => (
  <Get<GetOrderInvoiceFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetOrderInvoiceFilePathParams>
    path={`/my-orders-invoice/${order_id}`}
    
    {...props}
  />
);

export type UseGetOrderInvoiceFileProps = Omit<UseGetProps<GetOrderInvoiceFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetOrderInvoiceFilePathParams>, "path"> & GetOrderInvoiceFilePathParams;

/**
 * Get order invoice file
 */
export const useGetOrderInvoiceFile = ({order_id, ...props}: UseGetOrderInvoiceFileProps) => useGet<GetOrderInvoiceFileResponseResponse, UnauthenticatedResponse | UnauthorizedResponse | NotFoundResponse, void, GetOrderInvoiceFilePathParams>((paramsInPath: GetOrderInvoiceFilePathParams) => `/my-orders-invoice/${paramsInPath.order_id}`, {  pathParams: { order_id }, ...props });


export type ApproveOrderProps = Omit<MutateProps<void, void | UnauthenticatedResponse, void, ApproveOrder, void>, "path" | "verb">;

/**
 * Approve and open order
 */
export const ApproveOrder = (props: ApproveOrderProps) => (
  <Mutate<void, void | UnauthenticatedResponse, void, ApproveOrder, void>
    verb="POST"
    path={`/approve-order`}
    
    {...props}
  />
);

export type UseApproveOrderProps = Omit<UseMutateProps<void, void | UnauthenticatedResponse, void, ApproveOrder, void>, "path" | "verb">;

/**
 * Approve and open order
 */
export const useApproveOrder = (props: UseApproveOrderProps) => useMutate<void, void | UnauthenticatedResponse, void, ApproveOrder, void>("POST", `/approve-order`, props);


export type RequestOrderChangeProps = Omit<MutateProps<void, void | UnauthenticatedResponse, void, RequestOrderChange, void>, "path" | "verb">;

/**
 * Request an order change
 */
export const RequestOrderChange = (props: RequestOrderChangeProps) => (
  <Mutate<void, void | UnauthenticatedResponse, void, RequestOrderChange, void>
    verb="POST"
    path={`/request-order-change`}
    
    {...props}
  />
);

export type UseRequestOrderChangeProps = Omit<UseMutateProps<void, void | UnauthenticatedResponse, void, RequestOrderChange, void>, "path" | "verb">;

/**
 * Request an order change
 */
export const useRequestOrderChange = (props: UseRequestOrderChangeProps) => useMutate<void, void | UnauthenticatedResponse, void, RequestOrderChange, void>("POST", `/request-order-change`, props);


export interface GetPendingOrderChangeRequestQueryParams {
  sf_orderid: string;
}

export type GetPendingOrderChangeRequestProps = Omit<GetProps<void, void, GetPendingOrderChangeRequestQueryParams, void>, "path">;

/**
 * Get details of pending order change request
 */
export const GetPendingOrderChangeRequest = (props: GetPendingOrderChangeRequestProps) => (
  <Get<void, void, GetPendingOrderChangeRequestQueryParams, void>
    path={`/auth/get-pending-order-change-request`}
    
    {...props}
  />
);

export type UseGetPendingOrderChangeRequestProps = Omit<UseGetProps<void, void, GetPendingOrderChangeRequestQueryParams, void>, "path">;

/**
 * Get details of pending order change request
 */
export const useGetPendingOrderChangeRequest = (props: UseGetPendingOrderChangeRequestProps) => useGet<void, void, GetPendingOrderChangeRequestQueryParams, void>(`/auth/get-pending-order-change-request`, props);


export type UpdateUserAccountStateProps = Omit<MutateProps<void, void, void, UpdateSalesforceAccountState, void>, "path" | "verb">;

/**
 * Update Salesforce Account State
 */
export const UpdateUserAccountState = (props: UpdateUserAccountStateProps) => (
  <Mutate<void, void, void, UpdateSalesforceAccountState, void>
    verb="POST"
    path={`/auth/update-account-state`}
    
    {...props}
  />
);

export type UseUpdateUserAccountStateProps = Omit<UseMutateProps<void, void, void, UpdateSalesforceAccountState, void>, "path" | "verb">;

/**
 * Update Salesforce Account State
 */
export const useUpdateUserAccountState = (props: UseUpdateUserAccountStateProps) => useMutate<void, void, void, UpdateSalesforceAccountState, void>("POST", `/auth/update-account-state`, props);


export type ResendInviteProps = Omit<MutateProps<void, void, void, Email, void>, "path" | "verb">;

/**
 * Resend invite if activation link has expired
 */
export const ResendInvite = (props: ResendInviteProps) => (
  <Mutate<void, void, void, Email, void>
    verb="POST"
    path={`/auth/resend-invite`}
    
    {...props}
  />
);

export type UseResendInviteProps = Omit<UseMutateProps<void, void, void, Email, void>, "path" | "verb">;

/**
 * Resend invite if activation link has expired
 */
export const useResendInvite = (props: UseResendInviteProps) => useMutate<void, void, void, Email, void>("POST", `/auth/resend-invite`, props);


export interface GetBiometricContentsQueryParams {
  biometric: string;
}

export type GetBiometricContentsProps = Omit<GetProps<void, void | UnauthenticatedResponse, GetBiometricContentsQueryParams, void>, "path">;

/**
 * Pull Biometric contents from Salesforce
 */
export const GetBiometricContents = (props: GetBiometricContentsProps) => (
  <Get<void, void | UnauthenticatedResponse, GetBiometricContentsQueryParams, void>
    path={`/biometric_contents`}
    
    {...props}
  />
);

export type UseGetBiometricContentsProps = Omit<UseGetProps<void, void | UnauthenticatedResponse, GetBiometricContentsQueryParams, void>, "path">;

/**
 * Pull Biometric contents from Salesforce
 */
export const useGetBiometricContents = (props: UseGetBiometricContentsProps) => useGet<void, void | UnauthenticatedResponse, GetBiometricContentsQueryParams, void>(`/biometric_contents`, props);


export interface CheckRegisteredDevicePathParams {
  /**
   * User Salesforce Id
   */
  user_id: string;
  /**
   * Device Id
   */
  device_id: string
}

export type CheckRegisteredDeviceProps = Omit<GetProps<void, void, void, CheckRegisteredDevicePathParams>, "path"> & CheckRegisteredDevicePathParams;

/**
 * Check if device is registered to receive notification
 */
export const CheckRegisteredDevice = ({user_id, device_id, ...props}: CheckRegisteredDeviceProps) => (
  <Get<void, void, void, CheckRegisteredDevicePathParams>
    path={`/push-notification/check-device/${user_id}/${device_id}`}
    
    {...props}
  />
);

export type UseCheckRegisteredDeviceProps = Omit<UseGetProps<void, void, void, CheckRegisteredDevicePathParams>, "path"> & CheckRegisteredDevicePathParams;

/**
 * Check if device is registered to receive notification
 */
export const useCheckRegisteredDevice = ({user_id, device_id, ...props}: UseCheckRegisteredDeviceProps) => useGet<void, void, void, CheckRegisteredDevicePathParams>((paramsInPath: CheckRegisteredDevicePathParams) => `/push-notification/check-device/${paramsInPath.user_id}/${paramsInPath.device_id}`, {  pathParams: { user_id, device_id }, ...props });


export type RegisterDeviceTokenProps = Omit<MutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, DeviceNotificationToken, void>, "path" | "verb">;

/**
 * Register device token with push notification service
 */
export const RegisterDeviceToken = (props: RegisterDeviceTokenProps) => (
  <Mutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, DeviceNotificationToken, void>
    verb="POST"
    path={`/push-notification/register-device`}
    
    {...props}
  />
);

export type UseRegisterDeviceTokenProps = Omit<UseMutateProps<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, DeviceNotificationToken, void>, "path" | "verb">;

/**
 * Register device token with push notification service
 */
export const useRegisterDeviceToken = (props: UseRegisterDeviceTokenProps) => useMutate<void, void | UnauthenticatedResponse | UnauthorizedResponse, void, DeviceNotificationToken, void>("POST", `/push-notification/register-device`, props);

