import React, { ReactNode, useRef } from 'react';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import * as Styled from './Caret.styled';

interface CaretProps {
	onClick?: () => void;
	onClickOutside?: (param: boolean) => void;
	dropdownOpen?: boolean;
	children?: ReactNode | ReactNode[];
	ariaLabel?: string;
}

const Caret = ({
	onClick,
	dropdownOpen,
	onClickOutside,
	ariaLabel = 'caret',
	children,
}: CaretProps) => {
	const ref = useRef() as React.RefObject<HTMLButtonElement>;

	useOnClickOutside(ref, () => {
		if (onClickOutside) onClickOutside(false);
	});

	return (
		<Styled.Container
			aria-label={ariaLabel}
			onClick={onClick}
			ref={ref}
			name='caret'
		>
			{dropdownOpen ? <Styled.CaretUp /> : <Styled.CaretDown />}
			{children}
		</Styled.Container>
	);
};

export default Caret;
