import React from 'react';

import * as Styled from './ModalHeader.styled';

const ModalHeader = ({ onClose, title }) => {
	return (
		<Styled.Row title={title}>
			{title && <Styled.Title data-testid='modalTitle'>{title}</Styled.Title>}

			{/* TODO: Think it over if we can put this component into <Button /> as well */}
			<Styled.CloseBtn
				onClick={onClose}
				type='button'
				name='Close'
				data-testid='modalClose'
			>
				<i className='lni lni-close'></i>
			</Styled.CloseBtn>
		</Styled.Row>
	);
};

export default ModalHeader;
