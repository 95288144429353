import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.header`
	margin-bottom: 14px;
`;

export const MainTitle = styled.p`
	font-weight: 800;
	${fontStyles[fontSizes.twentyFour]};
	color: ${colors.navy};
`;

export const SubTitle = styled.p`
	${fontStyles[fontSizes.twenty]};
	margin-top: 6px;
	color: ${colors.darkGrey};
`;

export const SubTitleMore = styled.p`
	${fontStyles[fontSizes.fifteen]};
	margin-top: 6px;
	color: ${colors.darkGrey};
`;
