const biometricsState = {};

const biometrics = {
	state: biometricsState,
	reducers: {
		update: (state, payload) => ({
			...state,
			...payload,
		}),
		reset: () => ({
			...biometricsState,
		}),
	},
};

export default biometrics;
