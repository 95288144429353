import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { device } from 'styles/device';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Tabs = styled.ul`
	display: flex;
	flex-shrink: 0;
	width: 100%;
	margin-top: 15px;
`;

export const Tab = styled.li`
	display: flex;
	${fontStyles[fontSizes.eighteen]};
	border-bottom: 3px solid transparent;
	background-image: linear-gradient(rgba(0, 145, 148, 1), rgba(72, 71, 112, 1)),
		${colors.gradientBlue};
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px ${colors.offWhite} inset;
	cursor: pointer;
	outline: none;
`;

export const Span = styled.span`
	font-weight: 800;
	-webkit-text-stroke: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	background-image: ${colors.gradientBlue};
`;

export const Content = styled.div``;

export const Row = styled.div`
	width: 100%;
	display: flex;

	@media ${device.mobileL} {
		flex-direction: column;
		align-items: center;
	}
`;

export const RowBiometricCards = styled(Row)`
	@media ${device.laptop}, ${device.mobileXL} and (${device.landscape}) {
		& > div {
			&:first-child {
				margin: 20px 0 0;
			}
		}
	}
`;

export const CardWrapper = styled.div`
	min-width: 380px;
	min-height: 294px;

	@media ${device.laptopL} {
		min-width: 284px;
	}

	@media ${device.laptop} {
		min-width: 229px;
		min-height: 227px;
	}

	@media ${device.mobileL} {
		width: 100%;
	}
`;

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background: ${colors.white};
	border-radius: 10px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	margin: 20px 0 0 0;

	&:first-child {
		margin-right: 10px;
	}

	@media ${device.mobileL} {
		width: 100%;
		margin-top: 14px;
		margin-left: 0;
		margin-bottom: 0px;
	}
`;

export const Title = styled.div`
	font-weight: 500;
	${fontStyles[fontSizes.sixteen]};
	color: ${colors.navy};
	margin-bottom: 26px;

	@media ${device.mobileL} {
		${fontStyles[fontSizes.twenty]};
	}
`;

export const BiometricRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 10px;
	flex-wrap: wrap;

	@media ${device.mobileL} {
		flex-direction: column;
	}
`;

export const Card = styled.div`
	width: 310px;
	height: 192px;
	background: #ffffff;
	/* Gray 6 */

	border: 1px solid #f2f2f2;
	/* Card shadow */

	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
`;

export const TakeActionContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;

	${Card} {
		flex: 0 0 auto;
		padding: 20px;
		margin: 10px 10px 0 0;
		font-family: 'Avenir';
		font-style: normal;
		font-size: 18px;
		line-height: 25px;
	}
`;

export const CardTitle = styled.p`
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 25px;
	margin-bottom: 20px;
`;

export const TakeActionTitle = styled.p`
	margin: 15px 0 10px 0;
	font-weight: 900;
	font-size: 18px;
`;

export const BiometricDetailsContainer = styled.div`
	padding: 20px;
	flex: 1;
`;

export const AlignCenter = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 30%;
`;
