import React from 'react';

import { useHistory } from 'react-router-dom';

import SendIconSVG from 'assets/svg/icons/send.svg';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useUnreadMessagesCount from 'hooks/useUnreadMessageCount';

import * as Styled from './Header.styled';

const MessagingBtn = () => {
	const history = useHistory();
	const unreadCount = useUnreadMessagesCount();
	const status = useConnectionStatus();

	const enableBtnClick = () => status === 'Connected';

	const handleClick = () => {
		enableBtnClick() && history.push('/messaging');
	};

	return (
		<Styled.Messaging onClick={handleClick} disabled={!enableBtnClick()}>
			<Styled.Icon src={SendIconSVG} />
			<Styled.SmallText>Messaging</Styled.SmallText>
			{unreadCount > 0 && <Styled.NotifyPointer />}
		</Styled.Messaging>
	);
};

export default MessagingBtn;
