import React, { useEffect, useState } from 'react';

import avatarImg from 'assets/svg/icons/user-avatar.svg';

const SafeImage = ({ src, className, alt, placeholder = avatarImg }) => {
	const [image, setImage] = useState(placeholder);

	useEffect(() => {
		let url = '';
		if (!src || src === '') {
			setImage(placeholder);
			return;
		}

		if (src.startsWith('blob:')) {
			setImage(src);
			return;
		}

		async function fetchImage() {
			url = await fetch(new Request(src))
				.then((resp) => resp.blob())
				.then((blob) => URL.createObjectURL(blob))
				.catch(() => placeholder);

			setImage(url);
		}

		fetchImage();

		return () => {
			URL.revokeObjectURL(url);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [src]);

	return <img className={className} src={image} alt={alt} />;
};
export default SafeImage;
