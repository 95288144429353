import { BiomarkerTestResult } from 'components/BiomarkerCard/BiomarkerCard.helper';

const biomarkers = {
	state: {},
	reducers: {
		updatedBiomarkers: (
			state: BiomarkerTestResult,
			payload: BiomarkerTestResult
		) => {
			return {
				...state,
				biomarkers: payload,
			};
		},
		updateGraphData: (state: any, payload: any) => {
			return {
				...state,
				biomarkerGraphData: payload,
			};
		},
	},
};

export default biomarkers;
