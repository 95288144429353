import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { TOAST_GENERIC_ERROR_TITLE } from 'utils/internationalization/en';
import { navigationHelperConstants } from 'utils/navigation';

export type NavigationDestination = {
	id: string;
	route: string;
	screenName: string;
	legacyRoute?: string;
	isTabScreen?: boolean;
};

type NativeContext = {
	accessToken: string | undefined;
	hasBottomNavigation: boolean;
	logOut(): void;
};

export enum WebviewEvent {
	goBack = 'goBack',
	pushNavigationTarget = 'pushNavigationTarget',
}

const useUniversalContext = () => {
	const { logout: browserLogout } = useAuth0();
	const dispatch = useDispatch();
	// @ts-ignore
	const nativeContext: NativeContext = window.nativeContext;
	// @ts-ignore
	const isBrowser = nativeContext === undefined;
	const hasBottomNavigation = nativeContext?.hasBottomNavigation;
	const history = useHistory();

	const sendWebviewEvent = useCallback(
		(eventId: WebviewEvent, params?: any) => {
			if (!isBrowser) {
				(window as any)?.ReactNativeWebView?.postMessage(
					JSON.stringify({
						webviewEvent: eventId,
						params,
					})
				);
			} else {
				let _params = {};
				try {
					_params = JSON.stringify(params || {});
				} catch (err) {}
				Sentry.captureException(new Error('Invalid sendWebviewEvent call'), {
					extra: {
						appVersion: (nativeContext as any)?.appVersion || '',
						isBrowser,
						callParams: _params,
					},
				});
			}
		},
		[isBrowser, nativeContext]
	);

	// FIXME:  params are ignored on web and legacy
	const pushDestination = useCallback(
		(
			targetDestination: NavigationDestination,
			params?: Record<string, any>
		) => {
			const destinationIdOrParams = params
				? { destinationId: targetDestination.id, destinationParams: params }
				: targetDestination.id;
			if (!isBrowser && hasBottomNavigation) {
				sendWebviewEvent(
					WebviewEvent.pushNavigationTarget,
					destinationIdOrParams
				);
			} else {
				history.push(targetDestination.route);
			}
		},
		[isBrowser, sendWebviewEvent, history, hasBottomNavigation]
	);

	const openUrlNative = useCallback(
		(url: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (!isBrowser) {
				e && e.preventDefault();
				(window as any)?.ReactNativeWebView?.postMessage(
					JSON.stringify({
						url,
					})
				);
			}
		},
		[isBrowser]
	);

	const logOut = useCallback(
		(reason: string = '') => {
			if (nativeContext) {
				if (reason) {
					try {
						(window as any)?.ReactNativeWebView?.postMessage(
							JSON.stringify({
								toastTitle: TOAST_GENERIC_ERROR_TITLE,
								toastText: reason,
							})
						);
					} catch (err) {}
				}
				setTimeout(() => {
					nativeContext?.logOut?.();
				}, 500);
			} else {
				dispatch.user.logoutUser();
				browserLogout({
					returnTo: `${window.location.protocol}//${window.location.host}/login`,
				});
			}
		},
		[browserLogout, dispatch.user, nativeContext]
	);

	return {
		isBrowser,
		hasBottomNavigation,
		logOut,
		openUrlNative,
		sendWebviewEvent,
		pushDestination,
		destinations: navigationHelperConstants.navigationTargets,
	};
};
export default useUniversalContext;
