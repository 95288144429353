import { NavigationDestination } from 'hooks/useUniversalContext';

const navigationTargets = Object.freeze({
	login: {
		id: 'login',
		route: '/my-health/biomarkers',
		screenName: 'Login',
		isTabScreen: false,
	},
	biomarkers: {
		id: 'biomarkers',
		legacyRoute: '/biomarkers',
		route: '/my-health/biomarkers',
		screenName: 'Tabs',
		isTabScreen: true,
	},
	biometrics: {
		id: 'biometrics',
		legacyRoute: '/biometrics',
		route: '/my-health/biometrics',
		screenName: 'Tabs',
		isTabScreen: true,
	},
	reports: {
		id: 'reports',
		legacyRoute: '/diagnostics',
		route: '/my-health/reports',
		screenName: 'Tabs',
		isTabScreen: true,
	},
	regimen: {
		id: 'regimen',
		legacyRoute: '/products',
		route: '/my-health/regimen',
		screenName: 'Tabs',
		isTabScreen: true,
	},
	messaging: {
		id: 'messaging',
		route: '/messaging',
		screenName: 'messaging',
		isTabScreen: true,
	},
	profile: {
		id: 'profile',
		route: '/profile',
		screenName: 'Profile',
		isTabScreen: false,
	},
} as const);

export const navigationHelperConstants = {
	navigationTargets,
};

export const getDestinationFromPath = (
	path: string
): NavigationDestination | undefined => {
	const match = Object.values(navigationTargets).find(
		(destination) =>
			destination.route.includes(path) ||
			(destination as any).legacyRoute?.includes(path)
	);
	return match;
};
