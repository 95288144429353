import styled from 'styled-components/macro';

import { device } from 'styles/device';

export const Img = styled.img`
	height: 100%;
	width: 100%;
	@media ${device.mobileS} {
		height: 30px;
		width: 150px;
	}
`;
