import { TabOptions } from 'components/Tabs/Tabs';

export const biometricsMapping: any = {
	account_id: 'accountId',
	age: 'age',
	anaerobic_threshold: 'anaerobicThreshold',
	arterial_thickness: 'arterialThickness',
	blood_pressure_diastolic: 'bloodPressureDiastolic',
	blood_pressure_systolic: 'bloodPressureSystolic',
	blood_pressure_indicator: 'bloodPressureIndicator',
	fat_mass: 'fatMass',
	height: 'height',
	max_wattage: 'maxWattage',
	muscle_mass: 'muscleMass',
	peak_heart_rate: 'peakHeartRate',
	resting_heart_rate: 'restingHeartRate',
	resting_heart_rate_indicator: 'restingHeartRateIndicator',
	total_body_fat: 'totalBodyFat',
	total_body_fat_indicator: 'totalBodyFatIndicator',
	upper_torso_fat: 'androidFat',
	vo2_max: 'vo2Max',
	vo2_max_indicator: 'vo2MaxIndicator',
	waist_hip_ratio: 'waistHipRatio',
	waist_hip_ratio_indicator: 'waistHipRatioIndicator',
	weight: 'weight',
};

export enum BiometricsTabs {
	body_composition = 'body_composition',
	fitness = 'fitness',
	health = 'health',
}

export const options: TabOptions<BiometricsTabs>[] = [
	{ name: BiometricsTabs.body_composition, label: 'Body Composition' },
	{ name: BiometricsTabs.fitness, label: 'Fitness' },
	{ name: BiometricsTabs.health, label: 'Health' },
];

export interface ChartData {
	chartData: {
		chartId: string;
		name: string;
	};
}

export interface BiometricsType {
	biometrics?: {
		accountId: string;
		anaerobicThreshold: number;
		arterialThickness: number;
		bloodPressureDiastolic: number;
		bloodPressureIndicator: string;
		bloodPressureSystolic: number;
		fatMass: number;
		height: number;
		maxWattage: number;
		muscleMass: number;
		peakHeartRate: number;
		restingHeartRate: number;
		restingHeartRateIndicator: string;
		totalBodyFat: number;
		totalBodyFatIndicator: string;
		androidFat: number;
		vo2Max: string;
		vo2MaxIndicator: string;
		waistHipRatio: number;
		waistHipRatioIndicator: string;
		weight: number;
	};
}

export interface BiometricsPayload {
	[key: string]: string | number;
}

export interface EducationalContentProps {
	[key: string]: string | undefined;
}

export interface EducationContentApiProps {
	loading: boolean;
	data: any;
}

export const BiometricContents: any = {
	bloodPressureSystolic: 'Blood_Pressure_Systolic__c',
	bloodPressure: 'Blood_Pressure_Systolic__c',
	anaerobicThreshold: 'Heart_Rate_at_RER_1_1__c',
	arterialThickness: 'Mean_IMT_Left__c',
	bloodPressureDiastolic: 'Blood_Pressure_Diastolic__c',
	blood_pressure_indicator: 'bloodPressureIndicator',
	fatMass: 'Fat_Mass__c',
	height: 'height',
	maxWattage: 'Max_Wattage__c',
	muscleMass: 'Lean_Body_Mass__c',
	peakHeartRate: 'Maximum_Heart_Rate__c',
	restingHeartRate: 'Resting_Heart_Rate__c',
	resting_heart_rate_indicator: 'restingHeartRateIndicator',
	totalBodyFat: 'Total_Body_Fat_Percent__c',
	totalBodyFatIndicator: 'Total_Body_Fat_Percent__c',
	androidFat: 'Android_Fat__c',
	vo2Max: 'VO2_MAX__c',
	vo2MaxIndicator: 'VO2_MAX__c',
	waistHipRatio: 'Waist_to_Hip_Ratio__c',
	waist_hip_ratio_indicator: 'waistHipRatioIndicator',
	weight: 'weight',
};

export interface ChartDataProps {
	chartData: {
		chartId: string;
		activeTabName?: string;
		title?: string;
	};
}
