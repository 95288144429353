import React from 'react';

import { useHistory } from 'react-router-dom';

import useUniversalContext from 'hooks/useUniversalContext';

import * as Styled from './Footer.styled';

export const Link = ({ name, link, Icon }) => (
	<Styled.Link link={link}>
		{Icon ? <Styled.IconImage src={Icon} alt='link icon' /> : null} {name}
	</Styled.Link>
);
export const NativeLink = ({ name, messageName }) => {
	return (
		<Styled.Link
			href='#'
			onClick={(e) => {
				e.preventDefault();
				window?.ReactNativeWebView?.postMessage(messageName);
			}}
		>
			{name}
		</Styled.Link>
	);
};

const Footer = () => {
	const year = new Date().getFullYear();
	const history = useHistory();
	const { isBrowser } = useUniversalContext();

	return (
		<Styled.Container>
			<Styled.Copyright>
				{year} BestLife Holdings, Inc.
				<br />
				All rights reserved
			</Styled.Copyright>
			<Styled.Contact>
				{`Need help? Visit our `}
				{isBrowser ? (
					<Link name={'support link'} link='https://cenegenics.com/support' />
				) : (
					<NativeLink messageName={'open-support-link'} name='support link' />
				)}
				{` or write us at `}
				<Styled.Email>app@cenegenics.com</Styled.Email>
			</Styled.Contact>
			<Styled.Privacy>
				By using this app, you agree to the Cenegenics&nbsp;
				<Styled.Link onClick={() => history.push('/privacy-policy')}>
					privacy policy
				</Styled.Link>
				&nbsp;and&nbsp;
				{isBrowser ? (
					<Link
						name={'terms and conditions'}
						link={`https://cenegenics.com/terms-and-conditions/`}
					/>
				) : (
					<NativeLink
						messageName={'open-tnc-link'}
						name='terms and conditions'
					/>
				)}
			</Styled.Privacy>
		</Styled.Container>
	);
};

export default Footer;
