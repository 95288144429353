import React, { useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useGetLatestBiomarkers } from 'api/services';
import emptyImage from 'assets/svg/icons/test-results.svg';
import BiomarkerCard from 'components/BiomarkerCard/BiomarkerCard';
import { BiomarkerTestResult } from 'components/BiomarkerCard/BiomarkerCard.helper';
import PageContainer from 'components/PageContainer/PageContainer';
import BiomarkerLoader from 'components/Skeleton/BiomarkerLoader/BiomarkerLoader';
import BiomarkerTabLoader from 'components/Skeleton/BiomarkerLoader/BiomarkerTabLoader';
import Tabs from 'components/Tabs/Tabs';
import Titles from 'components/Titles/Titles';
import SortButtonRow from 'components/ToggleSortButton/SortButtonRow';
import useAnalytics from 'hooks/useAnalytics';
import {
	BIOMARKERS_MAIN_TITLE,
	BIOMARKERS_SUB_TITLE,
	BIOMARKERS_SUB_TITLE_MORE,
} from 'utils/internationalization/en';

import {
	BiomarkerResultProps,
	BiomarkerTestCategory,
	BiomarkerTestCategoryArray,
} from './Biomarkers.helper';

import BiomarkerEmptyStateCard from './BiomarkerEmptyStateCard';

import * as Styled from './Biomarkers.styled';

export enum SORTING {
	ascending = 1,
	descending = 2,
	most_recent = 3,
	flowsheet_order = 4,
}

interface BiomarkerScreenLocation {
	state: {
		activeTab?: BiomarkerTestCategory;
	};
}

type CategoryList = Array<{
	label: BiomarkerTestCategory;
	name: BiomarkerTestCategory;
}>;

function Biomarkers({
	hasBottomNavigation,
}: {
	hasBottomNavigation?: boolean;
}) {
	let history = useHistory();
	const { state }: BiomarkerScreenLocation = useLocation();
	const [sorting, setSorting] = useState<number>(SORTING.flowsheet_order);

	const [activeTab, setActiveTab] = useState<BiomarkerTestCategory>(
		state?.activeTab ? state.activeTab : BiomarkerTestCategory.chem_screen
	);

	const { data: biomarkerResults, loading }: BiomarkerResultProps =
		useGetLatestBiomarkers({});

	const { logScreenView } = useAnalytics();
	useEffect(() => {
		logScreenView?.();
	}, [logScreenView]);

	let tabOptions: CategoryList = [];
	let categoryItems: BiomarkerTestResult[] = [];

	if (biomarkerResults && biomarkerResults.length) {
		const tabOptionsSet = new Set(
			biomarkerResults.map((result: BiomarkerTestResult) => result.Category__c)
		);

		tabOptions = Array.from(tabOptionsSet).map((el) => {
			return {
				label: el,
				name: el,
			};
		}) as unknown as CategoryList;
		tabOptions = tabOptions.filter((el) => el.name !== null);
		tabOptions.sort((a, b) => {
			try {
				return (
					BiomarkerTestCategoryArray.indexOf(a.name) -
					BiomarkerTestCategoryArray.indexOf(b.name)
				);
			} catch (e) {
				return 0;
			}
		});

		categoryItems = biomarkerResults.filter(
			({ Category__c }: BiomarkerTestResult) =>
				isSameCategory(Category__c, activeTab)
		);

		if (sorting === SORTING.ascending || sorting === SORTING.descending) {
			categoryItems = categoryItems.sort(
				(a: BiomarkerTestResult, b: BiomarkerTestResult) => {
					try {
						return a?.Display_Name.localeCompare(b?.Display_Name!) || 0;
					} catch (e) {
						return 0;
					}
				}
			);
			if (sorting === SORTING.descending) categoryItems.reverse();
		} else if (sorting === SORTING.flowsheet_order) {
			categoryItems = categoryItems.sort(
				(a: BiomarkerTestResult, b: BiomarkerTestResult) => {
					try {
						return (
							a.Cenegenics_Test_Standards__r.Display_Order__c -
							b.Cenegenics_Test_Standards__r.Display_Order__c
						);
					} catch (e) {
						return 0;
					}
				}
			);
		} else {
			categoryItems = categoryItems.sort(
				(a: BiomarkerTestResult, b: BiomarkerTestResult) => {
					try {
						return (
							new Date(b.Specimen_Collection_Date_Time__c).getTime() -
							new Date(a.Specimen_Collection_Date_Time__c).getTime()
						);
					} catch (e) {
						return 0;
					}
				}
			);
		}
	}

	const onCardClick = (
		testId: string,
		name: string,
		unit: string,
		min?: number,
		max?: number,
		testResultType?: string
	) => {
		history.push('/my-health/biomarkers/detail', {
			name,
			unit,
			min,
			max,
			activeTab,
			testResultType,
			testId,
		});
	};

	const isEmpty = !categoryItems.length;

	return (
		<PageContainer hasBottomNavigation={hasBottomNavigation}>
			<Titles
				isMyHealthSection={true}
				mainTitle={BIOMARKERS_MAIN_TITLE}
				subTitle={BIOMARKERS_SUB_TITLE}
				subTitleMore={BIOMARKERS_SUB_TITLE_MORE}
			/>
			<SortButtonRow sorting={sorting} setSorting={setSorting} />
			{loading ? (
				<BiomarkerTabLoader />
			) : (
				<Styled.SubHeading>
					<Tabs
						options={tabOptions}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						isNotSpacedEvenly={false}
						hasFlexStart={true}
					/>
				</Styled.SubHeading>
			)}
			{loading ? (
				<BiomarkerLoader />
			) : (
				<>
					{isEmpty ? (
						<Styled.EmptyContainer>
							<BiomarkerEmptyStateCard image={emptyImage} />
						</Styled.EmptyContainer>
					) : (
						categoryItems.map((el: BiomarkerTestResult) => {
							return (
								<BiomarkerCard
									onClick={onCardClick}
									key={`${el.Specific_Test_Name__c}-${el.Id}`}
									testResult={el}
								/>
							);
						})
					)}
				</>
			)}
		</PageContainer>
	);
}

export default Biomarkers;

function isSameCategory(
	category: string,
	activeTab: BiomarkerTestCategory
): unknown {
	if (activeTab === BiomarkerTestCategory.other && !category) {
		return true;
	}
	return category === activeTab;
}
