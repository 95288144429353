import React from 'react';

import Spinner from 'components/Spinner/Spinner';

import ModalHeader from './ModalHeader/ModalHeader';

import * as Styled from './Modal.styled';

const Modal = ({
	children,
	onRequestClose,
	isPortrait = false,
	loading = false,
	actionButtons = '',
	title,
	isEmpty = false,
	shouldCloseOnOverlayClick = true,
	...rest
}) => {
	return (
		<Styled.ModalContent
			{...rest}
			onRequestClose={onRequestClose}
			isPortrait={isPortrait}
			ariaHideApp={false}
			appElement={document.getElementById('root')}
			isEmpty={isEmpty}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
		>
			<>
				{loading ? (
					<Styled.Loader>
						<Spinner />
					</Styled.Loader>
				) : (
					<Styled.Column>
						<ModalHeader onClose={onRequestClose} title={title} />
						<Styled.MainColumn>{children}</Styled.MainColumn>
						{actionButtons && !isEmpty && (
							<Styled.Footer>{actionButtons}</Styled.Footer>
						)}
					</Styled.Column>
				)}
			</>
		</Styled.ModalContent>
	);
};

export default Modal;
