import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useUploadImage } from 'api/services';
import Avatar from 'components/Avatar/Avatar';
import Button from 'components/Button/Button';
import FileUploadBtn from 'components/FileUploadBtn/FileUploadBtn';
import { BOTTOM_LEFT } from 'utils/constants';
import { resizeFile, showErrorNotify, showSuccessNotify } from 'utils/helpers';
import {
	IMAGE_UPLOAD_SUCCESS,
	IMAGE_UPLOAD_FAILED,
} from 'utils/internationalization/en';

import { toFeetAndInch, healthTrackingWeightCard } from './ProfileCard.helper';

import * as Styled from './ProfileCard.styled';

const ProfileCard = ({ openChart }) => {
	const { firstName, lastName, picture, age } = useSelector(
		(state) => state.user
	);
	const { weight = 0, height = 0 } = useSelector((state) => state.biometrics);

	const { mutate: uploadImg, loading } = useUploadImage();
	const dispatch = useDispatch();

	const fullName = `${firstName} ${lastName}`;

	const handleUploadFile = async (file) => {
		if (!file) return;

		try {
			const image = await resizeFile(file);

			await uploadImg(image);
			dispatch.user.uploadPicture(image);

			showSuccessNotify(IMAGE_UPLOAD_SUCCESS, BOTTOM_LEFT);
		} catch (err) {
			console.error(err);
			showErrorNotify(IMAGE_UPLOAD_FAILED, BOTTOM_LEFT);
		}
	};

	const handleOpenChart = useCallback(() => {
		openChart();
		dispatch.chartData.update({
			chartId: healthTrackingWeightCard.chartId,
			name: healthTrackingWeightCard.name,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openChart]);

	return (
		<Styled.Container>
			<Styled.Column>
				<Avatar picture={picture} loading={loading} />
				{!loading && (
					<FileUploadBtn
						name='uploadBtn'
						useIconBtn
						handleFile={handleUploadFile}
						allowExtensions='.png,.jpeg,.webp'
					/>
				)}

				<Styled.Text name='username'>{fullName}</Styled.Text>
			</Styled.Column>

			<Styled.Row>
				<Styled.Param>
					<Styled.Value name='weight'>{weight} lbs</Styled.Value>
					<Styled.Name>Weight</Styled.Name>
					<Button variant='primary' small rounded onClick={handleOpenChart}>
						See more
					</Button>
				</Styled.Param>
				<Styled.Param>
					<Styled.Value name='height'>{toFeetAndInch(height)}</Styled.Value>
					<Styled.Name>Height</Styled.Name>
				</Styled.Param>
				<Styled.Param>
					<Styled.Value name='age'>{age}</Styled.Value>
					<Styled.Name>Age</Styled.Name>
				</Styled.Param>
			</Styled.Row>
		</Styled.Container>
	);
};

export default ProfileCard;
