import React from 'react';

import Spinner from 'components/Spinner/Spinner';

import * as Styled from './PageLoader.styled';

const PageLoader = () => {
	return (
		<Styled.WrapperSpinner>
			<Spinner />
		</Styled.WrapperSpinner>
	);
};

export default PageLoader;
