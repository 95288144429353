import styled from 'styled-components/macro';

import { colors } from 'styles/colors';

export const DropDownContainer = styled.div`
	min-width: 12em;
	margin-right: 10px;
	text-align: left;
	position: relative;
	display: inline-block;
	width: 100%;
`;

export const DropDownHeader = styled.div<{
	active: boolean;
}>`
	border: ${({ active }) => {
		return active ? `1px solid ${colors.blue20}` : `1px solid ${colors.grey20}`;
	}};
	box-shadow: ${({ active }) => {
		return active ? `0px 0px 0px 3px ${colors.purple10}` : 'none';
	}};
	border-radius: 8px;
	background-color: ${colors.white};
	padding: 7px 10px;
	display: flex;
`;

export const DropDownItemTitle = styled.p`
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: ${colors.black};
	flex-grow: 1;
	cursor: pointer;
`;

export const DropDownListContainer = styled.div`
	border: 1px solid ${colors.grey};
	border-radius: 8px;
	background-color: ${colors.white};
	position: absolute;
	min-width: 10em;
	z-index: 50;
	overflow-y: scroll;
	transform: translateY(4px);
	width: 100%;
	max-height: 235px;
`;

export const DropDownSearchFieldContainer = styled.div`
	margin: 0px;
	padding: 12px;
	position: sticky;
	position: -webkit-sticky; /* Safari */
	top: 0;
	background-color: ${colors.white};
	input {
		border: 1px solid ${colors.darkBlue};
		border-radius: 4px;
		font-size: 16px;
		line-height: 22px;
		padding: 7px 10px;
		width: 100%;
	}
`;

export const DropDownList = styled.ul`
	margin: 0;
	box-sizing: border-box;
`;

export const ListItem = styled.li`
	list-style: none;
	padding: 7px 10px;
	cursor: pointer;
	&:not(:last-child) {
		border-bottom: 1px solid ${colors.grey};
	}
`;

export const ListItemSearchable = styled.li`
	list-style: none;
	padding: 0 10px;
	cursor: pointer;
	&:last-child {
		margin-bottom: 8px;
	}
	&:hover {
		border-left: 2px solid ${colors.newBlueLine};
	}
	p {
		padding: 6px 8px;
		&:hover {
			background-color: ${colors.softBlue};
			border-radius: 6px;
		}
	}
`;
