import { toast } from 'react-toastify';

// REGEX
// Check email format
export const EMAIL_RGX =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
export const PASSWORD_RGX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%.,\(\)^#*_+?&\-])[A-Za-z\d@$!.,#\(\)^_+%*?&\-]{8,}$/;

// Valid characters for a phone number
export const PHONE_RGX = /^[\d\-\(\)\+ ]*$/;

// Sendbird UNIQUE_HANDLER_ID for event handler
export const CHANNEL_HANDLER_ID = 'Channel_Hanndler_001';

// Toast positions
export const BOTTOM_LEFT = toast.POSITION.BOTTOM_LEFT;
export const TOP_CENTER = toast.POSITION.TOP_CENTER;

// Modal style override
export const modalStyle = {
	overlay: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(5, 14, 44, 0.5)',
	},
};

export const chatModalStyle = {
	...modalStyle,
	content: {
		width: '550px',
	},
};

export const autoWidthModalStyle = {
	...modalStyle,
	content: {
		width: 'auto',
		maxWidth: '90%',
	},
};

// Charts naming
export const chartsKey = {
	anaerobicThreshold: 'anaerobicThreshold',
	arterialThickness: 'arterialThickness',
	bloodPressure: 'bloodPressure',
	fatMass: 'fatMass',
	maxWattage: 'maxWattage',
	muscleMass: 'muscleMass',
	peakHeartRate: 'peakHeartRate',
	restingHeartRate: 'restingHeartRate',
	totalBodyFat: 'totalBodyFat',
	upperTorsoFat: 'androidFat',
	vo2Max: 'vo2Max',
	waistHipRatio: 'waistHipRatio',
	weight: 'weight',
};

export const policyConsentTimestamp = 'CenegenicsPolicyTimestamp';
export const policyConsentName = 'CenegenicsPolicyConsent';
// Value to be used in redux together with policyConsentName
// as key, and this values represent it's value
export const policyNameAccepted = 'accepted';

export enum AnalyticsMethod {
	logScreenView = 'logScreenView',
	setUserProperty = 'setUserProperty',
	setUserProperties = 'setUserProperties',
	setUserId = 'setUserId',
}
