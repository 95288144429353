import React from 'react';

import testResultsIcon from 'assets/svg/icons/test-results.svg';

import {
	ERROR_TEST_RESULT_ISEMPTY,
	SUBTITLE_CONTACT_CENEGENICS_TEAM_FOR_TESTRESULTS,
} from '../../utils/internationalization/en';
import { useTestResultsContext } from '../TestFilters/context/useTestResultsContext';
import { filterData } from '../TestFilters/TestFilters.helper';

import { TestResultsArrayProps } from './TestResultsBlock.types';
import { TestResultsList } from './TestResultsList/TestResultsList';

import * as Styled from './TestResultsBlock.styled';

export const TestResultsBlock = ({ testCollection }: TestResultsArrayProps) => {
	const { testType, testPeriod, testTags } = useTestResultsContext();
	const filteredCollection = filterData(
		testCollection,
		testType,
		testPeriod,
		testTags
	);

	return (
		<Styled.CardsContainer>
			{filteredCollection && filteredCollection.length > 0 ? (
				<TestResultsList testCollection={filteredCollection} />
			) : (
				<Styled.EmptyContainer>
					<Styled.IconWrapper>
						<Styled.Icon src={testResultsIcon} />
					</Styled.IconWrapper>
					<Styled.EmptyTitle>{ERROR_TEST_RESULT_ISEMPTY}</Styled.EmptyTitle>
					<Styled.EmptySubtitle>
						{SUBTITLE_CONTACT_CENEGENICS_TEAM_FOR_TESTRESULTS}
					</Styled.EmptySubtitle>
				</Styled.EmptyContainer>
			)}
		</Styled.CardsContainer>
	);
};
