import styled from 'styled-components/macro';

import SafeImage from 'components/Image/SafeImage';
import { Image } from 'components/Spinner/Spinner.styled';
import { colors } from 'styles/colors';

export const AvatarBorder = styled.div`
	width: 48px;
	height: 48px;
	padding: 2px;
	border-radius: 100px;
	background: ${(props) => (props.isPicture ? colors.gradient : 'transparent')};
	position: relative;

	${Image} {
		position: absolute;
		left: 25%;
		top: 25%;
		height: 50%;
	}
`;

export const NoCorsImg = styled.img`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 100px;
	background: transparent;
	object-fit: cover;
`;

export const Picture = styled(SafeImage)`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 100px;
	background: transparent;
	object-fit: cover;
`;
