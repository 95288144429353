import styled from 'styled-components/macro';

import { colors } from '../../../styles/colors';

export const CardTagsContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 6px;
`;

export const CardTagItem = styled.div`
	display: flex;
	width: 34px;
	height: 34px;
	justify-content: center;
	align-items: center;
	background: ${colors.secondaryLightBlue};
	border-radius: 6px;
`;
