import React from 'react';

import { SORTING } from 'pages/Biomarkers/Biomarkers';

import * as Styled from './ToggleSortButton.styled';

interface SortToggleButtonProps {
	sorting: SORTING;
	setSorting: (sortType: SORTING) => void;
}

export default function SortButtonRow({
	sorting,
	setSorting,
}: SortToggleButtonProps) {
	return (
		<Styled.FilterContainer>
			<Styled.SortContainer>
				<Styled.Container>
					<Styled.Title>Sort by</Styled.Title>
					<Styled.Ascending
						onClick={() => setSorting(SORTING.ascending)}
						active={sorting === SORTING.ascending}
					>
						A-Z
					</Styled.Ascending>
					<Styled.Descending
						onClick={() => setSorting(SORTING.descending)}
						active={sorting === SORTING.descending}
					>
						Z-A
					</Styled.Descending>
				</Styled.Container>
				<Styled.DefaultFlowSheetOrder
					onClick={() => setSorting(SORTING.flowsheet_order)}
					active={sorting === SORTING.flowsheet_order}
				>
					Default
				</Styled.DefaultFlowSheetOrder>
			</Styled.SortContainer>
		</Styled.FilterContainer>
	);
}
