import React from 'react';

import { uniqueId } from 'lodash';

import * as Styled from './ReferentialTable.styled';

const ReferentialTable = ({ data }) => {
	const { firstList, secondList, thirdList } = data;

	const isTwoColumn = !thirdList;

	const renderColumn = (list) =>
		list &&
		list.map(({ value, selected }) => (
			<Styled.TableData
				key={uniqueId()}
				className='table-data'
				isSelected={selected}
			>
				{value}
			</Styled.TableData>
		));

	return (
		<Styled.Container>
			<Styled.Title>Referential Values</Styled.Title>

			<Styled.Table role='table'>
				<Styled.TableRow>
					<Styled.TableColumn
						className='first-column'
						isTwoColumn={isTwoColumn}
					>
						<Styled.HeaderCell>{firstList?.title || 'n/a'}</Styled.HeaderCell>
						{renderColumn(firstList.data)}
					</Styled.TableColumn>

					<Styled.TableColumn isTwoColumn={isTwoColumn}>
						<Styled.HeaderCell>{secondList?.title || 'n/a'}</Styled.HeaderCell>
						{renderColumn(secondList.data)}
					</Styled.TableColumn>
					{thirdList && (
						<Styled.TableColumn>
							<Styled.HeaderCell>{thirdList?.title || 'n/a'}</Styled.HeaderCell>
							{renderColumn(thirdList.data)}
						</Styled.TableColumn>
					)}
				</Styled.TableRow>
			</Styled.Table>
		</Styled.Container>
	);
};

export default ReferentialTable;
