import React from 'react';

import avatarImg from 'assets/svg/icons/user-avatar.svg';
import { renderSimpleAvatarImages } from 'utils/helpers';

import * as Styled from './Avatar.styled';

const Avatar = ({
	picture,
	allowSwitch = true,
	loading = false,
	altText = 'User profile',
}) => (
	<Styled.AvatarBorder id='avatarBorder' isPicture={picture}>
		{allowSwitch && renderSimpleAvatarImages ? (
			<Styled.NoCorsImg
				src={picture || avatarImg}
				alt={altText}
				className='userAvatar'
			/>
		) : (
			<Styled.Picture src={picture} alt='User profile' className='userAvatar' />
		)}
	</Styled.AvatarBorder>
);

export default Avatar;
