import bodyIcon from 'assets/svg/icons/body.svg';
import massIcon from 'assets/svg/icons/mass_desktop.svg';
import muscleIcon from 'assets/svg/icons/muscle_desktop.svg';
import upperTorso from 'assets/svg/icons/upper-torso_desktop.svg';
import { colors } from 'styles/colors';

import { chartsKey } from './constants';

export const healthTrackingBodyCompositionCards = [
	{
		chartId: chartsKey.totalBodyFat,
		name: 'Total Body Fat',
		value: 'N/A',
		type: '%',
		icon: bodyIcon,
		backgroundColor: colors.green,
		mapKey: chartsKey.totalBodyFat,
		healthIndicator: 'totalBodyFatIndicator',
	},
	{
		chartId: chartsKey.muscleMass,
		name: 'Muscle Mass',
		value: 'N/A',
		type: 'lbs',
		icon: muscleIcon,
		backgroundColor: colors.purple,
		mapKey: chartsKey.muscleMass,
	},
	{
		chartId: chartsKey.fatMass,
		name: 'Fat Mass',
		value: 'N/A',
		type: 'lbs',
		icon: massIcon,
		backgroundColor: colors.gold,
		mapKey: chartsKey.fatMass,
	},
	{
		chartId: chartsKey.upperTorsoFat,
		name: 'Upper Torso Fat',
		value: 'N/A',
		type: '%',
		icon: upperTorso,
		backgroundColor: colors.pink,
		mapKey: chartsKey.upperTorsoFat,
	},
];
