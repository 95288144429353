import styled from 'styled-components/macro';

import { colors } from 'styles/colors';
import { fontSizes, fontStyles } from 'styles/fonts';

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const Title = styled.p`
	margin-right: 20px;
	color: ${colors.darkGrey};
	${fontStyles[fontSizes.eighteen]};
`;

const SortComponent = styled.div<{ active?: boolean }>`
	border: ${({ active }) =>
		active ? `1px solid ${colors.black}` : `1px solid ${colors.grey40}`};
	color: ${({ active }) => (active ? `${colors.black}` : `${colors.grey40}`)};
	pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
	opacity: 0.7;
`;

export const Ascending = styled(SortComponent)`
	background-color: white;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	padding: 5px 20px 5px 20px;
`;

export const Descending = styled(SortComponent)`
	background-color: white;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 5px 20px 5px 20px;
`;

export const SortContainer = styled.div`
	display: flex;
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const DateInnerContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
`;

export const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 10px;
`;

export const DefaultFlowSheetOrder = styled(SortComponent)`
	background-color: white;
	padding: 10px;
	border-radius: 5px;
	padding: 5px 20px 5px 20px;
	width: 37%;
	display: flex;
	justify-content: center;
`;
